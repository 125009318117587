(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(breadcrumb);

    breadcrumb.$inject = ['$breadcrumbProvider'];

    function breadcrumb($breadcrumbProvider) {
        // set below to true to make alerts look like toast
		$breadcrumbProvider.setOptions({
		  prefixStateName: 'home'
		});
    }
})();
