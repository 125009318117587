'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .controller('CmsDetailController', CmsDetailController);


  CmsDetailController.$inject = ['$state', '$stateParams', '$translate', 'CmsService', '$log'];

  function CmsDetailController($state, $stateParams, $translate, CmsService, $log) {
    var vm = this;

    vm.id = '';
    vm.node = '';
    vm.lang = 'eng';
    vm.nodes = {
      'tutorial': 'garanteasyApp.receipt.sidebar.tutorial',
      'guide': 'garanteasyApp.receipt.sidebar.guide',
      'cataloghi': 'garanteasyApp.receipt.sidebar.catalogues',
      'warranty': 'garanteasyApp.receipt.sidebar.officialWarranties',
      'faq': 'garanteasyApp.receipt.sidebar.faq'
    };
    vm.data = {};

    vm.init = init;


    init();

    function init() {
      if (vm.nodes[$stateParams.node]) {
        vm.node = $stateParams.node;
      } else {
        return;
      }
      vm.id = $stateParams.id;
      if ($translate.use()) {
        vm.lang = $translate.use();
      }
      CmsService.getData(vm.lang, vm.node, vm.id).then(function (res) {
        if (res && res.attributes && res.attributes.body) {
          vm.data = res.attributes.body;
          vm.data.title = res.attributes.title;
          vm.data.id = res.id;
        }
      });
    }

  }
})();
