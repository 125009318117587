(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('PurchasedProductResoController',PurchasedProductResoController);

    PurchasedProductResoController.$inject = ['$stateParams','$uibModalInstance', 'entity', 'PurchasedProduct'];

    function PurchasedProductResoController($stateParams, $uibModalInstance, entity, PurchasedProduct) {
        var vm = this;

        var params = $stateParams.listSelected;
        vm.purchasedProduct = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

            function confirmDelete (id) {
                if(params && params.length > 0){
                    angular.forEach(params, function (item) {
                        PurchasedProduct.delete({id: item.id},
                            function () {
                                $uibModalInstance.close(true);
                            });
                    });
                }
            }
    }
})();
