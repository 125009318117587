(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('ImportService', ImportService);

    ImportService.$inject = ['$resource'];

    function ImportService ($resource) {
    	var resourceUrl =  'management/products-import/:id';
    	return $resource(resourceUrl, {}, {
            'get': {method: 'GET'},
            'insert': { method:'POST' }
        });
    }
})();
