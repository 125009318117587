(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Warranty', Warranty);

    Warranty.$inject = ['$resource', 'DateUtils', '$stateParams'];

    function Warranty ($resource, DateUtils, $stateParams) {
        var resourceUrl =  'api/warranties/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.appliesFrom = DateUtils.convertLocalDateFromServer(data.appliesFrom);
                        data.appliesTo = DateUtils.convertLocalDateFromServer(data.appliesTo);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                	var dataToSend = angular.copy(data);
                	dataToSend.appliesFrom = DateUtils.convertLocalDateToServer(dataToSend.appliesFrom);
                	dataToSend.appliesTo = DateUtils.convertLocalDateToServer(dataToSend.appliesTo);
                    return angular.toJson(dataToSend);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var dataToSend = angular.copy(data);
                	dataToSend.appliesFrom = DateUtils.convertLocalDateToServer(dataToSend.appliesFrom);
                	dataToSend.appliesTo = DateUtils.convertLocalDateToServer(dataToSend.appliesTo);
                    return angular.toJson(dataToSend);
                }
            },
            /* Actually deletes a PurchasedProductWarranty, hence the need to identify a PurchasedProduct. */
            'delete': {
            	method:'DELETE',
                url: ':id/:purchasedProductId',
                params: {purchasedProductId: $stateParams.id}
            },

            'getByPurchasedProduct': {
              method:'GET',
              url: 'api/warranties/purchased-product/:purchasedProductId',
              isArray: true
            }
        });
    }
})();
