(function () {
  'use strict';

  var extensionReceiptSelectComponent = {
    templateUrl: 'app/entities/extension/components/extension-receipt-select-component.html',
    controller: ExtensionReceiptSelectController,
    controllerAs: 'vm',
    bindings: {
      receipt: '<',
      extension: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('extensionReceiptSelectComponent', extensionReceiptSelectComponent);

  ExtensionReceiptSelectController.$inject = ['$scope', 'Warranty', '$log', 'PurchasedProductWarranty', 'ExtensionService', '$translate', 'AlertService', '$state'];

  function ExtensionReceiptSelectController($scope, Warranty, $log, PurchasedProductWarranty, ExtensionService, $translate, AlertService, $state) {
    var vm = this;
    vm.giftInfo = {};
    vm.onSave = onSave;
    vm.selectedProducts = [];
    vm.clickedCheck = clickedCheck;
    vm.openCalendar = openCalendar;
    vm.datePickerOpenStatus = {};
    vm.datePickerOpenStatus.date = false;
    vm.isOther = false;
    vm.resolveGuarantor = resolveGuarantor;

    $scope.$watch("vm.extension", function (extension) {
      if (extension) {
        vm.extension = extension;
      }

    });

    function resolveGuarantor() {
      var purchasedProductWarranty = vm.extension;
      if (!purchasedProductWarranty.warranty) {
        return;
      }
      if (purchasedProductWarranty.warranty.contractType === 'LEGAL') {
        if (purchasedProductWarranty.receipt && purchasedProductWarranty.receipt.shop && purchasedProductWarranty.receipt.shop.description) {
          if (purchasedProductWarranty.purchasedProduct && purchasedProductWarranty.purchasedProduct.producer)
            return purchasedProductWarranty.purchasedProduct.product.producer.name;
        }
      }
      if (purchasedProductWarranty.warranty.contractType === 'CONVENTIONAL') {
        if (purchasedProductWarranty.purchasedProduct && purchasedProductWarranty.purchasedProduct.product && purchasedProductWarranty.purchasedProduct.product.producer && purchasedProductWarranty.purchasedProduct.product.producer.name) {
          if (purchasedProductWarranty.cmsWarrantyData && vm.cmsWarrantyData.holder_company) {
            return purchasedProductWarranty.cmsWarrantyData.holder_company;
          }
          if (purchasedProductWarranty.purchasedProduct && purchasedProductWarranty.purchasedProduct.producer)
            return purchasedProductWarranty.purchasedProduct.product.producer.name;
        }
      }
      if (purchasedProductWarranty.warranty.warrantyType === 'EXTRA' ||
        purchasedProductWarranty.warranty.warrantyType === 'EXTENSION'
      ) {
        return purchasedProductWarranty.warranty.description;
      }
    }

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    function onSave() {
      ExtensionService.linkWarrantyToPurchasedProduct(vm.extension.sourcePurchasedProductId, vm.selectedProductId).then(onPurchasedProductWarrantySaveSuccess, onPurchasedProductWarrantySaveError);

    }

    function onPurchasedProductWarrantySaveSuccess(result) {
      $translate("garanteasyApp.warranty.extension.associationCompleted").then(function (t) {
        AlertService.success(t);
      });
      $state.go('purchased-product-warranty', null, { reload: true });
    }
    function onPurchasedProductWarrantySaveError(result) {

    }

    function clickedCheck(purchasedProduct) {
      if (purchasedProduct) {
        if (purchasedProduct.checked) {
          vm.selectedProducts.push(purchasedProduct.id);
        } else {
          var result = vm.selectedProducts.indexOf(purchasedProduct.id);
          if (result > -1) {
            vm.selectedProducts.splice(result, 1);
          }
        }
      }
    }

  }
})();
