(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product', {
            parent: 'entity',
            url: '/product?page&sort&search',
            ncyBreadcrumb: {
                label: "{{'garanteasyApp.product.home.title' | translate}}"
            },
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'garanteasyApp.product.home.title'
            },
            views: {
                'sidebar@': {
                    templateUrl: 'app/layouts/sidebar/sidebar.html',
                    controller: 'SidebarController',
                    controllerAs: 'vm'
                },
                'content@': {
                    templateUrl: 'app/entities/product/products.html',
                    controller: 'ProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('brand');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-detail', {
            parent: 'entity',
            url: '/product/{id}?from&from2nd',
			ncyBreadcrumb: {
				label: "{{'garanteasyApp.product.detail.title' | translate}}",
				parent: function ($scope) {
		            return $scope.from || $scope.from2nd || 'product';
				}
			},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'garanteasyApp.product.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product/product-detail.html',
                    controller: 'ProductDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('purchasedProduct');
                    $translatePartialLoader.addPart('brand');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Product', function($stateParams, Product) {
                    return Product.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'product',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('product-detail.edit', {
            parent: 'product-detail',
            url: '/detail/edit',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-dialog.html',
                    controller: 'ProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product.new', {
            parent: 'product',
            url: '/new',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-dialog.html',
                    controller: 'ProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                description: null,
                                category: null,
                                eanCode: null,
                                official: 0,
                                transportable: 1,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('product');
                });
            }]
        })
        .state('product.edit', {
            parent: 'product',
            url: '/{id}/edit',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-dialog.html',
                    controller: 'ProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product.delete', {
            parent: 'product',
            url: '/{id}/delete',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-delete-dialog.html',
                    controller: 'ProductDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
            .state('product-detail.maintenance-new', {
                parent: 'product-detail',
                url: '/{id}/addMaintenance',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/maintenance/maintenance-dialog.html',
                        controller: 'MaintenanceDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve:
                        {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart('maintenance');
                                    return $translate.refresh();
                            }],

                            entity: function () {
                                return {
                                    description: '',
                                    id: null,
                                    startVal : null,
                                    startType : null,
                                    intervalVal : null,
                                    intervalType : '',
                                    repetitions : null,
                                    productId: $stateParams.id
                                };
                            },
                            previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'product-detail',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                        }
                    }).result.then(function() {
                        $state.go('product-detail', null, { reload: true });
                    }, function() {
                        $state.go('product-detail');
                    });
                }]
            })
    .state('product-detail.maintenance-edit', {
            parent: 'product-detail',
            url: '/{ppId}/{mId}/editMaintenance',
            ncyBreadcrumb: {
                skip: true // Never display this state in breadcrumb.
            },
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/maintenance/maintenance-dialog.html',
                    controller: 'MaintenanceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('maintenance');
                            return $translate.refresh();
                        }],
                        entity: ['Maintenance', function(Maintenance) {
                            return Maintenance.get({id : $stateParams.mId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-detail', null, { reload: true });
                }, function() {
                    $state.go('product-detail');
                });
            }]
        })
            .state('product-detail.maintenance-delete', {
                parent: 'product-detail',
                url: '/{id}/deleteMaintenance/{pmId}',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/maintenance/maintenance-delete-dialog.html',
                        controller: 'MaintenanceDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('maintenance');
                                return $translate.refresh();
                            }],
                            entity: ['Maintenance', function(Maintenance) {
                                return Maintenance.get({id : $stateParams.pmId}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('product-detail', null, { reload: true });
                    }, function() {
                        $state.go('product-detail');
                    });
                }]
            })
    .state('product-detail.link-new', {
            parent: 'product-detail',
            url: '/{id}/addLink',
            ncyBreadcrumb: {
                skip: true // Never display this state in breadcrumb.
            },
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/link/link-dialog.html',
                    controller: 'LinkDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve:
                    {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('maintenance');
                            return $translate.refresh();
                        }],

                        entity: function () {
                            return {
                                description: '',
                                value : '',
                                id: null,
                                productId: $stateParams.id
                            };
                        },
                        previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'product-detail',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-detail', null, { reload: true });
                }, function() {
                    $state.go('product-detail');
                });
            }]
        })
    .state('product-detail.link-edit', {
            parent: 'product-detail',
            url: '/{ppId}/{lId}/editLink',
            ncyBreadcrumb: {
                skip: true // Never display this state in breadcrumb.
            },
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/link/link-dialog.html',
                    controller: 'LinkDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {

                        entity: ['ProductResources', function(ProductResources) {
                            return ProductResources.get({id : $stateParams.lId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-detail', null, { reload: true });
                }, function() {
                    $state.go('product-detail');
                });
            }]
        })
            .state('product-detail.link-delete', {
                parent: 'product-detail',
                url: '/{id}/deleteLink/{lmId}',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/link/link-delete-dialog.html',
                        controller: 'LinkDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('maintenance');
                                return $translate.refresh();
                            }],
                            entity: ['ProductResources', function(ProductResources) {
                                return ProductResources.get({id : $stateParams.lmId}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('product-detail', null, { reload: true });
                    }, function() {
                        $state.go('product-detail');
                    });
                }]
            });
    }

})();
