(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('MerchantOperator', MerchantOperator);

    MerchantOperator.$inject = ['$resource'];

    function MerchantOperator ($resource) {
        var resourceUrl =  'api/merchant-operator/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'search':{
                url: 'api/merchant-operator/search',
                method: 'GET',
                isArray: true,

            },
            'enable': {
                url: 'api/merchant-operator/enable',
                method:'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'disable': {
                url: 'api/merchant-operator/disable',
                method:'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
