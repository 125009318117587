(function () {
  'use strict';

  var customerComponent = {
    templateUrl: 'app/entities/customer/customer.component.html',
    controller: CustomerEditController,
    controllerAs: 'vm',
    bindings: {
      customer: '<',
      onSave: '<',
      onCancel: '<',
      onError: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('customerComponent', customerComponent);

  CustomerEditController.$inject = ['$timeout', '$scope', 'Customer', 'DateUtils'];

  function CustomerEditController($timeout, $scope, Customer, DateUtils) {
    var vm = this;
    vm.tmp = {};
    vm.datePickerOpenStatus = {};
    vm.datePickerOpenStatus.birthDate = false;

    vm.openCalendar = openCalendar;
    vm.clear = clear;
    vm.save = save;

    vm.$onInit = function () {
      if (!vm.customer || !vm.customer.id) {
        vm.customer = {
          legalName: null,
          birthDate: null,
          vatid: null,
          business: false,
          gender: null,
          id: null
        };
      }
      if (vm.customer.birthDate && typeof vm.customer.birthDate === 'string') {
        vm.customer.birthDate = DateUtils.convertLocalDateFromServer(vm.customer.birthDate);
      }
      angular.copy(vm.customer, vm.tmp);
    };

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

    function clear() {
      if (typeof vm.onCancel === 'function') {
        angular.copy(vm.tmp, vm.customer);
        vm.onCancel();
      }
    }

    function save() {
      vm.isSaving = true;
      if (vm.customer.id !== null) {
        Customer.update(vm.customer, onSaveSuccess, onSaveError);
      } else {
        Customer.save(vm.customer, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      $scope.$emit('garanteasyApp:customerUpdate', result);
      if (typeof vm.onSave === 'function') {
        vm.onSave(result);
      }
      vm.isSaving = false;
    }

    function onSaveError(error) {
      if (typeof vm.onError === 'function') {
        vm.onError(error);
      }
      vm.isSaving = false;
    }

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

  }
})();
