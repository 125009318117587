(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('GiftSelectedProductController', GiftSelectedProductController);

  GiftSelectedProductController.$inject = ['$state', '$stateParams', '$scope', '$rootScope', '$q', 'Receipt', 'Principal', 'PurchasedProduct'];

  function GiftSelectedProductController($state, $stateParams, $scope, $rootScope, $q, Receipt, Principal, PurchasedProduct) {
    var vm = this;
    vm.checkAccess = checkAccess;
    vm.giftInfo = {};
    vm.init = init;
    vm.purchasedProduct = {};
    vm.onSave = onSave;


    vm.init();



    function init() {
      if ($stateParams.purchasedProductId)
        PurchasedProduct.get({ id: $stateParams.purchasedProductId }, onSuccessLoadProduct, onErrorLoadProduct);
    }

    function onSuccessLoadProduct(result) {
      if (!angular.isUndefined(result)) {
        vm.purchasedProduct = result;
        checkAccess();
      }
    }

    function onErrorLoadProduct() {

    }

    function onSave() {
      if (!vm.giftInfo.giftMakerDescription) {
        vm.purchasedProduct.giftMakerDescription = vm.purchasedProduct.receipt.customer.legalName;
      } else {
        vm.purchasedProduct.giftMakerDescription = vm.giftInfo.giftMakerDescription;
      }
      vm.purchasedProduct.giftReceiverEmail = vm.giftInfo.giftReceiverEmail;
      vm.purchasedProduct.giftDescription = vm.giftInfo.giftDescription;
      if (!vm.purchasedProduct.receiver) {
        vm.purchasedProduct.receiver = {};
      }
      vm.purchasedProduct.receiver.legalName = vm.giftInfo.giftReceiverName;
      PurchasedProduct.update(vm.purchasedProduct, onSaveSuccess, onSaveError);
    }

    function onSaveSuccess() {
      $state.go('purchased-product');
    }

    function onSaveError() {

    }


    function checkAccess() {
      Principal.identity().then(function (account) {
        if (vm.purchasedProduct.receipt.customer) {
          vm.isOwner = vm.purchasedProduct.receipt.customer.user.id === account.id;
        } else {
          vm.isOwner = false;
        }
        vm.isGiftReceiver = vm.purchasedProduct.giftReceiverUserId === account.id;
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });

        //If this is a gift, after notification warranties can be managed by gift receiver and not by owner
        vm.canManageWarranties = !vm.purchasedProduct.giftId || vm.isAdmin || (vm.isGiftReceiver && vm.purchasedProduct.giftReceiverNotified === true) || (vm.isOwner && vm.purchasedProduct.giftReceiverNotified === false);
      });


    }

  }
})();
