(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('PurchasedProductWarrantyDeleteController',PurchasedProductWarrantyDeleteController);

    PurchasedProductWarrantyDeleteController.$inject = ['$uibModalInstance', 'entity', 'PurchasedProductWarranty', 'Warranty', 'PurchasedProduct'];

    function PurchasedProductWarrantyDeleteController($uibModalInstance, entity, PurchasedProductWarranty, Warranty, PurchasedProduct) {
        var vm = this;

        vm.purchasedProductWarranty = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        // Carica la warranty
        vm.loadWarranty = loadWarranty;
        vm.warranty = {};
       	loadWarranty(vm.purchasedProductWarranty.warrantyId);
        
        function loadWarranty (id) {
        	Warranty.get({id: id}, function(result) {
                vm.warranty = result;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	PurchasedProductWarranty.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
