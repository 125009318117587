(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('card', {
            parent: 'entity',
            url: '/card?page&sort&search',
            ncyBreadcrumb: {
                label: "{{'garanteasyApp.card.home.title' | translate}}"
            },
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'garanteasyApp.card.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/card/cards.html',
                    controller: 'CardController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'legalName,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('card');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('card.detail', {
                parent: 'entity',
                url: '/card/{id}',
                ncyBreadcrumb: {
                    label: "{{'garanteasyApp.receipt.detail.title' | translate}}",
                    parent: 'card'
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.receipt.detail.title'
                },
                views: {

                    'content@': {
                        templateUrl: 'app/entities/card/card-detail.html',
                        controller: 'CardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('card');
                        $translatePartialLoader.addPart('cardCustomer');
                        $translatePartialLoader.addPart('purchasedProduct');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('brand');
                        $translatePartialLoader.addPart('shop');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('merchant');
                        $translatePartialLoader.addPart('paymentType');
                        $translatePartialLoader.addPart('dataSource');
                        $translatePartialLoader.addPart('warrantyClaim');
                        $translatePartialLoader.addPart('warranty');
                        $translatePartialLoader.addPart('feedback');
                        $translatePartialLoader.addPart('receiptType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Card', function($stateParams, Card) {
                            return Card.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'card',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
        .state('card-detail.edit', {
            parent: 'card-detail',
            url: '/detail/edit',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/card/card-dialog.html',
                    controller: 'CardDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Card', function(Card) {
                            return Card.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('card.new', {
            parent: 'card',
            url: '/new',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/card/card-dialog.html',
                    controller: 'CardDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                activationDate: null,
                                expirationDate: null,
                                number: null,
                                type: null,
                                contact: null,
                                phoneNumber: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                   $state.go('card', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('card.card-requestUse', {
            parent: 'card.new',
            url: '/requestUseCard',
            ncyBreadcrumb: {
                skip: true // Never display this state in breadcrumb.
            },
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/card/card-request-use-card-dialog.html',
                    controller: 'CardRequestUseController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Card', function(Card) {
                            return Card.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('settings', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('card.edit', {
            parent: 'card',
            url: '/{id}/edit',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/card/card-dialog.html',
                    controller: 'CardDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Card', function(Card) {
                            return Card.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('card', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
            .state('card.card-customer-edit', {
                parent: 'card.detail',
                url: '/detail/edit',

                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/cardCustomer/cardCustomer-dialog.html',
                        controller: 'CardCustomerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['$state', '$stateParams', 'CardCustomer', function($state, $stateParams, CardCustomer) {
                                return CardCustomer.get({id : $state.params.ccId}).$promise;
                            }]
                        }

                    }).result.then(function() {
                        $state.go('card.detail', null, { reload: true });
                    }, function() {
                        $state.go('card.detail');
                    });
                }]
            }).state('card.card-customer-delete', {
                parent: 'card.detail',
                url: '/{ccId}/deleteCardCustomer',
                params: {dataCard:null,ccId:null},
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/cardCustomer/cardCustomer-delete-dialog.html',
                        controller: 'CardCustomerDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CardCustomer', function(CardCustomer) {
                                return CardCustomer.get({id : $stateParams.ccId}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('settings');
                    }, function() {
                        $state.go('settings');
                    });
                }]
            })
        .state('card.delete', {
            parent: 'card',
            url: '/{id}/delete',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/card/card-delete-dialog.html',
                    controller: 'CardDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Card', function(Card) {
                            return Card.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('card', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
