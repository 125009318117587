(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('AcceptPrivacyController', AcceptPrivacyController);


  AcceptPrivacyController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', '$location', '$filter', '$stateParams', 'PrivacyPolicy', 'AlertService', '$state', 'Principal', '$log'];

  function AcceptPrivacyController($translate, $timeout, Auth, LoginService, $location, $filter, $stateParams, PrivacyPolicy, AlertService, $state, Principal, $log) {
    var vm = this;

    vm.doNotMatch = null;
    vm.error = null;
    vm.errorSocialLoginError = $stateParams.SocialLoginError;
    vm.errorUserExists = null;
    vm.errorServiceConsent = null;
    vm.login = LoginService.open;
    vm.success = null;
    vm.lang = $translate.use();
    vm.arrayLegalese = function (stringhe) {
      console.log(stringhe)
      if (stringhe) {
        vm.array = [];
        vm.array = vm.array.concat(stringhe);
        vm.array = stringhe.split(',');
      } else {
        $log.error("Mancano le clausole privacy");
      }
    };

    function loadMerchantPrivacyPolicies() {
      PrivacyPolicy.requiredGet({}, onSuccess, onError);
    }

    function onSuccess(data) {
      console.log("data:")
      console.log(data)
      vm.merchantPrivacyPolicies = [];

      if (data && data.length > 0) {
        data.forEach(function (pp) {
          var mpp = {
            policyId: pp.id,
            code: pp.code,
            merchant: pp.merchant,
            mandatory: pp.mandatory,
            approved: false,
            merchantCode: pp.merchant.code
          };
          if (pp.description && pp.description.length > 0) {
            pp.description.forEach(function (ppDescr) {
              if (ppDescr.language && ppDescr.language.toUpperCase() === vm.lang.toUpperCase()) {
                mpp.privacyPolicy = ppDescr.description;
                mpp.policyItem = ppDescr;
                if (ppDescr.url) {
                  mpp.policyItem.urls = ppDescr.url.split(',');
                }
              }
            });
          }

          vm.merchantPrivacyPolicies.push(mpp);
        });
      }

    }

    function onError(error) {
      AlertService.error(error.data.message);
    }


    if ($location.search().merchantCode) {
      vm.merchantCode = $location.search().merchantCode.toUpperCase();
    } else {

      //vm.merchantCode = 'GARANTEASY';
    }


    $timeout(function () { angular.element('#login').focus(); });

    loadMerchantPrivacyPolicies();


    $timeout(function () { angular.element('#login').focus(); });

    vm.acceptPrivacy = function () {
      PrivacyPolicy.requiredPost(vm.merchantPrivacyPolicies, onSuccessAccept, onError);
    };

    function onSuccessAccept(data) {
      Principal.identity(true).then(function (account) {
        $state.go('receipt', null, { reload: true });
      });

    }

  }
})();
