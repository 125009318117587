(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyClaimRuleDialogController', WarrantyClaimRuleDialogController);

    WarrantyClaimRuleDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', '$timeout','Product', 'Merchant', 'MerchantLookup', 'Brand', 'BrandLookup', 'WarrantyClaimRule', 'DataUtils',  'ProductLookup', 'AlertService', 'Shop', 'ShopLookup'];

    function WarrantyClaimRuleDialogController ($scope, $stateParams, $uibModalInstance, $q, entity, $timeout, Product, Merchant, MerchantLookup, Brand, BrandLookup, WarrantyClaimRule, DataUtils, ProductLookup, AlertService, Shop, ShopLookup) {
        var vm = this;

        vm.warrantyClaimRule = entity;
        vm.clear = clear;
        vm.save = save;
        vm.products = Product.query();
        WarrantyClaimRule.categories({}, onCatSuccess, onCatError);
        WarrantyClaimRule.authorities({}, onAuthSuccess, onAuthError);

        function onCatSuccess (result) {
            vm.categories = result;
        }
        function onCatError (error) {
            AlertService.error(error.data.message);
        }

        function onAuthSuccess (result) {
            vm.authorities = result;
        }
        function onAuthError (error) {
            AlertService.error(error.data.message);
        }

// BRANDS ***
        vm.brands = Brand.query({filter: 'product-is-null'});
        $q.all([vm.warrantyClaimRule.$promise, vm.brands.$promise]).then(function() {
            if (!vm.warrantyClaimRule.brand || !vm.warrantyClaimRule.brand.id) {
                return $q.reject();
            }
            return Brand.get({id : vm.warrantyClaimRule.brand.id}).$promise;
        }).then(function(brand) {
            vm.brands.push(brand);
        });
        vm.isValidInput = isValidInput;
        vm.hasBrand = null;
        vm.validBrand = null;

        $scope.resetBrand = function() {
           	vm.product.brand = null;
        }

        $scope.loadBrands = function(searchQuery) {
            if (searchQuery){
            	BrandLookup.query({query: searchQuery}, function(result) {
                    $scope.brands = result;
                }, function(response) {
                    if(response.status === 404) {
                        $scope.brands = Brand.query({isActive: true});
                    }
                });
            } else {
                $scope.brands = Brand.query({isActive: true});
            }
        };


// MERCHANT ****
        vm.merchants = Merchant.query({});
        $q.all([vm.warrantyClaimRule.$promise, vm.merchants.$promise]).then(function() {
            if (!vm.warrantyClaimRule.merchant || !vm.warrantyClaimRule.merchant.id) {
                return $q.reject();
            }
            return Merchant.get({id : vm.warrantyClaimRule.merchant.id}).$promise;
        }).then(function(merchant) {
            vm.merchants.push(merchant);
        });

        $scope.loadMerchants = function(searchQuery) {
            if (searchQuery){
                MerchantLookup.query({query: searchQuery}, function(result) {
                    $scope.merchants = result;
                }, function(response) {
                    if(response.status === 404) {
                        $scope.merchants = Merchant.query({isActive: true}, onSuccessMerchant, onErrorMerchant);
                    }
                });
            } else {
                $scope.merchants = Merchant.query({isActive: true}, onSuccessMerchant, onErrorMerchant);
            }
        };

        function onSuccessMerchant (result) {
            $scope.merchants = result;
        }
        function onErrorMerchant (error) {
            AlertService.error(error.data.message);
        }

        // PRODUCTS *******

        $scope.loadProducts = function(searchQueryProducts) {
            if (searchQueryProducts && searchQueryProducts.trim().length > 0){
                ProductLookup.query({query: searchQueryProducts}, function(result) {
                    $scope.products = result;
                }, function(response) {
                    if(response.status === 404) {
                        $scope.products = Product.query({isActive: true},onSuccess, onError);
                    }
                });
            } else {
                Product.query({isActive: true},onSuccess, onError);
            }
        };

        function onSuccess (result) {
            $scope.products = result;
        }
        function onError (error) {
            AlertService.error(error.data.message);
        }

        //FINE PRODUCTS *****

// SHOPS *********
        vm.shops = Shop.query({filter: 'receipt-is-null'});

        $scope.loadShops = function(searchQuery) {
            if (searchQuery && searchQuery.trim().length > 1){
                ShopLookup.query({query: searchQuery}, function(result) {
                    $scope.shops = result;
                }, function(response) {
                    if(response.status === 404) {
                        $scope.shops = Shop.query({isActive: true});
                    }
                });
            } else {
                $scope.shops = Shop.query({isActive: true});
            }
        };




        // FINE SHOPS *********






        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        function save () {
            vm.isSaving = true;
            if (vm.warrantyClaimRule.id !== null && vm.warrantyClaimRule.id !== undefined) {
                WarrantyClaimRule.update(vm.warrantyClaimRule, onSaveSuccess, onSaveError);
            } else {
                WarrantyClaimRule.save(vm.warrantyClaimRule, onSaveSuccess, onSaveError);
            }
        }

        // Constraints beyond the ones in the form
        function isValidInput () {
        	return true;
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyApp:warrantyClaimRuleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
