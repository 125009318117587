(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('LinkDialogController', LinkDialogController);

    LinkDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProductResources', 'Product'];

    function LinkDialogController ($scope, $stateParams, $uibModalInstance, entity, ProductResources, Product) {
        var vm = this;

        function loadProduct (id) {
            Product.get({id: id}, function(result) {
                vm.product = result;
            });
        }

        vm.link = entity;
        vm.linkType = {0 : 'MANUAL', 1 : 'VIDEO'};
        if (vm.link.productId == null) {
            vm.link.productId = $stateParams.ppId;
        }

        if(vm.produt == null){
            vm.product = loadProduct(vm.link.productId);
        }

        if(vm.link.product == null){
            vm.link.product = {id:vm.link.productId};
        }


        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }



        function save () {
            if(vm.link.localCopy == null){
                vm.link.localCopy = false;
            }
            vm.isSaving = true;
            if (vm.link.id !== null) {
                ProductResources.update(vm.link, onSaveSuccess, onSaveError);
            } else {
                    ProductResources.save(vm.link,onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyApp:maintenanceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
