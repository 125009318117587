(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window'];

    function DataUtils($window) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            resizeifNecessary: resizeifNecessary
        };

        return service;

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 35) {
                return text;
            }
            return text ? (text.substring(0, 20) + '...' + text.slice(-10)) : '';
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile(type, data) {
            var objbuilder = '';
            objbuilder += ('<object width="100%" height="100%" ');
            objbuilder += ('  class="internal">');
            objbuilder += ('<embed width="100%" height="100%" src="data:' + type + ';base64,');
            objbuilder += (data);
            objbuilder += ('" type="' + type + '" />');
            objbuilder += ('</object>');
            var pop = $window.open("", '_blank', 'height=300,width=400');
            pop.document.write(objbuilder);

            // $window.open(objbuilder, '_blank', 'height=300,width=400');
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function resizeifNecessary(file, cb) {
          var fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = function (e) {
              if (file.size  > 5120000 && file.type.indexOf('image') !== -1) {
                var canvas = document.createElement('canvas');
                var img = document.createElement('img');
                img.onload = function () {
                  var width = img.width;
                  var height = img.height;
                  canvas.width = width;
                  canvas.height = height;
                  var ctx = canvas.getContext('2d');
                  ctx.drawImage(img, 0, 0, width, height);
                  var quality = 0.9;
                  if (file.size > 17000000) {
                    quality = 0.7;
                  } else if (file.size > 14000000) {
                    quality = 0.75;
                  } else if (file.size > 10000000) {
                    quality = 0.8;
                  } else if (file.size > 7500000) {
                    quality = 0.85;
                  }
                  var base64Data = canvas.toDataURL('image/jpeg', quality);
                  base64Data = base64Data.substr(base64Data.indexOf('base64,') + 'base64,'.length);
                  cb(base64Data);
                };
                img.src = e.target.result;
              } else {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
              }
          };
        }
    }
})();
