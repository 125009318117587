(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ImportController', ImportController);

    ImportController.$inject = ['$scope', '$rootScope', '$stateParams',  '$state','Principal', 'User', 'JhiLanguageService', 'DataUtils', 'ImportService', '$timeout'];

    function ImportController ($scope, $rootScope, $stateParams, Principal,  User, $state, JhiLanguageService, DataUtils, ImportService, $timeout) {
    	
        var vm = this;
        vm.process = null;
        vm.poll = true;
        
        var loopProcess = function(){
        	if(vm.poll){
        	$timeout(function(){
        		ImportService.get({id:1}, function(ret) {
        			vm.process = ret;
            		});
        		loopProcess();
        	},5000)
        }
        };
        
        loopProcess();
        
        vm.isInProcess = function(){
        	var ret = vm.process;
        	return ret && ret.progress != ret.total;
        		  	
        };
        
        vm.uploadFile = function ($file) {
        	vm.productImportResource = {};
            
            vm.byteSize = DataUtils.byteSize;
            vm.openFile = DataUtils.openFile;
            vm.fileTooLarge = false;
                if ($file && $file.$error === 'pattern') {
                    return;
                }
                if ($file) {
                	
                        vm.fileTooLarge = false;
    	                DataUtils.toBase64($file, function(base64Data) {
    	                    $scope.$apply(function() {
    	                    	vm.productImportResource.description = $file.name;
    	                    	vm.productImportResource.fileData = base64Data;
    	                    	vm.productImportResource.contentType = $file.type;
    	                    	ImportService.insert(vm.productImportResource)
    	                    });
    	                });
                	
                	
                }
        };
        
        
        
        vm.widthProgress = function(){
        	return 'width: 80%';
        }
    $scope.$on('$destroy', function(event, next, current) {
    	vm.poll = false;
    });
    }
})();
