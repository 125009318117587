angular
  .module('garanteasyApp')
  .component('paymentInfo', {
    templateUrl: 'app/components/payment-info/payment-info.component.html',
    controller: PaymentInfoController,
    controllerAs: 'vm',
    bindings: {
      receipt: '<',
      customerCards: '<'
    }
  });

PaymentInfoController.$inject = ['$scope', '$element', '$log', 'ProductLookup', 'AlertService', 'Receipt', 'Card', 'CMS_URI', 'CardTypeCmsService'];

function PaymentInfoController($scope, $element, $log, ProductLookup, AlertService, Receipt, Card, CMS_URI, CardTypeCmsService) {
  var vm = this;
  vm.isOfficial = isOfficial;
  vm.datePickerOpenStatus = {};
  vm.datePickerOpenStatus.paymentDate = false;
  vm.openCalendar = openCalendar;
  vm.useCard = false;
  vm.card;
  vm.onCardSaveSuccess = onCardSaveSuccess;
  vm.onCardCancel = onCardCancel;
  vm.cmsCardData;

  loadCardFromCms();

  function loadCardFromCms() {
    if (vm.receipt && vm.receipt.card && vm.receipt.card.cmsReference) {
      CardTypeCmsService.getCardByReference(vm.receipt.card).then(onSuccessLoadCmsCard, onErrorLoadCmsCard);
    }
  }

  function onSuccessLoadCmsCard(cmsCard) {
    vm.cmsCardData = cmsCard[0];
  }

  function onErrorLoadCmsCard() {
    console.log("cms card loading error");
  }

  function isOfficial() {
    if (vm.receipt)
      return vm.receipt.source === 'MERCHANT' || vm.receipt.source === 'CLAIM' || vm.receipt.source === 'CLAIM_RESOLVED';
  }

  vm.onChangeCard = function () {
    if (vm.receipt && vm.receipt.cardId) {
      var card = vm.customerCards.find(function (card) {
        return card.id === vm.receipt.cardId;
      });
      if (card.cmsReference) {
        CardTypeCmsService.getCardByReference(card).then(onSuccessLoadCmsCard, onErrorLoadCmsCard);
      }
    }
  };

  function openCalendar(date) {
    vm.datePickerOpenStatus[date] = true;
  }

  vm.saveSecond = function () {
    Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
  };

  vm.getCardImage = function () {

    if (!vm.cmsCardData || !vm.cmsCardData.image) {
      return '<i class="garanteasy-icon garanteasy-cartadicreditobucata"></i>';
    } else {
      return '<img src="' + CMS_URI + vm.cmsCardData.image + '" class="img-responsive">';
    }
  };

  function onCardSaveSuccess(result) {
    vm.customerCards.push(result);
    vm.card = null;

  }

  function onCardCancel() {
    vm.card = null;
  }

  function onSaveSuccess(result) {
    vm.isSaving = false;
    vm.isEdit = false;
    vm.isEdit2 = false;
    vm.isEdit3 = false;
  }

  vm.onCmsCardChange = function (newCmsData) {
    vm.cmsCardData = newCmsData;
  }

  function onSaveError(error) {
    vm.error = error;
    /*if(error.statusText === 'Forbidden'){
        AlertService.error("zdfsf");
    }*/
    vm.isSaving = false;
    vm.isEdit = false;
    vm.isEdit2 = false;
    vm.isEdit3 = false;
  }
}
