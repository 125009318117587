(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('BrandLookup', BrandLookup);

    BrandLookup.$inject = ['$resource'];

    function BrandLookup($resource) {
        var resourceUrl =  'api/_lookup/brands';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
