(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Link', Link);

    Link.$inject = ['$resource', 'DateUtils', '$stateParams'];

    function Link ($resource, DateUtils, $stateParams) {
        var resourceUrl =  'api/product-link/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var dataToSend = angular.copy(data);

                    return angular.toJson(dataToSend);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                   
                    return angular.toJson(dataToSend);
                }
            },

        });

    }
})();
