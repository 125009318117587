(function(){
    'use strict';

    angular
    .module('garanteasyApp')
    .directive('onErrorSrc', onErrorSrc);

    onErrorSrc.$inject   = [];

    function onErrorSrc(scope) {
        return {
            link: function(scope, element, attrs) {
                element.bind('error', function() {
                  if (attrs.src != attrs.onErrorSrc) {
                    attrs.$set('src', attrs.onErrorSrc);
                  }
                });
                
                attrs.$observe('ngSrc', function(value) {
                  if (!value && attrs.onErrorSrc) {
                    attrs.$set('src', attrs.onErrorSrc);
                  }
                });
                }
        };
    }})();
