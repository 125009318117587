(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('ExtensionLookup', ExtensionLookup);

    ExtensionLookup.$inject = ['$resource'];

    function ExtensionLookup($resource) {
        var resourceUrl = 'api/warranties/extension';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();
