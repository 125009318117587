(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .filter('booleanAsYesNo', booleanAsYesNo);

    function booleanAsYesNo() {
        return booleanAsYesNoFilter;

        function booleanAsYesNoFilter (input) {
            if (input === null || input === undefined) {
                input = false;
            }
            return input ? 'global.yes' : 'global.no';
        }
    }
})();
