(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .factory('ReceiptSearch', ReceiptSearch);

  ReceiptSearch.$inject = ['$resource', 'DateUtils', '$log'];

  function ReceiptSearch($resource, DateUtils, $log) {
    var resourceUrl = 'api/_search/receipts/:id';
    var transformResponse = function (data) {
      if (data) {
        data.creationDate = DateUtils.convertLocalDateFromServer(data.creationDate);
        data.buyingDate = DateUtils.convertLocalDateFromServer(data.buyingDate);
        if (data.warrantyAggregate) {
          data.warrantyAggregate.forEach(function (item) {
            if (item.warranty) {
              item.warranty.appliesFrom = DateUtils.convertLocalDateFromServer(item.warranty.appliesFrom);
              item.warranty.appliesTo = DateUtils.convertLocalDateFromServer(item.warranty.appliesTo);
            }
          });
        }

        if (data.purchasedProductWarranty) {

          var dateFrom = data.buyingDate;

          data.purchasedProductWarranty.expirationDate = DateUtils.convertLocalDateFromServer(data.purchasedProductWarranty.expirationDate);

          if (data.purchasedProductWarranty.purchasedProduct) {
            data.purchasedProductWarranty.purchasedProduct.deliveryDate = DateUtils.convertLocalDateFromServer(data.purchasedProductWarranty.purchasedProduct.deliveryDate);
            if (data.purchasedProductWarranty.purchasedProduct.deliveryDate)
              dateFrom = data.purchasedProductWarranty.purchasedProduct.deliveryDate;
          }


          var dateTo = data.purchasedProductWarranty.expirationDate;

          if (_.isNull(dateFrom)) {
            dateFrom = new Date();
          }

          data.purchasedProductWarranty.startDate = dateFrom;
          dateFrom = new Date(dateFrom);

          const one_day = 1000 * 60 * 60 * 24;
          dateTo = new Date(dateTo);

          data.purchasedProductWarranty.dayToExpiration = Math.round((dateTo.getTime() - new Date()) / one_day);
        }
      }
      return data;
    };
    var transformResponseArray = function (data, headersGetter, status) {
      if (status === 200) {
        return _.map(angular.fromJson(data), transformResponse);
      } else {
        $log.error("Errore fetching data", data);
        return null;
      }
    };
    return $resource(resourceUrl, {}, {
      'query': { method: 'GET', isArray: true, transformResponse: transformResponseArray }
    });
  }
})();
