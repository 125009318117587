(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CustomerDetailController', CustomerDetailController);

    CustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Customer', 'User', 'Gift', 'Receipt', 'Address', 'MerchantPrivacyPolicy', 'AlertService', '$state'];

    function CustomerDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Customer, User, Gift, Receipt, Address, MerchantPrivacyPolicy, AlertService, $state) {
        var vm = this;
        vm.merchantPrivacyPolicies = loadMerchantPrivacyPolicies;
        vm.customer = entity;
        vm.onEditCustomer = onEditCustomer;
        vm.onCancelCustomer = onCancelCustomer;
        checkAccess();
        function checkAccess() {
            Principal.identity().then(function(account) {
                vm.account = account; });
        }

        loadMerchantPrivacyPolicies();

        function loadMerchantPrivacyPolicies() {
            MerchantPrivacyPolicy.getByCustomer({customerId : vm.customer.id}, onSuccess, onError);
        }

        vm.approve = function (mpp, input) {
            for(var i = 0; i < vm.merchantPrivacyPolicies.length; i++ ) {
                if (vm.merchantPrivacyPolicies[i].id === mpp.id){
                    var merchantPrivacyPolicy = mpp;
                    merchantPrivacyPolicy.approved = input;
                    MerchantPrivacyPolicy.update(merchantPrivacyPolicy, onSaveSuccess, onSaveError);
                }}
        }
        function onSaveSuccess (result) {
            $state.go('customer.detail', null, { reload: true });
        }

        function onSaveError () {
            $state.go('customer.detail', null, { reload: true });
        }

        function onSuccess(data) {
            /*vm.merchantPrivacyPolicies = [];
            data.forEach(function(mpp){
                if (mpp.customer.id && mpp.customer.id === vm.customer.id) {
                    vm.merchantPrivacyPolicies.push(mpp);
                }
            });*/
            vm.merchantPrivacyPolicies = data;
        }

        function onError(error){
            AlertService.error(error.data.message);
        }

        if($stateParams.from) {
            $scope.from = $stateParams.from
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:customerUpdate', function(event, result) {
            vm.customer = result;
        });

        function onEditCustomer() {
          vm.editCustomer = true;
        }

        function onCancelCustomer(){
          vm.editCustomer = false;
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
