(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('LinkDeleteController',LinkDeleteController);

    LinkDeleteController.$inject = ['$uibModalInstance', 'entity', 'Product', 'ProductResources'];

    function LinkDeleteController($uibModalInstance, entity, Product, ProductResources) {
        var vm = this;

        vm.link = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;


        vm.loadLink = loadLink;
       	loadLink(vm.link.id);

        function loadLink (id) {
            ProductResources.get({id: id}, function(result) {
                vm.link = result;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	ProductResources.delete({id: entity.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
