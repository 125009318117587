(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('error', {
        parent: 'app',
        url: '/error',
        data: {
          authorities: [],
          pageTitle: 'error.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/layouts/error/error.html'
          }
        },
        resolve: {
          mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('error');
            return $translate.refresh();
          }]
        }
      })
      .state('accessdenied', {
        parent: 'app',
        url: '/accessdenied',
        data: {
          authorities: []
        },
        views: {
          'content@': {
            templateUrl: 'app/layouts/error/accessdenied.html',
            controller: 'ErrorController'
          }
        },
        resolve: {
          mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('error');
            $translatePartialLoader.addPart('receipt');
            $translatePartialLoader.addPart('paymentType');
            $translatePartialLoader.addPart('dataSource');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('receiptType');
            return $translate.refresh();
          }]
        }
      });
  }
})();
