(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .filter('receiptType', receiptType);

    receiptType.$inject = ['$translate'];
    function receiptType($translate) {
        return receiptTypeFilter;

        function receiptTypeFilter (input) {
            if (input === null || input === undefined) {
                input = null;
            }
            else {
                    input = 'garanteasyApp.ReceiptType.'+input;
            }
            return input;
        }
    }
})();
