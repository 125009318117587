'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .controller('NavbarController', NavbarController);

  NavbarController.$inject = ['$scope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'Account', '$rootScope', '$translate', 'JhiLanguageService'];

  function NavbarController($scope, $state, Auth, Principal, ProfileService, LoginService, Account, $rootScope, $translate, JhiLanguageService) {
    var vm = this;
    vm.state = $state;
    vm.link = "http://it.garanteasy.com";
    vm.lang = $translate.use();
    vm.isOpenSearch = false;

    vm.status = {
      isopen: false
    };

    vm.toggled = function (open) {
      $log.log('Dropdown is now: ', open);
    };

    vm.toggleDropdown = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vm.status.isopen = !$scope.status.isopen;
    };

    $scope.appendToEl = angular.element(document.querySelector('#dropdown-long-content'));


    vm.changeLinkGaranteasy = function (language) {
      vm.lang = language;
      if (language === 'it') {
        vm.link = "http://it.garanteasy.com";
      }
      else {
        vm.link = "http://en.garanteasy.com";
      }
    };
    $scope.$watch(function () {
      return $rootScope.showSearchBar;
    }, function () {
      if ($rootScope.showSearchBar) {
        vm.show = true;
      }
      else {
        vm.show = false;
      }
    }, true);

    $scope.$on('globalSearchChange', function (evt, stringData) {
      if (!stringData) {
        vm.isOpenSearch = false;
      }
    });



    /*if($state.current.name !== 'purchased-product' && $state.current.name !== 'receipt')
    {
        vm.showSearchBar = false;
    }*/
    vm.isNavbarCollapsed = true;
    vm.isAuthenticated = Principal.isAuthenticated;
    /*vm.identity = Account.get({id : $stateParams.id}).$promise;*/

    ProfileService.getProfileInfo().then(function (response) {
      if (response) {
        vm.inProduction = response.inProduction;
        vm.swaggerEnabled = response.swaggerEnabled;
      }
    });

    vm.login = login;
    vm.logout = logout;
    vm.toggleNavbar = toggleNavbar;
    vm.collapseNavbar = collapseNavbar;
    vm.openSearch = openSearch;
    vm.$state = $state;

    function login() {
      // collapseNavbar();
      // LoginService.open();
      $state.go('home');
    }

    function logout() {
      collapseNavbar();
      Auth.logout();
      vm.account = null;
      $state.go('home');
    }

    function toggleNavbar() {
      vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
    }

    function collapseNavbar() {
      Auth.resetPreviousState();
      vm.isNavbarCollapsed = true;
    }

    $scope.$on('authenticationSuccess', function () {
      getAccount();
    });



    getAccount();

    function getAccount() {
      Principal.identity().then(function (account) {
        if (account) {
          vm.account = account;
          vm.isAuthenticated = Principal.isAuthenticated;

          Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
            if (result) {
              vm.isAdmin = true;
            } else {
              vm.isAdmin = false;
            }
          });
          Principal.hasAuthority('ROLE_SUPERVISOR').then(function (result) {
            if (result) {
              vm.isSupervisor = true;
            } else {
              vm.isSupervisor = false;
            }
          });
          JhiLanguageService.getCurrent().then(function (current) {
            if (account.langKey && account.langKey !== current) {
              $translate.use(account.langKey);
            }
          });
        }
      });
    }

    function openSearch() {
      if (vm.show) {
        vm.isOpenSearch = true;
      }
    }
  }
})();
