/* globals $ */
'use strict';
(function() {
  angular
        .module('garanteasyApp')
        .directive('readMore', readMore);

  function readMore () {

    return {
        restrict: 'A',
        transclude: true,
        replace: true,
        template: '<p></p>',
        scope: {
          moreText: '@',
          lessText: '@',
          words: '@',
          ellipsis: '@',
          char: '@',
          limit: '@',
          content: '<'
        },
        link: function(scope, elem, attr, ctrl, transclude) {
          var moreText = angular.isUndefined(scope.moreText) ? ' <a class="read-more">Read More...</a>' : ' <a class="read-more">' + scope.moreText + '</a>',
            lessText = angular.isUndefined(scope.lessText) ? ' <a class="read-less">Less ^</a>' : ' <a class="read-less">' + scope.lessText + '</a>',
            ellipsis = angular.isUndefined(scope.ellipsis) ? '' : scope.ellipsis,
            limit = angular.isUndefined(scope.limit) ? 150 : scope.limit;
          if (scope.content) {
            readmore(scope.content);
          } else {
            transclude(scope.$parent, function(clone, scope) {
              readmore(clone.text().trim());
            });
          }

          elem.find('.more-text').hide();

          function readmore(text) {
            var text = text,
              orig = text,
              regex = /\s+/gi,
              charCount = text.length,
              wordCount = text.trim().replace(regex, ' ').split(' ').length,
              countBy = 'char',
              count = charCount,
              foundWords = [],
              markup = text,
              more = '';

            if (!angular.isUndefined(attr.words)) {
              countBy = 'words';
              count = wordCount;
            }

            if (countBy === 'words') { // Count words

              foundWords = text.split(/\s+/);

              if (foundWords.length > limit) {
                text = fixHtml(foundWords.slice(0, limit)).join(' ') + ellipsis;
                more = fixHtml(foundWords.slice(limit, count).join(' '));
                markup = text + moreText + '<span class="more-text">' + more + lessText + '</span>';
              }

            } else { // Count characters

              if (count > limit) {
                text = fixHtml(orig.slice(0, limit)) + ellipsis;
                more = fixHtml(orig.slice(limit, count));
                markup = text + moreText + '<span class="more-text">' + more + lessText + '</span>';
              }

            }

            elem.append(markup);
            elem.find('.read-more').on('click', function() {
              $(this).hide();
              elem.find('.more-text').addClass('show').slideDown();
            });
            elem.find('.read-less').on('click', function() {
              elem.find('.read-more').show();
              elem.find('.more-text').hide().removeClass('show');
            });

          }

          function fixHtml(html){
            var div = document.createElement('div');
            div.innerHTML= html;
            return (div.innerHTML);
          }
        }
      }

  }
})();
