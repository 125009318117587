'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .controller('CmsController', CmsController);


  CmsController.$inject = ['$state', '$stateParams', '$translate', 'CmsService', '$log', '$sce', '$window', 'JhiLanguageService'];

  function CmsController($state, $stateParams, $translate, CmsService, $log, $sce, $window, JhiLanguageService) {
    var vm = this;

    vm.node = '';
    vm.lang = 'eng';
    vm.itemsPerPage = 8;
    vm.page = 1;
    vm.nodes = {
      'tutorial': 'garanteasyApp.receipt.sidebar.tutorial',
      'guide': 'garanteasyApp.receipt.sidebar.guide',
      'cataloghi': 'garanteasyApp.receipt.sidebar.catalogues',
      'warranty': 'garanteasyApp.receipt.sidebar.officialWarranties',
      'faq': 'garanteasyApp.receipt.sidebar.faq',
      'practice': 'garanteasyApp.receipt.sidebar.practice'
    };
    vm.data = [];
    vm.pagedData = [];

    vm.init = init;
    vm.clearData = clearData;
    vm.loadPage = loadPage;

    init();

    function clearData(dataInput) {
      return $sce.trustAsHtml(dataInput);
    }

    function init() {
      if (vm.nodes[$stateParams.node]) {
        vm.node = $stateParams.node;
      }
      if ($translate.use()) {
        vm.lang = $translate.use();
      }
      if (vm.node === 'faq' || vm.node === 'practice') {
        JhiLanguageService.getCurrent().then(function (current) {
          CmsService.getFaqData('CONSUMER', vm.node, null, current).then(function (res) {
            vm.data = res.map(function (element) {
              return {
                id: element.uuid,
                title: element.title,
                body: element.body
              };
            });
            vm.loadPage(vm.page);
          });
        });

      } else {
        CmsService.getNodeData(vm.lang, vm.node, ['title', 'body']).then(function (res) {
          vm.data = [];
          if (res && res.length) {
            res.forEach(function (element) {
              if (element && element.attributes && element.attributes.body) {
                var data = element.attributes.body;
                data.title = element.attributes.title;
                data.id = element.id;
                vm.data.push(data);
              }
            });
          }
          $log.debug(vm.data);
        });
      }
    }

    function loadPage(page) {
      $window.scrollTo(0, angular.element('#faq-container').offsetTop);
      vm.pagedData = vm.data.slice(
        (page - 1) * vm.itemsPerPage,
        page * vm.itemsPerPage
      );
    }

  }
})();
