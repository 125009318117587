(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('BrandDetailController', BrandDetailController);

    BrandDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Brand', 'User','$location', '$window'];

    function BrandDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Brand, User, $location, $window) {
        var vm = this;

        vm.brand = entity;

        vm.isOwner=null;
        vm.isAdmin=null;

        checkAccess();

        vm.getUrl = function () {
            $window.location.href = ''+vm.brand.warrantyUrl;
        }

        function checkAccess() {
            Principal.identity().then(function(account) {
            	vm.isOwner=vm.brand.createdBy.id == account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                    	vm.isAdmin=true;
                    } else {
                    	vm.isAdmin=false;
                    }
                });
            });
        }

        if (typeof previousState.params.id != 'undefined') {
            vm.previousState = previousState.name+'({id: \''+previousState.params.id+'\'})';
        } else {
        	vm.previousState = previousState.name;
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:brandUpdate', function(event, result) {
            vm.brand = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
