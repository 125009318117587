(function(){
    'use strict';

    angular
    .module('garanteasyApp')
    .directive('remoteContent', remoteContent);

    remoteContent.$inject   = ['$http'];

    function remoteContent($http) {
        return {
            restrict: 'E',
            link: function(scope, element, attrs) {
                $http.post('/endpoint', {format:'html'})
                    .success(function(html) {
                    // remove wrapping html and body tags etc if necessary
                        angular.element(element).append(html);
                    })
                    .error(function(data, status, headers, config) {
                        angular.element(element).append("<h1>Work in progress</h1>");
                    });
            }
        };
    }})();
