(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('ActivateEmail', ActivateEmail);

    ActivateEmail.$inject = ['$resource'];

    function ActivateEmail ($resource) {
        var service = $resource('api/activateEmail', {}, {
            'get': { method: 'GET', params: {}, isArray: false}
        });

        return service;
    }
})();
