(function () {
  'use strict';

  var contactComponent = {
    templateUrl: 'app/entities/contact/contact.component.html',
    controller: ContactEditController,
    controllerAs: 'vm',
    bindings: {
      contact: '<',
      onSave: '<',
      onCancel: '<',
      onError: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('contactComponent', contactComponent);

  ContactEditController.$inject = ['$timeout', '$scope', 'Contact'];

  function ContactEditController($timeout, $scope, Contact) {
    var vm = this;
    vm.tmp = {};
    //vm.contact = entity;
    vm.clear = clear;
    vm.save = save;

    vm.$onInit = function () {
      if (!vm.contact || !vm.contact.id) {
        vm.contact = {
          channel: null,
          primaryContact: false,
          activated: false,
          id: null
        };
      }
      angular.copy(vm.contact, vm.tmp);
    };

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

    function clear() {
      if (typeof vm.onCancel === 'function') {
        angular.copy(vm.tmp, vm.contact);
        vm.onCancel();
      }
    }

    function save() {
      vm.isSaving = true;
      if (vm.contact.id !== null) {
        Contact.update(vm.contact, onSaveSuccess, onSaveError);
      } else {
        Contact.save(vm.contact, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      $scope.$emit('garanteasyApp:contactUpdate', result);
      if (typeof vm.onSave === 'function') {
        vm.onSave(result);
      }
      vm.isSaving = false;
    }

    function onSaveError(error) {
      if (typeof vm.onError === 'function') {
        vm.onError(error);
      }
      vm.isSaving = false;
    }

  }
})();
