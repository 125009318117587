(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('PurchasedProductWarrantyController', PurchasedProductWarrantyController);

  PurchasedProductWarrantyController.$inject = [
    "$scope",
    "$state",
    "$q",
    "DataUtils",
    "PurchasedProductWarranty",
    "Principal",
    "PurchasedProductWarrantySearch",
    "ParseLinks",
    "AlertService",
    "$stateParams",
    "Searchbar",
    "$rootScope",
    "$translate",
    "Receipt",
    "AuthServerProvider",
    "BrandService",
    "CmsWarrantyService",
    "$localStorage",
    "$log",
  ];

  function PurchasedProductWarrantyController(
    $scope,
    $state,
    $q,
    DataUtils,
    PurchasedProductWarranty,
    Principal,
    PurchasedProductWarrantySearch,
    ParseLinks,
    AlertService,
    $stateParams,
    Searchbar,
    $rootScope,
    $translate,
    Receipt,
    AuthServerProvider,
    BrandService,
    CmsWarrantyService,
    $localStorage,
    $log
  ) {
    var vm = this;

    $rootScope.querySearch = "";
    $rootScope.showSearchBar = true;
    $scope.stateData.vm = vm;

    vm.getValidReceiptsFistCall = true;
    vm.gridView = $rootScope.gridView;
    vm.purchasedProductWarranties = [];
    vm.isLoading = false;
    vm.predicate = "expirationDate";
    vm.page = 0;
    vm.reverse = false;
    vm.currentUserId = null;
    vm.isAdmin = null;
    vm.imageUrl = "../../../content/images/";
    vm.getImage = getImage;
    vm.elaboratingData = false;
    // vm.warrantyImages = {
    //   'LEGAL': 'ICONA_GARANZIE_LEGALE',
    //   'CONVENTIONAL': 'ICONA_GARANZIE_CONVENZIONALE',
    //   'SERVICE_CONTRACT': 'ICONA_GARANZIE_ASSISTENZA',
    //   'INSURANCE': 'ICONA_GARANZIE_POLIZZA'
    // };
    vm.warrantyIcons = {
      LEGAL: "fa-balance-scale",
      CONVENTIONAL: "fa-industry",
      SERVICE_CONTRACT: "fa-toolbox",
      INSURANCE_POLICY: "fa-umbrella",
    };
    vm.links = {
      last: 0,
    };

    vm.canAssociateProduct = canAssociateProduct;
    vm.abbreviate = DataUtils.abbreviate;
    vm.init = init;
    vm.checkOfficial = checkOfficial;
    vm.loadAll = loadAll;
    vm.checkAccess = checkAccess;
    vm.loadPage = loadPage;
    vm.clear = clear;
    vm.search = search;
    vm.reset = reset;
    vm.getIcon = getIcon;
    //vm.getImage = getImage;
    vm.getValidReceipts = getValidReceipts;
    vm.resolveBrand = resolveBrand;
    vm.resolveGuarantor = resolveGuarantor;
    vm.loadCmsWarranties = loadCmsWarranties;
    vm.setViewType = setViewType;

    vm.init();

    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.currentUserId = account.id;
        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    checkGrid();
    function checkGrid() {
      if ($localStorage.purchasedProductWarrantyView) {
        vm.setViewType($localStorage.purchasedProductWarrantyView);
      }
    }

    vm.getWarrantyValidity = function (purchasedProductWarranty) {
      if (
        purchasedProductWarranty &&
        purchasedProductWarranty.warranty &&
        purchasedProductWarranty.warranty.validityUnit == "YEARS"
      ) {
        return purchasedProductWarranty.warranty.validity * 12;
      } else {
        return purchasedProductWarranty.warranty.validity;
      }
    };

    function setViewType(type) {
      if (type === "grid") {
        $localStorage.purchasedProductWarrantyView = "grid";
        vm.gridView = true;
      }
      if (type === "list") {
        $localStorage.purchasedProductWarrantyView = "list";
        vm.gridView = false;
      }
    }

    function checkOfficial(purchasedProductWarranty) {
      if (purchasedProductWarranty.receipt.source === "MERCHANT") {
        return true;
      }
    }

    function canAssociateProduct(purchasedProductWarranty) {
      var product = purchasedProductWarranty.purchasedProduct;
      var contractType = purchasedProductWarranty.warranty.contractType;
      var isExtraWarranty =
        contractType === "SERVICE_CONTRACT" ||
        contractType === "INSURANCE_POLICY";
      return !product && isExtraWarranty;
    }

    // lrkwz
    function resolveGuarantor(purchasedProductWarranty) {
      $log.debug("resolveGuarantor", purchasedProductWarranty);
      if (purchasedProductWarranty.warranty.contractType === "LEGAL") {
        if (
          purchasedProductWarranty.receipt &&
          purchasedProductWarranty.receipt.shop &&
          purchasedProductWarranty.receipt.shop.description
        ) {
          if (
            purchasedProductWarranty.purchasedProduct &&
            purchasedProductWarranty.purchasedProduct.producer
          )
            return purchasedProductWarranty.purchasedProduct.product.producer
              .name;
        }
      }
      if (purchasedProductWarranty.warranty.contractType === "CONVENTIONAL") {
        if (
          purchasedProductWarranty.purchasedProduct &&
          purchasedProductWarranty.purchasedProduct.product &&
          purchasedProductWarranty.purchasedProduct.product.producer &&
          purchasedProductWarranty.purchasedProduct.product.producer.name
        ) {
          if (
            purchasedProductWarranty.cmsWarrantyData &&
            vm.cmsWarrantyData.holder_company
          ) {
            return purchasedProductWarranty.cmsWarrantyData.holder_company;
          }
          if (
            purchasedProductWarranty.purchasedProduct &&
            purchasedProductWarranty.purchasedProduct.producer
          )
            return purchasedProductWarranty.purchasedProduct.product.producer
              .name;
        }
        if (
          purchasedProductWarranty.purchasedProduct &&
          purchasedProductWarranty.purchasedProduct.product &&
          purchasedProductWarranty.purchasedProduct.product.brand &&
          purchasedProductWarranty.purchasedProduct.product.brand.name
        ) {
          return purchasedProductWarranty.purchasedProduct.product.brand.name;
        }
      }
      if (
        purchasedProductWarranty.warranty.contractType === "SERVICE_CONTRACT" ||
        purchasedProductWarranty.warranty.contractType === "INSURANCE_POLICY"
      ) {
        return purchasedProductWarranty.warranty.description;
      }
      return purchasedProductWarranty.warranty.contractType;
    }

    function loadCmsBrand(dataElement, queryString) {
      var brandMap = new Map();
      if (
        dataElement.purchasedProduct &&
        dataElement.purchasedProduct.product &&
        dataElement.purchasedProduct.product.brand &&
        dataElement.purchasedProduct.product.brand.cmsReference
      ) {
        var brandId = dataElement.purchasedProduct.product.brand.id;
        var reference = dataElement.purchasedProduct.product.brand.cmsReference;
        brandMap.set(brandId, reference);
        queryString += reference + ",";
      }
      return queryString;
    }

    function loadCmsWarranties(loadedData) {
      var warrantyMap = new Map();
      var queryString = "";
      for (var i = 0; i < loadedData.length; i++) {
        if (
          loadedData[i] &&
          loadedData[i].warranty &&
          loadedData[i].warranty.reference
        ) {
          var productId = loadedData[i].id;
          var reference = loadedData[i].warranty.reference;
          warrantyMap.set(productId, reference);
          queryString += reference + ",";
        }
      }

      if (queryString.length != 0) {
        queryString = queryString.substring(0, queryString.length - 1);
        CmsWarrantyService.getWarrantyData(queryString).then(function (
          response
        ) {
          var resultMap = new Map();
          if (response) {
            for (var i = 0; i < response.length; i++) {
              if (response[i].title) {
                resultMap.set(response[i].uuid, response[i]);
              }
            }
            for (var i = 0; i < loadedData.length; i++) {
              if (
                loadedData[i] &&
                loadedData[i].warranty &&
                loadedData[i].warranty.reference
              ) {
                var warrantyCmsUuid = loadedData[i].warranty.reference;
                if (resultMap.has(warrantyCmsUuid)) {
                  loadedData[i].cmsWarrantyData =
                    resultMap.get(warrantyCmsUuid);
                }
              }
            }
          }
        });
      }
    }

    function resolveBrand(id) {
      return BrandService.getBrandData(id).then(function (response) {
        return response.title;
      });
    }

    function init() {
      vm.totalNumExpired = 0;
      vm.totalNumValid = 0;
      vm.totalNumExpiring = 0;
      vm.oldSearch = undefined;

      $scope.$on("$destroy", function (event, next, current) {
        $rootScope.querySearch = "";
        $rootScope.showSearchBar = false;
      });

      $scope.$on("$init", function (event, next, current) {
        $rootScope.showSearchBar = true;
      });

      setTimeout(function () {
        if (!vm.globalSearchChangeHandled) {
          vm.loadAll();
        }
      }, 500);
      vm.checkAccess();
      vm.getValidReceipts();
    }

    function getValidReceipts() {
      if (!vm.getValidReceiptsFistCall) {
        return;
      }
      Receipt.getValid({}, onSuccess, onError);
      vm.valid = vm.validReceipt;

      function onSuccess(data) {
        vm.validWarranties = data.valid;
        vm.expiredWarranties = data.expired;
        vm.expiringWarranties = data.expiring;
      }

      function onError(error) {
        AlertService.error(error.data.message);
      }
    }

    function loadAll(stringSearch) {
      if (vm.isLoading) return;
      vm.globalSearchChangeHandled = true;
      vm.isLoading = true;
      vm.currentSearch = stringSearch || vm.oldSearch;

      if (vm.currentSearch) {
        PurchasedProductWarrantySearch.query(
          {
            query: vm.currentSearch,
            page: vm.page,
            size: 20,
            sort: sort(),
          },
          onSuccess,
          onError
        );
      } else {
        PurchasedProductWarranty.query(
          {
            page: vm.page,
            size: 20,
            sort: sort(),
          },
          onSuccess,
          onError
        );
      }

      function sort() {
        var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
        if (vm.predicate !== "id") {
          result.push("id");
        }
        return result;
      }

      function onSuccess(data, headers) {
        var requests = [];

        vm.links = ParseLinks.parse(headers("link"));
        vm.totalItems = headers("X-Total-Count");
        var brandQueryString = "";
        for (var i = 0; i < data.length; i++) {
          if (
            data[i].status === "valid" ||
            (data[i].status === "expiring" && data[i].expirationDate)
          ) {
            const one_day = 1000 * 60 * 60 * 24;
            var dateTo = new Date(data[i].expirationDate);
            data[i].dayToExpiration = Math.round(
              (dateTo.getTime() - new Date()) / one_day
            );
          }

          brandQueryString = loadCmsBrand(data[i], brandQueryString);
        }

        vm.elaboratingData = true;
        //loadCmsWarranties(data);
        if (brandQueryString.length == 0) {
          for (var i = 0; i < data.length; i++) {
            const item = ((data[i] || {}).purchasedProduct || {}).product || {};
            if (
              !data[i].purchasedProduct ||
              item.underWarranty ||
              item.isProductWarranty
            ) {
              vm.purchasedProductWarranties.push(data[i]);
            }
          }
          vm.isLoading = false;
          vm.elaboratingData = false;
        } else {
          brandQueryString = brandQueryString.substring(
            0,
            brandQueryString.length - 1
          );
          requests.push(BrandService.getBrandData(brandQueryString));
        }
        $q.all(requests).then(function (responses) {
          manageBrandResponse(data, responses[0]);
        });
      }

      function manageBrandResponse(data, response) {
        var resultMap = new Map();
        if (response) {
          for (var i = 0; i < response.length; i++) {
            if (response[i].title) {
              resultMap.set(response[i].uuid, response[i].title);
            }
          }
          for (var i = 0; i < data.length; i++) {
            if (
              data[i].purchasedProduct &&
              data[i].purchasedProduct.product &&
              data[i].purchasedProduct.product.brand &&
              data[i].purchasedProduct.product.brand.cmsReference
            ) {
              var brandUuid =
                data[i].purchasedProduct.product.brand.cmsReference;
              if (resultMap.has(brandUuid)) {
                data[i].purchasedProduct.product.brand.name =
                  resultMap.get(brandUuid);
              }
            }

            vm.purchasedProductWarranties.push(data[i]);
          }
        }
        vm.isLoading = false;
        vm.elaboratingData = false;
      }

      function onError(error) {
        vm.isLoading = false;
        AlertService.error(error.message);
      }
    }

    function loadPage(page) {
      if (vm.page != page) {
        vm.page = page;
        vm.loadAll();
      }
    }

    function reset() {
      vm.page = 0;
      vm.purchasedProductWarranties = [];
      vm.loadAll();
    }

    function clear() {
      vm.purchasedProductWarranties = [];
      vm.links = null;
      vm.page = 0;
      vm.predicate = "id";
      vm.reverse = true;
      vm.searchQuery = null;
      vm.currentSearch = null;
      vm.loadAll();
    }

    function search(searchQuery) {
      if (!searchQuery) {
        return vm.clear();
      }
      vm.purchasedProductWarranties = [];
      vm.links = null;
      vm.page = 0;
      vm.predicate = "_score";
      vm.reverse = false;
      vm.currentSearch = searchQuery;
      vm.loadAll();
    }

    function getIcon(purchasedProductWarranty) {
      var icon = "fa-balance-scale";
      if (purchasedProductWarranty && purchasedProductWarranty.warranty) {
        icon =
          vm.warrantyIcons[purchasedProductWarranty.warranty.contractType] ||
          icon;
      }
      return icon;
    }

    function getImage(purchasedProductWarranty) {
      var item = ((purchasedProductWarranty || {}).warranty || {} || {})
        .warrantyType;
      if (item === "LEGAL") {
        return "/content/images/ICONA_GARANZIE_LEGALE.png";
      } else if (item === "CONVENTIONAL") {
        return "/content/images/ICONA_GARANZIE_CONVENZIONALE.png";
      } else if (item === "SERVICE_CONTRACT") {
        return "/content/images/ICONA_GARANZIE_ASSISTENZA.png";
      } else if (item === "INSURANCE") {
        return "/content/images/ICONA_GARANZIE_POLIZZA.png";
      } else {
        return "/content/images/ICONA_GARANZIE_LEGALE.png";
      }
    }
  }
})();
