'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .controller('HomeController', HomeController);

  HomeController.$inject = ['$scope', '$location', 'Principal', 'LoginService', '$rootScope', '$state', '$timeout', '$translate', 'Auth', 'Receipt', 'Customer', 'User', 'ROLE_USER', 'bsScreenSize', 'JhiLanguageService'];

  function HomeController($scope, $location, Principal, LoginService, $rootScope, $state, $timeout, $translate, Auth, Receipt, Customer, User, ROLE_USER, bsScreenSize, JhiLanguageService) {
    var vm = this;

    vm.account = null;
    vm.isAuthenticated = null;
    vm.login = LoginService.open;
    vm.register = register;
    vm.customers = [];
    vm.customersNotFlagEta = [];
    vm.name = 'home';
    vm.showRegisterForm = true;
    vm.screenSize = bsScreenSize.state;
    vm.settingsAccount = null;
    vm.hasPreviousState = false;

    if (($location.search() || {}).username) {
      vm.username = $location.search().username;
    }

    vm.closeAlert = function () {
      vm.show = false;
    };
    if (((Auth.getPreviousState() || {}).query || {}).username) {
      vm.username = Auth.getPreviousState().query.username;
      vm.showRegisterForm = false;
    }

    $scope.$on('authenticationSuccess', function () {
      getAccount();
    });

    getAccount();

    function getAccount() {
      Principal.identity().then(function (account) {
        vm.account = account;
        vm.isAuthenticated = Principal.isAuthenticated;
        if (account && !vm.hasPreviousState) {
          $state.go('dashboard');
        }
      });
    }

    function register() {
      $state.go('register');
    }

    vm.login = function () {
      Auth.login({
        username: vm.username,
        password: vm.password,
        rememberMe: vm.rememberMe
      }).then(function () {
        Principal.identity().then(function (account) {
          vm.account = account;
          Principal.getAuthorities().then(function (a) {
            if (a && a.length > 0) {
              a.forEach(function (item) {
                if (item === ROLE_USER) {
                  vm.testAcceptPrivacy = true;
                }
              });
            }
          });

          Customer.getAllCustomersFiltered({}, onSuccess, onError);
          /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
          var copyAccount = function (account) {
            return {
              activated: account.activated,
              serviceConsent: account.serviceConsent,
              commInfoConsent: account.commInfoConsent,
              commInfo3rdConsent: account.commInfo3rdConsent,
              /*
              firstName: account.firstName,
              lastName: account.lastName,
              */
              langKey: account.langKey,
              login: account.login
            };
          };
          function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.customers = data;
            // Disabilitato - penso che l'intento di questo sia fare completare i dati personali, era utilizzato quando il flag 16 anni era nel profilo utente
            // verrà riesumato se e quando servirà ancora.
            // if (vm.customers.length > 0) {
            //   Auth.storePreviousState('home');
            //   $state.go('customer');
            // } else {
            Principal.identity(true).then(function (account) {
              vm.settingsAccount = copyAccount(account);
              JhiLanguageService.getCurrent().then(function (current) {
                if (vm.settingsAccount.langKey !== current) {
                  $translate.use(vm.settingsAccount.langKey);
                }
              });
            });

            if (($state.current.name === 'register' || $state.current.name === 'activate' ||
              $state.current.name === 'finishReset' || $state.current.name === 'requestReset' ||
              $state.current.name === 'claimChiccoReceipt' || $state.current.name === 'home' ||
              $state.current.name === 'social-auth' || $state.current.name === 'social-register' ||
              $state.current.name === 'accept-privacy') && vm.testAcceptPrivacy) {
              $state.go('dashboard');
            } else if (!vm.testAcceptPrivacy) {
              $state.go('accept-privacy');
            }
            // }
          }

          function onError(error) { }
        });

        vm.authenticationError = false;

        $rootScope.$broadcast('authenticationSuccess');

        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
        // since login is succesful, go to stored previousState and clear previousState
        if (Auth.getPreviousState()) {
          var previousState = Auth.getPreviousState();
          if (previousState.name !== 'home') {
            Auth.resetPreviousState();
            vm.hasPreviousState = true;
            $state.go(previousState.name, previousState.params);
          }
        }
      }).catch(function () {
        vm.authenticationError = true;
        vm.show = true;
      });
    };

    vm.requestResetPassword = function () {
      $state.go('requestReset');
    };
  }
})();
