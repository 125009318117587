angular
  .module('garanteasyApp')
  .component('purchasedProduct', {
    templateUrl: 'app/components/purchased-product/purchased-product.component.html',
    controller: PurchasedProductController,
    controllerAs: 'vm',
    bindings: {
      purchasedProduct: '<',
      onUpdate: '<'
    }
  });

PurchasedProductController.$inject = ['$scope', '$element', '$log', 'ProductLookup', 'AlertService'];

function PurchasedProductController($scope, $element, $log, ProductLookup, AlertService) {
  var vm = this;

  vm.ALL_PRODUCTS = -1;
  vm.PREFERRED_PRODUCTS = 0;
  vm.NEW_PRODUCT = 1;

  $log.debug("Booting directive", vm);

  vm.$onInit = function() {
    $log.debug("Initializing directive", vm);
  };

  vm.isNewProductCollapsed = vm.PREFERRED_PRODUCTS;

  $scope.$watch('purchasedProduct', function( pproduct ){
    $log.debug("Purchasedproduct has changed", $scope.purchasedProduct, pproduct);
    vm.onUpdate( $scope.purchasedProduct );
  });

  vm.products = [];
  $scope.loadProducts = function (searchQuery) {
    if (vm.isNewProductCollapsed === vm.PREFERRED_PRODUCTS) {
      ProductLookup.query({}, function (results) {
        vm.products = results;
      });
    } else {
      ProductLookup.query({ query: searchQuery },
          function (result) {
            vm.products = result;
            angular.forEach(vm.products, function (product, key) {
              product.description = String(product.description).replace(/<[^>]+>/gm, '');
            });
          },
          function (response) {
            $log.error("Error querying products", response);
            AlertService.error("Error: " + response.status);
          }
        );
    }
  };

  vm.datePickerOpenStatus = [];
  vm.datePickerOpenStatus.deliveryDate = false;
  vm.datePickerOpenStatus.giftDate = false;

  vm.openCalendar = function (date) {
    vm.datePickerOpenStatus[date] = true;
  };

  $scope.$on('$destroy', function () {
    $log.debug("Destroing directive");
  });
}
