(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('MerchantPrivacyPolicy', MerchantPrivacyPolicy);

    MerchantPrivacyPolicy.$inject = ['$resource'];

    function MerchantPrivacyPolicy ($resource) {
        var resourceUrl =  'api/merchant-privacy-policy-resources/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateMpp': {
                method: 'PUT',
                url: 'api/merchant-privacy-policy-resources/'},
             'getByCustomer' : {
                 method: 'GET',
                 isArray: true,
                 url: 'api/merchant-privacy-policy-resources/by-customer/:customerId',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
            'getByUser' : {
                method: 'GET',
                isArray: true,
                url: 'api/merchant-privacy-policy-resources/by-user',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
