(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CardTypesController', CardTypesController);

    CardTypesController.$inject = ['$scope', '$state', 'CardType', 'CardTypesSearch'];

    function CardTypesController ($scope, $state, CardType, CardTypesSearch) {
        var vm = this;
        
        vm.cardTypes = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            CardType.query(function(result) {
                vm.cardTypes = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            CardTypesSearch.query({query: vm.searchQuery}, function(result) {
                vm.cardTypes = result;
            });
        }    }
})();
