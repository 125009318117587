(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ReceiptDialogControllerPage', ReceiptDialogControllerPage);

  ReceiptDialogControllerPage.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'Principal', 'Receipt', 'Customer', 'Contact', 'Shop', 'ShopLookup', 'Merchant', 'MerchantLookup', 'Card', '$state', 'DataUtils', 'ReceiptFile', 'AlertService', 'ProductLookup', 'Product', 'BrandLookup', 'Base64', 'AuthServerProvider', '$log', 'Warranty', '$rootScope', '$translate', 'PurchasedProductWarranty'];

  function ReceiptDialogControllerPage($timeout, $scope, $stateParams, $q, entity, Principal, Receipt, Customer, Contact, Shop, ShopLookup, Merchant, MerchantLookup, Card, $state, DataUtils, ReceiptFile, AlertService, ProductLookup, Product, BrandLookup, Base64, AuthServerProvider, $log, Warranty, $rootScope, $translate, PurchasedProductWarranty) {
    var vm = this;
    vm.useCard = null;
    vm.receipt = entity;
    if (!vm.receipt.receiptType) {
      vm.receipt.receiptType = 'RECEIPT';
    }
    vm.step = 1;
    vm.receipt.transactionCurrency = 'EUR';
    vm.receipt.buyingDate = new Date();
    vm.receipt.paymentDate = new Date();
    vm.receipt.profile = 'PERSONAL';
    vm.clear = clear;
    vm.datePickerOpenStatus = {};
    vm.datePickerOptions = { maxDate: new Date() };
    vm.openCalendar = openCalendar;
    vm.save = save;
    vm.onUpdateWarrantyNewProduct = onUpdateWarrantyNewProduct;
    vm.images = [];
    vm.receiptFiles = [];
    vm.openCreditCard = openCreditCard;
    vm.onUpdateConventionalWarranty = onUpdateConventionalWarranty;
    vm.onUpdateExtraWarranty = onUpdateExtraWarranty;
    vm.convertIcon = convertIcon;
    vm.addExtraWarrantyToProduct = addExtraWarrantyToProduct;
    vm.customers = Customer.query({}, function (result) {
      vm.receipt.customer = result[0];
      vm.receipt.customerId = vm.receipt.customer.id;
      loadActiveCards();
    });
    vm.receipt.profile = vm.receipt.business ? 'BUSINESS' : 'PERSONAL';
    // vm.cards = Card.queryActive(vm.receipt);
    vm.contacts = Contact.queryActive();
    vm.shops = Shop.query({ filter: 'receipt-is-null' });
    $q.all([vm.receipt.$promise, vm.shops.$promise]).then(function () {
      if (!vm.receipt.shopId) {
        return $q.reject();
      }
      return Shop.get({ id: vm.receipt.shopId }).$promise;
    }).then(function (shop) {
      vm.shops.push(shop);
    });

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });
    vm.isValidInput = isValidInput;
    vm.disableAddProduct = disableAddProduct;
    vm.validMerchant = null;
    vm.validAddress = null;
    // Le ricevute inviate da un negozio ufficiale (o in attesa di conferma dopo un claim) sono quasi totalmente readonly
    vm.isOfficial = vm.receipt.source === 'MERCHANT' || vm.receipt.source === 'CLAIM' || vm.receipt.source === 'CLAIM_RESOLVED';
    vm.isAdmin = null;

    var selectCustomer = function (customer) {
      if (customer) {
        var last_profile = vm.receipt.profile;
        vm.receipt.customer = customer;
        vm.receipt.customerId = vm.receipt.customer.id;
        vm.receipt.profile = vm.receipt.business ? 'BUSINESS' : 'PERSONAL';

        if (last_profile && vm.receipt.profile != last_profile) {
          $translate("garanteasyApp.receipt.changeProfile").then(function (t) {
            AlertService.info(t);
            /*vm.alert = t;*/
          });
        }
      } else {
        vm.receipt.customer = null;
        vm.receipt.customerId = null;
        vm.receipt.profile = null;
      }
    };

    function convertIcon(warrantyExtension) {
      if (warrantyExtension && warrantyExtension.warranty) {
        if (warrantyExtension.warranty.guarantorType === 'PRODUCER') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/azienda.png";
        }
        if (warrantyExtension.warranty.guarantorType === 'INSURANCE') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/ombrello_scudo_bianco.png";
        }
        if (warrantyExtension.warranty.guarantorType === 'ASSISTANCE_CENTER') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/assistenza_1scudo_bianco.png";
        }
      }
      return "../../../content/images/icone_new/icone_sfondo_bianco/punto_domanda.png";
    }

    vm.customerId = {
      wrap: function (customerId) {
        if (angular.isDefined(customerId)) {
          selectCustomer(_.find(vm.customers, function (cust) { return cust.id == customerId }));
        }
        return vm.receipt.customerId;
      }
    };

    vm.addProductTest = addProductTest;
    vm.images = [];
    vm.documents = [];
    vm.customer = {};
    vm.merchantPrivacyPolicies = [];
    //vm.isValidForm = false;
    vm.typeCodes = ["GTIN", "EAN", "UPC"];
    vm.isDisabled = isDisabled;
    vm.contPurchasedProducts = 0;
    vm.receipt.purchasedProducts = [];
    vm.receipt.numProducts = vm.receipt.purchasedProducts.length;
    if (!vm.receipt.shop) {
      vm.receipt.shop = {};
    }
    $rootScope.receipt = vm.receipt;
    if (!vm.receipt.shop.address) {
      vm.receipt.shop.address = {};
    }
    if (!vm.receipt.shop.address.country) {
      vm.receipt.shop.address.country = {};
    }
    vm.receipt.shop.address.country.code = 'IT';

    vm.ALL_SHOPS = -1;
    vm.PREFERRED_SHOPS = 0;
    vm.EDIT_SHOP = 1;
    vm.isNewShopCollapsed = vm.PREFERRED_SHOPS;

    /* Togliere */
    vm.ALL_PRODUCTS = -1;
    vm.PREFERRED_PRODUCTS = 0;
    vm.NEW_PRODUCT = 1;
    vm.isNewProductCollapsed = vm.PREFERRED_PRODUCTS;
    /* Togliere */

    vm.receipt.purchasedProduct = {};

    vm.crediCardCollapsed = true;

    vm.basePaymentType = ['CASH', 'CHEQUE', 'BANK', 'ATM_CARD', 'PAYPAL'];
    $scope.$watch('vm.receipt.paymentType', function (value) {
      if (!value || vm.basePaymentType.includes(value)) {
        vm.crediCardCollapsed = true;
      } else {
        vm.crediCardCollapsed = false;
      }
      $log.debug('vm.receipt.paymentType', value);
    });

    getAccount();
    // getAccountMerchants();


    vm.checkAccess = checkAccess;
    vm.loadActiveCards = loadActiveCards;
    checkAccess();

    function checkAccess() {
      Principal.identity().then(function (account) {
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    $scope.resetShop = function () {
      vm.receipt.shop = null;
    };

    $scope.resetMerchant = function () {
      if (vm.receipt.shop !== null) {
        vm.receipt.shop.merchant = null;
      }
    };

    function loadActiveCards() {
      if (vm.receipt.customer) {
        vm.receipt.customerId = vm.receipt.customer.id;
      }
      if (vm.receipt.customer && vm.receipt.buyingDate) {
        vm.cards = Card.queryActive(vm.receipt);
      }
    }

    // $scope.loadShops = function (searchQuery) {
    //   if (searchQuery && searchQuery.trim().length > 1) {
    //     ShopLookup.query({ query: searchQuery }, function (result) {
    //       $scope.shops = result;
    //     }, function (response) {
    //       if (response.status === 404) {
    //         $scope.shops = Shop.query({ isActive: true });
    //       }
    //     });
    //   } else {
    //     $scope.shops = Shop.query({ isActive: true });
    //   }
    // };

    $scope.loadMerchants = function (searchQuery) {
      if (searchQuery) {
        MerchantLookup.query({ query: searchQuery }, function (result) {
          $scope.merchants = result;
        }, function (response) {
          if (response.status === 404) {
            $scope.merchants = Merchant.query({ isActive: true });
          }
        });
      } else {
        $scope.merchants = Merchant.query({ isActive: true });
      }
    };



    // Constraints beyond the ones in the form
    function isValidInput() {
      var isValid = true;
      if (vm.receipt.receiptType && vm.receipt.buyingDate) {
        isValid = true;
      }
      if (vm.step > 3 && $scope.addProductForm.$invalid) {
        isValid = false;
      }
      if (vm.step == 6 && $scope.extraWarrantyForm.$invalid) {
        isValid = false;
      }
      if (vm.step == 7 && $scope.giftForm.$invalid) {
        isValid = false;
      }
      return isValid;

    }

    function openCreditCard(event) {
      event.preventDefault();
      vm.crediCardCollapsed = !vm.crediCardCollapsed;
    }

    vm.phoneNumberUpdated = function () {
      vm.receipt.shop.phoneNumber.activated = false;
      vm.phoneNumberIsDirty = true;
    };

    function save() {
      if (vm.step > 3) {
        addProductTest();
      }
      if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length) {
        vm.receipt.numProducts = Number(vm.receipt.purchasedProducts.length);
      }
      vm.isSaving = true;
      vm.receipt.cardId = vm.useCard ? vm.receipt.cardId : null;
      if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length) {
        vm.receipt.purchasedProducts = vm.receipt.purchasedProducts.map(function (purchasedProduct) {
          if (purchasedProduct.product.id) {
            purchasedProduct.allProductId = purchasedProduct.product.id;
            purchasedProduct.product = {};
          }
          return purchasedProduct;
        });
      }
      if (vm.receipt.shop && !vm.receipt.shop.shopSignboard || !vm.receipt.shop.vatCode) {
        delete vm.receipt.shop;
      }
      if (typeof vm.receipt.transactionAmount === 'string') {
        vm.receipt.transactionAmount = vm.receipt.transactionAmount.replace(',', '.');
      }
      if (vm.receipt.id !== null) {
        Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
      } else {
        vm.receipt.purchasedProduct = null;
        // if (!vm.receipt.receiptNumber) {
        //   vm.receipt.receiptNumber = Base64.encode(vm.receipt.buyingDate.toString());
        // }
        Receipt.save(vm.receipt, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      var receiptId = result.id;
      var promiseArray = [];
      if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length &&
        result.purchasedProducts && result.purchasedProducts.length) {
        for (var i = 0; i < result.purchasedProducts.length; i++) {
          if (vm.receipt.purchasedProducts[i] && vm.receipt.purchasedProducts[i].receiptFiles &&
            vm.receipt.purchasedProducts[i].receiptFiles.length && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].receiptFiles.map(function (file) {
              file.purchasedProductId = result.purchasedProducts[i].id;
              return file;
            });
            vm.receiptFiles = vm.receiptFiles.concat(vm.receipt.purchasedProducts[i].receiptFiles);
          }
          if (vm.receipt.purchasedProducts[i].conventionalWarranty && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].conventionalWarranty.purchasedProductId = result.purchasedProducts[i].id;
            promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProducts[i].conventionalWarranty));
          }
          if (vm.receipt.purchasedProducts[i].warrantyService && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].warrantyService.purchasedProductId = result.purchasedProducts[i].id;
            promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProducts[i].warrantyService));
          }
          if (vm.receipt.purchasedProducts[i].warrantyInsurance && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].warrantyInsurance.purchasedProductId = result.purchasedProducts[i].id;
            promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProducts[i].warrantyInsurance));
          }
        }
      }
      if (vm.receiptFiles && vm.receiptFiles.length) {
        vm.receiptFiles.forEach(function (receiptFile) {
          if (receiptFile.fileDataChanged) {
            receiptFile.fileData = receiptFile.fileDataChanged;
          }
          delete receiptFile.fileDataChanged;
          delete receiptFile.changed;
          receiptFile.receiptId = receiptId;
          promiseArray.push(ReceiptFile.save(receiptFile, function (result) {
            vm.images.push(result);
            if (result) {
              vm.image = result;
              vm.images.push(result);
            }
          }).$promise);
        });
      }
      $q.all(promiseArray).then(function () {
        $scope.$emit('garanteasyApp:receiptUpdate', result);
        vm.isSaving = false;
        $state.go('receipt', null, { reload: true });
      });
    }

    function onSaveError(error) {
      vm.error = error;
      vm.isSaving = false;
      AlertService.error(error.data.message);
    }

    vm.datePickerOpenStatus.buyingDate = false;

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    vm.canShow = function (contentType) {
      return contentType != null && contentType.toLowerCase().indexOf('image') === 0 && contentType.toLowerCase().indexOf('tiff') === -1;
    };

    vm.uploadFile = function ($file, isProduct) {
      vm.receiptFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.receiptFile.receiptId = null;
          vm.receiptFile.fileData = null;
          vm.receiptFile.contentType = null;
          vm.receiptFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.toBase64($file, function (base64Data) {
            $scope.$apply(function () {
              // vm.receiptFile.receiptId = vm.receipt.id;
              vm.receiptFile.fileData = base64Data;
              vm.receiptFile.fileContentType = $file.type;
              vm.receiptFile.fileName = $file.name;
              if (isProduct) {
                if (!vm.receipt.purchasedProduct.receiptFiles) {
                  vm.receipt.purchasedProduct.receiptFiles = [];
                }
                vm.receipt.purchasedProduct.receiptFiles.push(vm.receiptFile);
              } else {
                vm.receiptFiles.push(vm.receiptFile);
              }
              /*if(item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") == 0){*/
              //vm.imageNotPersistent = vm.receiptFile;
              //}
            });
          });
        }
      }
    };

    vm.getImageTest = function (data) {
      return 'data:image/jpeg;base64,' + data;
    };

    var imagesPath = {};
    vm.getImageUrl = function (receiptFile) {
      if (!receiptFile || !receiptFile.id) {
        imagesPath[receiptFile.id] = null;
      } else if (_.isUndefined(imagesPath[receiptFile.id])) {
        imagesPath[receiptFile.id] = "api/receipt-files/content/" + receiptFile.id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
      }
      return imagesPath[receiptFile.id];

    };
    if (vm.receipt.receiptFiles != null && vm.receipt.receiptFiles.length > 0) {
      vm.receipt.receiptFiles.forEach(function (item) {
        if (item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") === 0)
          vm.images.push(item);
        else
          vm.receiptFiles.push(item);
      });
    }


    if (vm.images.length > 0) {
      vm.image = vm.images[0];
    }

    vm.changeMainImage = function (image) {
      /*$('.zoomContainer').remove();*/
      vm.image = image;
    };

    vm.deleteFile = function (receiptFile, isProduct) {
      var index;
      if (isProduct) {
        index = vm.receipt.purchasedProduct.receiptFiles.indexOf(receiptFile);
        return vm.receipt.purchasedProduct.receiptFiles.splice(index, 1);
      }
      index = vm.receiptFiles.indexOf(receiptFile);
      if (!receiptFile.id) {
        vm.receiptFile = {};
        vm.receiptFiles.splice(index, 1);
      } else {
        ReceiptFile.delete({ id: receiptFile.id }, function () {
          vm.receiptFiles.splice(index, 1);
        });
      }
    };

    function onUpdateConventionalWarranty(purchasedProductWarranty) {
      vm.receipt.purchasedProduct.conventionalWarranty = Object.assign({}, purchasedProductWarranty);
    }

    function addProductTest() {
      vm.addProduct = false;
      if (
        angular.isDefined(vm.receipt.purchasedProduct) &&
        angular.isDefined(vm.receipt.purchasedProduct.product)
      ) {
        if (angular.isUndefined(vm.receipt.purchasedProduct.giftMakerDescription)) {
          vm.receipt.purchasedProduct.giftMakerDescription = vm.receipt.customer.legalName;
        }
        var index = vm.receipt.purchasedProducts.indexOf(vm.receipt.purchasedProduct);
        if (index === -1) {
          vm.receipt.purchasedProducts.push(vm.receipt.purchasedProduct);
        } else {
          vm.receipt.purchasedProducts[index] = vm.receipt.purchasedProduct;
        }
        vm.receipt.purchasedProduct = {};
      }
    }

    function isDisabled() {
      if (!vm.receipt.buyingDate ||
        !vm.receipt.receiptNumber ||
        !vm.receipt.shop ||
        !vm.merchant
      ) {
        return true;
      } else {
        if (vm.customerSelected) {
          return false;
        } else {
          if (!vm.customer.surname || !vm.customer.email) {
            return true;
          }
        }
      }
    }

    vm.updateCustomerData = function () {
      $log.debug("customer selezionato", vm.customerSelected);
      if (vm.customerSelected) {
        vm.customer.name = vm.customerSelected.legalName
          .split(" ")
          .slice(0, -1)
          .join(" ");
        vm.customer.surname = vm.customerSelected.legalName
          .split(" ")
          .slice(-1)
          .join(" ");
        vm.customer.email = vm.customerSelected.user.login;
      } else {
        vm.customer.name = "";
        vm.customer.surname = "";
        vm.customer.email = "";
      }
    };

    vm.removePurchasedProduct = function (index) {
      vm.receipt.purchasedProducts.splice(index, 1);
    };

    // function getAccountMerchants() {
    //     Receipt.getMerchants({},
    //         function(result) {
    //             vm.accountMerchants = result;
    //         },
    //         function(response) {
    //             if (response.status === 404) {
    //                 vm.accountMerchants = {};
    //             }
    //         }
    //     );
    // }

    vm.getShopByMerchants = function () {
      if (angular.isDefined(vm.merchant)) {
        Shop.getShopByMerchants({ merchant_id: vm.merchant.id },
          function (result) {
            vm.shops = result;
          },
          function (response) {
            if (response.status === 404) {
              vm.shops = {};
            }
          }
        );
      }
    };

    function getAccount() {
      Principal.identity().then(function (account) {
        vm.account = account;
        vm.isAuthenticated = Principal.isAuthenticated;
      });
    }

    vm.customers = Customer.query({}, function (result) {
      vm.receipt.customer = result[0];
    });

    function saveUpdatePurchasedProductWarranty(purchasedProductWarranty) {
      var promise = new Promise(function (resolve) {
        resolve();
      });
      if (!purchasedProductWarranty.warranty.id) {
        promise = Warranty.save(purchasedProductWarranty.warranty).$promise;
      } else if (purchasedProductWarranty.warranty.id && purchasedProductWarranty.warranty.code === 'CUSTOM') {
        promise = Warranty.update(purchasedProductWarranty.warranty).$promise;
      }
      if (!purchasedProductWarranty.id) {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || warranty.id;
          return PurchasedProductWarranty.save(purchasedProductWarranty).$promise
        });
      } else {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || warranty.id;
          return PurchasedProductWarranty.update(purchasedProductWarranty).$promise
        });
      }
      return promise.then(function (result) {
        purchasedProductWarranty = result;
        return purchasedProductWarranty;
      });
    }

    function onUpdateExtraWarranty(purchasedProductWarranty) {
      vm.receipt.purchasedProduct.warrantyExtension = Object.assign({}, purchasedProductWarranty);
    }

    function addExtraWarrantyToProduct() {
      if (!vm.receipt.purchasedProduct.purchasedProductWarranties)
        vm.receipt.purchasedProduct.purchasedProductWarranties = [];
      vm.receipt.purchasedProduct.purchasedProductWarranties.push(vm.receipt.purchasedProduct.warrantyExtension);
      vm.receipt.purchasedProduct.warrantyExtension = {};
    }

    function onUpdateWarrantyNewProduct(purchasedProductWarranty, valid) {
      if (!purchasedProductWarranty) {
        return;
      }
      switch (purchasedProductWarranty.warranty.type) {
        case 'CONVENTIONAL':
          vm.receipt.purchasedProduct.warrantyConventional = Object.assign({}, purchasedProductWarranty);
          break;
        case 'SERVICE_CONTRACT':
          vm.receipt.purchasedProduct.warrantyService = Object.assign({}, purchasedProductWarranty);
          break;
        case 'INSURANCE':
          vm.receipt.purchasedProduct.warrantyInsurance = Object.assign({}, purchasedProductWarranty);
          break;
      }
    }

    /* Togliere */
    vm.products = [];
    $scope.loadProducts = function (searchQuery) {
      if (vm.isNewProductCollapsed === vm.PREFERRED_PRODUCTS) {
        ProductLookup.query({}, function (results) {
          vm.products = results;
        });
      } else {
        ProductLookup.query({ query: searchQuery },
          function (result) {
            vm.products = result;
            angular.forEach(vm.products, function (product, key) {
              product.description = String(product.description).replace(/<[^>]+>/gm, '');
            });
          },
          function (response) {
            $log.error("Error in productlokup", response);
            AlertService.error("Error: " + response.status);
          }
        );
      }
    };
    /* Togliere */

    $scope.loadBrands = function (searchQuery, producer) {
      if (producer && producer.code) {
        searchQuery = '\'(owner.code:' + producer.code + ')AND(official:1)\'' + searchQuery;
      }
      BrandLookup.query({ query: searchQuery },
        function (result) {
          $scope.brands = result;
        }
      );
    };

    function disableAddProduct() {
      if (vm.receipt.purchasedProduct == null || vm.receipt.purchasedProduct.product == null || vm.receipt.purchasedProduct.product.name == null ||
        (vm.receipt.purchasedProduct.product.eanCodes == null && vm.receipt.purchasedProduct.product.eanCode == null) ||
        vm.receipt.purchasedProduct.product.brand == null ||
        (vm.receipt.gift === 'Y' && vm.receipt.purchasedProduct.giftReceiverEmail == null)
      ) {
        return true;
      } else return false;
    }

    vm.updatePurchasedProduct = function (pProduct) {
      $log.debug("Calling update", vm.purchasedProduct, pProduct);
      vm.purchasedProduct = pProduct;
    };

    vm.updateShop = function (newShop) {
      vm.receipt.shop = newShop;
    };

    vm.goToStep = function (step) {
      vm.addProduct = false;
      if (vm.step == 6 && step == 7) {
        vm.addExtraWarrantyToProduct();
      }
      if (!isNaN(step) && step > 0 && step <= 7) {
        return vm.step = step;
      }
      if (step === '+' && vm.step < 7) {
        vm.step++;
      } else if (vm.step > 1) {
        vm.step--;
      }
    };
  }
})();
