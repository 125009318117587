(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('UserData', UserData);

    UserData.$inject = ['$resource'];

    function UserData ($resource) {
        var resourceUrl =  'api/account/send_data';

        return $resource(resourceUrl, {}, {
            'send': { method: 'GET', isArray: false},
           
          
        });
    }
})();
