(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('MaintenanceDeleteController',MaintenanceDeleteController);

    MaintenanceDeleteController.$inject = ['$uibModalInstance', 'entity', 'Product', 'Maintenance'];

    function MaintenanceDeleteController($uibModalInstance, entity, Product, Maintenance) {
        var vm = this;

        vm.maintenance = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        // Carica la maintenance
        vm.loadMaintenance = loadMaintenance;
        vm.warranty = {};
       	loadMaintenance(vm.maintenance.id);
        
        function loadMaintenance (id) {
            Maintenance.get({id: id}, function(result) {
                vm.maintenance = result;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	Maintenance.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
