(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyClaimFileDeleteController',WarrantyClaimFileDeleteController);

    WarrantyClaimFileDeleteController.$inject = ['$uibModalInstance', 'entity', 'WarrantyClaimFile'];

    function WarrantyClaimFileDeleteController($uibModalInstance, entity, WarrantyClaimFile) {
        var vm = this;

        vm.warrantyClaimFile = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            WarrantyClaimFile.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
