'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .factory('notificationInterceptor', notificationInterceptor);

  notificationInterceptor.$inject = ['$q', 'AlertService'];

  function notificationInterceptor($q, AlertService) {
    var service = {
      response: response
    };

    return service;

    function response(response) {
      var alertKey = response.headers('X-garanteasyApp-alert');
      if (angular.isString(alertKey)) {
        AlertService.success(alertKey, { param: response.headers('X-garanteasyApp-params') });
      }
      return response;
    }
  }
})();
