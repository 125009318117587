(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('SidebarViewsController', SidebarViewsController);

    SidebarViewsController.$inject = ['$state', '$scope', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

    function SidebarViewsController ($state, $scope, Auth, Principal, ProfileService, LoginService) {
        var vm = $scope;
        vm.showSuggestionsVar = 'falsee';

        vm.triggerObj = {};
        vm.triggerObj.trigger = false;

        $scope.name = 'SidebarController';
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.currentState = $state.current.parent;

      ProfileService.getProfileInfo().then(function (response) {
        if (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        }
      });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.showUlSuggestions = showUlSuggestions;
        vm.$state = $state;

        function showUlSuggestions() {
            if(vm.triggerObj.trigger == true){
                vm.triggerObj.trigger = false;
            }
            else {
                vm.triggerObj.trigger = true;
            }
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
