(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CardTypeDialogController', CardTypeDialogController);

    CardTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CardType', 'Merchant', 'MerchantLookup'];

    function CardTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CardType, Merchant, MerchantLookup) {
        var vm = this;

        vm.cardType = entity;
        vm.clear = clear;
        vm.save = save;

        $scope.loadMerchants = function(searchQuery) {
            if (searchQuery && searchQuery.trim().length > 1){
                MerchantLookup.query({query: searchQuery}, function(result) {
                    $scope.merchants = result;
                }, function(response) {
                    if(response.status === 404) {
                        $scope.merchants = Merchant.query({isActive: true});
                    }
                });
            } else {
                $scope.merchants = Merchant.query({isActive: true});
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.cardType.id !== null) {
                CardType.update(vm.cardType, onSaveSuccess, onSaveError);
            } else {
                CardType.save(vm.cardType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyApp:cardTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
