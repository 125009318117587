(function () {
  'use strict';

  var purchasedProductFastComponent = {
    templateUrl: 'app/entities/purchased-product/components/purchased-product-fast.component.html',
    controller: PurchasedProductFastController,
    controllerAs: 'vm',
    bindings: {
      receipt: '<',
      onSave: '<',
      onCancel: '<',
      onError: '<',
      onChange: '<',
      hideProductList: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('purchasedProductFastComponent', purchasedProductFastComponent);

  PurchasedProductFastController.$inject = ['$timeout', '$scope', 'Receipt', 'ProductLookup', 'AlertService', '$log', 'DataUtils', 'BrandLookup'];

  function PurchasedProductFastController($timeout, $scope, Receipt, ProductLookup, AlertService, $log, DataUtils, BrandLookup) {
    var vm = this;

    vm.onChangeProduct = onChangeProduct;
    vm.addOnBlurProduct = addOnBlurProduct;
    vm.onKeyUpPorduct = onKeyUpPorduct;
    vm.products = [];

    function transformProduct(new_value) {
      var new_product = {};
      new_product.name = new_value;
      new_product.brandAndName = new_value;

      return new_product;
    }

    $scope.loadProducts = function (searchQuery) {
      ProductLookup.query({
        query: searchQuery
      },
        function (result) {
          vm.products = result;
          angular.forEach(vm.products, function (product, key) {
            product.description = String(product.description).replace(/<[^>]+>/gm, '');
          });
          var product = vm.products.find(function (item) {
            return item.brandAndName === searchQuery.trim();
          });
          if (searchQuery && !product) {
            vm.products.unshift(transformProduct(searchQuery));
          }
        },
        function (response) {
          $log.error("Error in productlokup", response);
          AlertService.error("Error: " + response.status);
        }
      );
    };

    function onKeyUpPorduct(search) {
      if (search && search.length) {
        vm.productValid = true;
      } else if (!search && !vm.receipt.purchasedProduct.product) {
        vm.productValid = false;
      }
    }

    function addOnBlurProduct($select) {
      $select.searchInput.on('blur', function () {
        $timeout(function () {
          if ($select.search && !$select.selected && (!vm.receipt.purchasedProduct.product || !vm.receipt.purchasedProduct.product.name)
            || (vm.receipt.purchasedProduct.product && !vm.receipt.purchasedProduct.product.idxId && $select.search.trim() !== vm.receipt.purchasedProduct.product.name)) {
            var product = vm.products.find(function (item) {
              return item.brandAndName === $select.search.trim();
            });
            vm.receipt.purchasedProduct.product = product;
          }
          if (vm.tempSavingProduct) {
            vm.receipt.purchasedProduct.product = vm.tempSavingProduct;
          }
        }, 250);
      });

    }

    function onChangeProduct($select) {
      if ($select.search && !vm.receipt.purchasedProduct.product) {
        $select.search = '';
        vm.productValid = false;
      }
      if (!vm.alreadySelected) {
        vm.alreadySelected = true;
      } else {
        vm.receipt.purchasedProducts.forEach(function (val) {
          if (val.id === vm.newSavedProductId) {
            vm.lastProductToDelete = val;
            return;
          }
        })

      }
      if (vm.receipt.purchasedProduct.product.idxId) {
        vm.isNewProduct = true;
      }

    }
  }
})();
