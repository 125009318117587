(function () {
  'use strict';

  var cardComponent = {
    templateUrl: 'app/entities/card/card.component.html',
    controller: CardEditController,
    controllerAs: 'vm',
    bindings: {
      card: '<',
      onCmsCardChange: '<',
      onSave: '<',
      onCancel: '<',
      onError: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('cardComponent', cardComponent);

  CardEditController.$inject = ['$translate', '$http', '$timeout', '$scope', 'DateUtils', '$q', 'Card', 'CardType', 'Contact', 'ContactLookup', 'PhoneNumberLookup', 'PhoneNumber', 'Principal', '$state', '$rootScope', 'CardCustomer', 'Customer', 'PrivacyPolicy', 'CardTypeCmsService'];

  function CardEditController($translate, $http, $timeout, $scope, DateUtils, $q, Card, CardType, Contact, ContactLookup, PhoneNumberLookup, PhoneNumber, Principal, $state, $rootScope, CardCustomer, Customer, PrivacyPolicy, CardTypeCmsService) {
    var vm = this;

    vm.tmp = {};
    vm.cardTypes = [];
    vm.customers = [];
    vm.datePickerOpenStatus = {};
    vm.datePickerOpenStatus.activationDate = false;
    vm.datePickerOpenStatus.expirationDate = false;
    vm.isOwner = false;
    vm.isAdmin = false;
    vm.lang = $translate.use();

    // vm.card = entity;
    vm.clear = clear;
    vm.openCalendar = openCalendar;
    vm.save = save;
    vm.getMerchantPrivacyPolicies = getMerchantPrivacyPolicies;
    vm.disableEdit = disableEdit;
    vm.cardTypes = CardType.query(onCardTypeLoadSuccess, onCardTypeLoadError);
    vm.contacts = Contact.query();
    vm.phoneNumbers = PhoneNumber.query();
    vm.cmsCardTypes = [];

    vm.ALL_SHOPS = -1;
    vm.EDIT_SHOP = 1;
    vm.isNewShopCollapsed = vm.ALL_SHOPS;


    checkAccess();
    loadCustomers();

    function onCardTypeLoadSuccess() {
      if (vm.cardTypes) {
        CardTypeCmsService.getCardByReference(vm.cardTypes).then(onSuccessLoadCmsTypes, onErrorLoadCmsTypes);
      }

    }

    function onCardTypeLoadError() {
      console.log("Error loading cards")
    }



    function onSuccessLoadCmsTypes(result) {
      if (result && result.length) {
        vm.cmsCardTypes = result;
      }
    }

    function onErrorLoadCmsTypes() {
      console.log("Can't load data types from cms");
    }

    vm.$onInit = function () {
      if (!vm.card || !vm.card.id) {
        vm.card = {
          number: null,
          activationDate: null,
          expirationDate: null,
          id: null
        };
      }
      vm.card.merchantPrivacyPolicies = [];
      angular.copy(vm.card, vm.tmp);
      if (vm.card.activationDate) {
        vm.card.activationDate = DateUtils.convertLocalDateFromServer(vm.card.activationDate);
      }
      if (vm.card.expirationDate) {
        vm.card.expirationDate = DateUtils.convertLocalDateFromServer(vm.card.expirationDate);
      }

      vm.contacts.$promise.then(function () {
        if (!vm.card.contactId) {
          return $q.reject();
        }
        return Contact.get({
          id: vm.card.contactId
        }).$promise;
      }).then(function (contact) {
        vm.contacts.push(contact);
      });

      vm.phoneNumbers.$promise.then(function () {
        if (!vm.card.phoneNumberId) {
          return $q.reject();
        }
        return PhoneNumber.get({
          id: vm.card.phoneNumberId
        }).$promise;
      }).then(function (phoneNumber) {
        vm.phoneNumbers.push(phoneNumber);
      });
    };

    $scope.resetContact = function () {
      vm.card.contact = null;
      vm.newContact = true;
    }

    $scope.resetPhoneNumber = function () {
      vm.card.phoneNumber = null;
      vm.newPhoneNumber = true;
    }

    $scope.loadContacts = function (searchQuery) {
      if (searchQuery && searchQuery.trim().length > 1) {
        ContactLookup.query({
          query: searchQuery
        }, function (result) {
          $scope.contacts = result;
        }, function (response) {
          if (response.status === 404) {
            $scope.contacts = Contact.query({
              isActive: true
            }, onSuccess, onError);
          }
        });
      } else {
        $scope.contacts = Contact.query({
          isActive: true
        }, onSuccess, onError);
      }
    };

    function loadCustomers() {
      Customer.query(function (result) {
        if (result && result[0]) {
          //Force first customer of the list
          vm.card.customer = result[0];
        }
      });
    }

    function getMerchantPrivacyPolicies(customer) {
      if (customer) {
        vm.mppCustomer = customer.merchantPrivacyPolicies;
      }
    }


    function checkAccess() {
      Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
        if (result) {
          vm.isAdmin = true;
        }
      });
      var account = Principal.identity();
      if (vm.card && vm.card.contact && vm.card.contact.user && account.$$state && account.$$state.value.id) {
        if (vm.card.contact.user.id == account.$$state.value.id) {
          vm.isOwner = true;
        }
      }
    }

    function disableEdit() {
      if (vm.card.flowMerchant && vm.isAdmin) {
        return false;
      }
      if (!vm.card.flowMerchant && vm.isOwner) {
        return false;
      }
      if (vm.card.id) {
        return true;
      }
    }

    function onSuccess(data, headers) {
      if ($scope.contacts) {
        $scope.contacts.forEach(function (item) {
          if (item.primaryContact && !vm.card.contact) {
            vm.card.contact = item;
          }
        });
      }
    }

    function onError(data, headers) {

    }

    $scope.loadPhoneNumbers = function (searchQuery) {
      if (searchQuery && searchQuery.trim().length > 1) {
        PhoneNumberLookup.query({
          query: searchQuery
        }, function (result) {
          $scope.phoneNumbers = result;
        }, function (response) {
          if (response.status === 404) {
            $scope.phoneNumbers = PhoneNumber.query({
              isActive: true
            });
          }
        });
      } else {
        $scope.phoneNumbers = PhoneNumber.query({
          isActive: true
        });
      }
    };

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

    function clear() {
      if (typeof vm.onCancel === 'function') {
        angular.copy(vm.tmp, vm.card);
        console.log(vm.card);
        vm.onCancel();
      }
    }

    function save() {
      vm.isSaving = true;
      if (vm.card.id !== null) {
        Card.update(vm.card, onSaveSuccess, onSaveError);
      } else {
        Card.save(vm.card, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      $scope.$emit('garanteasyApp:cardUpdate', result);
      if (typeof vm.onSave === 'function') {
        vm.onSave(result);
      }
      vm.isSaving = false;
    }


    function onSaveError(error, headers) {
      vm.isSaving = false;
      if (error && error.data && error.data.errCode && 'ERR_EXIST_CARD' == error.data.errCode) {
        vm.resp = error.data.errCode;
        $state.go('settings.card-requestUse', {
          'dataCard': error.data,
          'card': vm.card
        });
        // $uibModalInstance.close();
      } else {
        if (typeof vm.onError === 'function') {
          vm.onError(error);
        }
        vm.isSaving = false;
      }

    }

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    function onLoadPolicySuccess(data) {
      vm.card.merchantPrivacyPolicies = [];

      if (data && data.length > 0) {
        data.forEach(function (pp) {
          if (_.find(vm.mppCustomer, function (it) {
            it.policyId == pp.id
          }) == null) {
            var mpp = {
              policyId: pp.id,
              code: pp.code,
              merchant: pp.merchant,
              customerId: vm.card.customer.id,
              mandatory: pp.mandatory,
              approved: false,
              merchantCode: pp.merchant.code
            };
            if (pp.description && pp.description.length > 0) {
              pp.description.forEach(function (ppDescr) {
                if (ppDescr.language === vm.lang) {
                  mpp.privacyPolicy = ppDescr.description;
                  mpp.policyItem = ppDescr;
                  if (ppDescr.url) {
                    mpp.policyItem.urls = ppDescr.url.split(',');
                  }
                }
              });
            }
            vm.card.merchantPrivacyPolicies.push(mpp);
          }
        });
      }
    }

    vm.onChangeCard = function () {
      var cmsCard;
      vm.card.merchantPrivacyPolicies = [];
      vm.getMerchantPrivacyPolicies(vm.card.customer);
      vm.loadMerchantPrivacyPolicies();
      if (vm.card && vm.card.cmsReference) {
        cmsCard = vm.cmsCardTypes.find(function (card) {
          return card.uuid === vm.card.cmsReference;
        });

      }
      if (typeof vm.onCmsCardChange === 'function') {
        vm.onCmsCardChange(cmsCard);
      }
    }

    vm.loadMerchantPrivacyPolicies = function () {
      vm.card.merchantPrivacyPolicies = [];
      if (vm.card.customer && vm.card.cardType && vm.card.cardType.merchant && vm.card.cardType.merchant.code) {
        PrivacyPolicy.regMerchantCode({
          merchant_code: vm.card.cardType.merchant.code
        }, onLoadPolicySuccess, onError);
      }
    };

  }
})();
