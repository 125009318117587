(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('PurchasedProductDialogController', PurchasedProductDialogController);

    PurchasedProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Principal', 'PurchasedProduct', 'Receipt', 'Brand', 'BrandLookup', 'Product', 'ProductLookup', 'PurchasedProductWarranty', 'WarrantyClaim', 'IceProductLookup', 'IcecatSimpleProduct', 'Shop', 'ShopLookup', 'Merchant', 'MerchantLookup', 'Customer', '$rootScope', 'AlertService', '$translate'];

    function PurchasedProductDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Principal, PurchasedProduct, Receipt, Brand, BrandLookup, Product, ProductLookup, PurchasedProductWarranty, WarrantyClaim, IceProductLookup, IcecatSimpleProduct, Shop, ShopLookup, Merchant, MerchantLookup, Customer, $rootScope, AlertService, $translate) {
        var vm = this;

        vm.purchasedProduct = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.customers = Customer.query();

        vm.brands = Brand.query();
        vm.products = Product.query({ filter: 'purchasedproduct-is-null' });
        vm.shops = Shop.query({ filter: 'receipt-is-null' });

        $scope.resetShop = function() {
            if (vm.receipt) {
                vm.receipt.shop = null;
            }
        }

        $scope.loadShops = function(searchQuery) {
            if (searchQuery && searchQuery.trim().length > 1) {
                ShopLookup.query({ query: searchQuery }, function(result) {
                    $scope.shops = result;
                }, function(response) {
                    if (response.status === 404) {
                        $scope.shops = Shop.query({ isActive: true });
                    }
                });
            } else {
                $scope.shops = Shop.query({ isActive: true });
            }
        };

        vm.datePickerOpenStatus.buyingDate = false;
        vm.datePickerOpenStatus.creationDate = false;

        $scope.resetMerchant = function() {
            if (vm.receipt && vm.receipt.shop) {
                vm.receipt.shop.merchant = null;
            }
        }

        $scope.loadMerchants = function(searchQuery) {
            if (searchQuery) {
                MerchantLookup.query({ query: searchQuery }, function(result) {
                    $scope.merchants = result;
                }, function(response) {
                    if (response.status === 404) {
                        $scope.merchants = Merchant.query({ isActive: true });
                    }
                });
            } else {
                $scope.merchants = Merchant.query({ isActive: true });
            }
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $q.all([vm.purchasedProduct.$promise, vm.products.$promise]).then(function() {
            if (!vm.purchasedProduct.productId) {
                return $q.reject();
            }
            return Product.get({ id: vm.purchasedProduct.productId }).$promise;
        }).then(function(product) {
            vm.products.push(product);
        });

        vm.purchasedproductwarranties = PurchasedProductWarranty.query();
        vm.warrantyclaims = WarrantyClaim.query();
        vm.isValidInput = isValidInput;
        vm.hasProduct = null;
        vm.validProduct = null;
        vm.hasBrand = null;
        vm.validBrand = null;

        vm.isOwner = null;
        vm.isAdmin = null;
        vm.isGiftReceiver = null;


        vm.receiptIsOfficial = vm.purchasedProduct.receipt && (vm.purchasedProduct.receipt.source == 'MERCHANT' || vm.purchasedProduct.receipt.source == 'CLAIM' || vm.purchasedProduct.receipt.source == 'CLAIM_RESOLVED');

        vm.checkAccess = checkAccess;
        checkAccess();

        function checkAccess() {
            Principal.identity().then(function(account) {
                vm.isOwner = !vm.purchasedProduct.id || vm.purchasedProduct.receipt.customer.user.id == account.id;
                vm.isGiftReceiver = vm.purchasedProduct.giftReceiverUserId == account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function(result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }

        $timeout(function() {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.resetProduct = function() {
            vm.purchasedProduct.product = null;
        }

        $scope.resetBrand = function() {
            if (vm.purchasedProduct.product !== null) {
                vm.purchasedProduct.product.brand = null;
            }
        }

        $scope.loadProducts = function(searchQuery) {
            if (searchQuery && searchQuery.trim().length > 0) {
                ProductLookup.query({ query: searchQuery }, function(result) {
                    $scope.products = result;
                }, function(response) {
                    if (response.status === 404) {
                        $scope.products = Product.query({ isActive: true }, onSuccess, onError);
                    }
                });
            } else {
                ProductLookup.query({ query: '' }, onSuccess, onError);
            }
        };

        function onSuccess(result) {
            $scope.products = result;
            vm.isSaving = false;
        }

        function onError(result) {
            // $uibModalInstance.close(result);
        }


        $scope.loadBrands = function(searchQuery) {
            if (searchQuery) {
                BrandLookup.query({ query: searchQuery }, function(result) {
                    $scope.brands = result;
                }, function(response) {
                    if (response.status === 404) {
                        $scope.brands = Brand.query({ isActive: true });
                    }
                });
            } else {
                $scope.brands = Brand.query({ isActive: true });
            }
        };

        function save() {
            vm.isSaving = true;
            var pp = angular.copy(vm.purchasedProduct);
            if (pp.product.idxId) {
                var allProductId = pp.product.idxId;
                pp.product = {};
                pp.allProductId = allProductId;
            }
            if (angular.isUndefined(vm.purchasedProduct.giftMakerDescription)) {
                vm.purchasedProduct.giftMakerDescription = vm.purchasedProduct.receipt.customer.legalName;
            }
            if (vm.purchasedProduct.id !== null) {
                PurchasedProduct.update(pp, onSaveSuccess, onSaveError);
            } else {
                PurchasedProduct.save(pp, onSaveSuccess, onSaveError);
            }
        }

        // Constraints beyond the ones in the form
        function isValidInput() {
            vm.hasProduct = vm.purchasedProduct.product != null;
            // vm.validProduct = vm.hasProduct && vm.purchasedProduct.product.name != null && vm.purchasedProduct.product.name.trim().length > 0;
            vm.validProduct = vm.hasProduct;
            // vm.hasBrand = vm.hasProduct && vm.purchasedProduct.product.brand != null;
            vm.hasBrand = vm.hasProduct && vm.purchasedProduct.product.brand != null;


            /*vm.validBrand = vm.hasBrand && vm.purchasedProduct.product.brand.name != null && vm.purchasedProduct.product.brand.name.trim().length > 0;*/
            /*vm.validBrand = vm.hasBrand && vm.purchasedProduct.product.brand != null;*/
            if (!(vm.validProduct && vm.hasBrand)) {
                return false
            }
            // return vm.validProduct && vm.validBrand;
            return true;
        }

        function onSaveSuccess(result) {
            // $scope.$emit('garanteasyApp:purchasedProductUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            $rootScope.viewPanelSuccess = true;
            $rootScope.receiptId = result.id;
            /*$translate("garanteasyApp.purchasedProduct.messageSaved" ,{receiptId: result.id}).then( function(t) {
                AlertService.info(t);
            });*/
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.deliveryDate = false;
        vm.datePickerOpenStatus.giftNotificationDate = false;


        vm.saveSidebarProduct = function() {
            vm.isSaving = true;
            vm.receipt.creationDate = new Date();
            vm.receipt.customerId = vm.receipt.customer.id;
            vm.receipt.purchasedProducts = [vm.purchasedProduct];
            Receipt.save(vm.receipt, onSaveSuccess, onSaveError);
        };

    }
})();