(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('MaintenanceDialogController', MaintenanceDialogController);
   
    MaintenanceDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Maintenance', 'Product'];

    function MaintenanceDialogController ($scope, $stateParams, $uibModalInstance, entity, Maintenance, Product) {
        var vm = this;

        vm.maintenance = entity;
        vm.maintenanceType = {0 : 'Giorni', 1 : 'Settimane', 2: 'Mesi', 3: 'Anni'};
        if (vm.maintenance.productId == null) {
            vm.maintenance.productId = $stateParams.ppId;
        }
        if(vm.maintenance.product == null){
            vm.maintenance.product = {id:vm.maintenance.productId};
        }
        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.maintenance.id !== null) {
                Maintenance.update(vm.maintenance, onSaveSuccess, onSaveError);
            } else {
                Maintenance.save(vm.maintenance, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyApp:maintenanceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
