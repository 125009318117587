(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyDetailController', WarrantyDetailController);

    WarrantyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'Principal', 'entity', 'Warranty', 'PurchasedProductWarranty'];

    function WarrantyDetailController($scope, $rootScope, $stateParams, previousState, Principal, entity, Warranty, PurchasedProductWarranty) {
        var vm = this;

        vm.warranty = entity;

        if (typeof previousState.params.id != 'undefined') {
            vm.previousState = previousState.name+'({id: \''+previousState.params.id+'\'})';
        } else {
        	vm.previousState = previousState.name;
        }

        vm.isOwner=null;
        vm.isAdmin=null;
        
        checkAccess();

        function checkAccess() {
            Principal.identity().then(function(account) {
            	vm.isOwner=vm.warranty.createdBy.id == account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                    	vm.isAdmin=true;
                    } else {
                    	vm.isAdmin=false;
                    }
                });
            });
        }        

        var unsubscribe = $rootScope.$on('garanteasyApp:warrantyUpdate', function(event, result) {
            vm.warranty = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
