(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('NotificationDetailController', NotificationDetailController);

    NotificationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Notification', '$sce'];

    function NotificationDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Notification, $sce) {
        var vm = this;
        vm.notification = entity;
        vm.isOwner=null;
        vm.isAdmin=null;
        vm.skipValidation = function(value) {
            return $sce.trustAsHtml(value);
        }

        vm.checkAccess = checkAccess;
        checkAccess();

        function checkAccess() {
        	Principal.identity().then(function(account) {
				Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
					if (result) {
						vm.isAdmin=true;
					} else {
						vm.isAdmin=false;
					}
	            });
            });
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:notificationUpdate', function(event, result) {
            vm.notification = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
