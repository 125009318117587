'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('register', {
      parent: 'account',
      url: '/register?merchantCode&{SocialLoginError:boolean}',
      data: {
        authorities: [],
        pageTitle: 'register.title'
      },
      views: {
        'sidebar@': {
          templateUrl: 'app/layouts/sidebar/sidebar-none.html',
          controller: 'SidebarController',
          controllerAs: 'vm'
        },
        'content@': {
          templateUrl: 'app/account/register/register.html',
          controller: 'RegisterController',
          controllerAs: 'vm'
        }
      },
      params: {
        merchantCode: {
          value: 'GARANTEASY',
          squash: false
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('register');
          $translatePartialLoader.addPart('merchant');
          return $translate.refresh();
        }]
      }
    });
  }
})();
