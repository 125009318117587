(function () {
  'use strict';
  angular
    .module('garanteasyApp')
    .factory('PurchasedProduct', PurchasedProduct);

  PurchasedProduct.$inject = ['$resource', 'DateUtils'];

  function PurchasedProduct($resource, DateUtils) {
    var resourceUrl = 'api/purchased-products/:id';

    var transformResponse = function (data) {

      if (data) {
        data = angular.fromJson(data);
        data.deliveryDate = DateUtils.convertLocalDateFromServer(data.deliveryDate);
        data.giftNotificationDate = DateUtils.convertLocalDateFromServer(data.giftNotificationDate);
        data.warrantiesLaw = {};
        data.warrantiesLawStatus = 'white';
        data.warrantiesConventialStatus = 'white';
        data.assuranceAgreementStatus = 'white';
        data.assuranceContractStatus = 'white';
        data.conventionalWarranties = [];
        data.otherWarrenties = [];
        if (data.purchasedProductWarranties) {
          data.purchasedProductWarranties.forEach(function (item) {

            item.expirationDate = DateUtils.convertLocalDateFromServer(item.expirationDate);

            var dateFrom = DateUtils.convertLocalDateFromServer(data.receipt.buyingDate);

            if (data.deliveryDate) {
              dateFrom = data.deliveryDate;
            }

            item.startDate = dateFrom;

            var dateTo = item.expirationDate;

            if (_.isNull(dateFrom)) {
              dateFrom = new Date();
            }
            dateFrom = new Date(dateFrom);

            const one_day = 1000 * 60 * 60 * 24;
            dateTo = new Date(dateTo);

            if (item.status === 'valid') {
              item.color = 'green';
            } else if (item.status === 'aboutToExpire') {
              item.color = 'orange';
            } else if (item.status === 'expired') {
              item.color = 'red';
            } else {
              item.color = 'white';
            }

            item.dayToExpiration = Math.round((dateTo.getTime() - new Date()) / one_day);
            // FIXME k3 da rivedere con flag sulla warranty
            if (item.warranty.contractType === 'LEGAL') {
              data.warrantiesLaw = item;
              data.warrantiesLawStatus = item.color;
            } else if (item.warranty.contractType === 'CONVENTIONAL') {
              data.conventionalWarranties.push(item)
              data.warrantiesConventialStatus = item.color;
            } else if (item.warranty.contractType === 'SERVICE_CONTRACT') {
              data.assuranceContractStatus = item.color;
            } else if (item.warranty.contractType === 'INSURANCE_POLICY') {
              data.assuranceContractStatus = item.color;
            } else {
              data.otherWarrenties.push(item);
            }
          });
        }
      }
      return data;

    };
    var transformResponseArray = function (data) {
      return _.map(angular.fromJson(data), transformResponse);
    };

    return $resource(resourceUrl, {}, {
      'query': { method: 'GET', cache: true, isArray: true, transformResponse: transformResponseArray },
      'get': {
        method: 'GET',
        cache: true,
        transformResponse: transformResponse
      },
      'update': {
        method: 'PUT',
        transformRequest: function (data) {
          var dataToSend = angular.copy(data);
          dataToSend.deliveryDate = DateUtils.convertLocalDateToServer(dataToSend.deliveryDate);
          dataToSend.giftNotificationDate = DateUtils.convertLocalDateToServer(dataToSend.giftNotificationDate);
          return angular.toJson(dataToSend);
        }
      },
      'updateList': {
        method: 'PUT',
        url: 'api/purchased-products/purchased-products-list',
        transformRequest: function (data) {
          angular.forEach(data, function (item) {
            item.deliveryDate = DateUtils.convertLocalDateToServer(item.deliveryDate);
            item.giftNotificationDate = DateUtils.convertLocalDateToServer(item.giftNotificationDate);
          });
          var dataToSend = angular.copy(data);
          return angular.toJson(dataToSend);
        }
      },
      'save': {
        method: 'POST',
        transformRequest: function (data) {
          var dataToSend = angular.copy(data);
          dataToSend.deliveryDate = DateUtils.convertLocalDateToServer(dataToSend.deliveryDate);
          dataToSend.giftNotificationDate = DateUtils.convertLocalDateToServer(dataToSend.giftNotificationDate);
          return angular.toJson(dataToSend);
        }
      }
    });
  }
})();
