(function () {
  'use strict';

  var phoneNumberComponent = {
    templateUrl: 'app/entities/phone-number/phone-number.component.html',
    controller: PhoneNumberEditController,
    controllerAs: 'vm',
    bindings: {
      phoneNumber: '<',
      onSave: '<',
      onCancel: '<',
      onError: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('phoneNumberComponent', phoneNumberComponent);

  PhoneNumberEditController.$inject = ['$timeout', '$scope', 'PhoneNumber'];

  function PhoneNumberEditController($timeout, $scope, PhoneNumber) {
    var vm = this;
    vm.tmp = {};
    //vm.contact = entity;
    vm.clear = clear;
    vm.save = save;


    vm.$onInit = function () {
      if (!vm.phoneNumber || !vm.phoneNumber.id) {
        vm.phoneNumber = {
          phoneNumber: null,
          activated: false,
          activationKey: null,
          id: null
        };
      }
      angular.copy(vm.phoneNumber, vm.tmp);
    };

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

    function clear() {
      if (typeof vm.onCancel === 'function') {
        angular.copy(vm.tmp, vm.phoneNumber);
        vm.onCancel();
      }
    }

    function save() {
      vm.isSaving = true;
      if (vm.phoneNumber.id !== null) {
          PhoneNumber.update(vm.phoneNumber, onSaveSuccess, onSaveError);
      } else {
          PhoneNumber.save(vm.phoneNumber, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      $scope.$emit('garanteasyApp:phoneNumberUpdate', result);
      if (typeof vm.onSave === 'function') {
        vm.onSave(result);
      }
      vm.isSaving = false;
    }

    function onSaveError(error) {
      if (typeof vm.onError === 'function') {
        vm.onError(error);
      }
      vm.isSaving = false;
    }

  }
})();
