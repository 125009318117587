(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ReceiptFileDetailController', ReceiptFileDetailController);

    ReceiptFileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ReceiptFile', 'Receipt'];

    function ReceiptFileDetailController($scope, $rootScope, $stateParams, previousState, entity, ReceiptFile, Receipt) {
        var vm = this;

        vm.receiptFile = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('garanteasyApp:receiptFileUpdate', function(event, result) {
            vm.receiptFile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
