(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CovidLinkController', CovidLinkController);

        CovidLinkController.$inject = ['$scope', '$state'];

    function CovidLinkController ($scope, $state) {
        var vm = this;
        
          }
})();
