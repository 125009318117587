(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('PurchasedProductWarranty', PurchasedProductWarranty);

    PurchasedProductWarranty.$inject = ['$resource', 'DateUtils'];

    function PurchasedProductWarranty ($resource, DateUtils) {
        var resourceUrl =  'api/purchased-product-warranties/:id';
        var transformResponseArray = function (data) {
          // return _.map(angular.fromJson(data), transformResponse);
        };

        function transformResponse(data) {
          if (data) {
            data = angular.fromJson(data);
          }
          if (!data.expirationDate) {
            return data;
          }
          var dateTo = data.expirationDate;
          const one_day = 1000 * 60 * 60 * 24;
          dateTo = new Date(dateTo);
          data.dayToExpiration = Math.round((dateTo.getTime() - new Date()) / one_day);
          return data;
        }

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', cache: true, isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expirationDate = DateUtils.convertLocalDateFromServer(data.expirationDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                	var dataToSend = angular.copy(data);
                  dataToSend.expirationDate = DateUtils.convertLocalDateToServer(dataToSend.expirationDate);
                    return angular.toJson(dataToSend);
                },
                transformResponse: transformResponse
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var dataToSend = angular.copy(data);
                  dataToSend.expirationDate = DateUtils.convertLocalDateToServer(dataToSend.expirationDate);
                    return angular.toJson(dataToSend);
                },
                transformResponse: transformResponse
            },
            'delete': {
            	method:'DELETE'
            }
        });
    }
})();
