(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('PurchasedProductDetailController', PurchasedProductDetailController);




  PurchasedProductDetailController.$inject = ['$state', '$q', '$scope', 'Brand', 'BrandLookup', 'ShopLookup', 'Shop', 'AlertService', '$rootScope', '$http', '$stateParams', 'previousState', 'entity', 'Principal', 'PurchasedProduct', 'Receipt', 'Gift', 'Product', 'PurchasedProductWarranty', 'WarrantyClaim', 'ProductResources', 'DEFAULT_BARCODE_ENCODING', 'DataUtils', 'bsScreenSize', '$window', '$translate', 'Address', 'PhoneNumber', 'AuthServerProvider', 'ReceiptFile', 'ProductLookup', 'Warranty', 'BrandService', 'CMS_URI', 'CmsWarrantyService', 'Card', '$log', 'CmsShopService', 'ExtensionLookup', 'GalleryCheckService'];

  function PurchasedProductDetailController($state, $q, $scope, Brand, BrandLookup, ShopLookup, Shop, AlertService, $rootScope, $http, $stateParams, previousState, entity, Principal, PurchasedProduct, Receipt, Gift, Product, PurchasedProductWarranty, WarrantyClaim, ProductResources, DEFAULT_BARCODE_ENCODING, DataUtils, bsScreenSize, $window, $translate, Address, PhoneNumber, AuthServerProvider, ReceiptFile, ProductLookup, Warranty, BrandService, CMS_URI, CmsWarrantyService, Card, $log, CmsShopService, ExtensionLookup, GalleryCheckService) {
    var vm = this;
    vm.purchasedProduct = entity;
    vm.purchasedProductProduct = {};
    vm.datePickerOpenStatus = {};
    vm.isOwner = false;
    vm.isAdmin = false;
    vm.isGiftReceiver = null;
    vm.canManageWarranties = null;
    vm.validWarranties = vm.purchasedProduct.totalWarranties.numValid;
    vm.expiringWarranties = vm.purchasedProduct.totalWarranties.numExpiring;
    vm.expiredWarranties = vm.purchasedProduct.totalWarranties.numExpired;
    vm.legalWarrantiesValid = [];
    vm.legalWarrantiesExpired = [];
    vm.legalWarrantyExpiring = [];
    vm.nonLegalWarranties = [];
    vm.editingWarranties = {};
    vm.carouselImages = [];
    vm.barCodes = [];
    vm.newConventionalWarranty = null;
    vm.newExtraWarranty = null;
    vm.manuals = [];
    vm.warrantyFormAdd = null;
    vm.hasShop = false;
    vm.canShow = canShow;
    vm.getImageUrl = getImageUrl;
    vm.ssize = ssize;
    vm.deleteFile = deleteFile;
    vm.addPurchasedProduct = addPurchasedProduct;
    vm.editWarranty = editWarranty;
    vm.saveWarranty = saveWarranty;
    vm.onUpdateWarranty = onUpdateWarranty;
    vm.onUpdateWarrantyNewProduct = onUpdateWarrantyNewProduct;
    vm.onEditClickCheck = onEditClickCheck;
    vm.cards = [];
    vm.cmsShopReference;
    vm.showGiftQuestion = true;
    vm.collapsed = 'search';
    vm.cmsUri = CMS_URI;
    vm.isOfficial = false;

    vm.extensionList = [];

    vm.productBrandName = "";
    vm.productBrandLogo;
    vm.resolveBrand = resolveBrand;

    vm.openCalendar = openCalendar;
    vm.datePickerOptions = { maxDate: new Date() };
    vm.isEdit = false;
    vm.isEdit2 = false;
    vm.isEdit3 = vm.purchasedProduct.receipt.shop ? false : true;
    vm.brands = Brand.query({ isActive: true });
    vm.imagesPath = {};
    vm.typeCodes = ["GTIN", "EAN", "UPC"];

    vm.conventionalWarranty = null;
    vm.otherWarranties = [];
    vm.extraWarranty = null;
    vm.editConventionalWarranty = editConventionalWarranty;
    vm.saveConvetionalWarranty = saveConvetionalWarranty;
    vm.addExtralWarranty = addExtralWarranty;
    vm.onUpdateConventionalWarranty = onUpdateConventionalWarranty;
    vm.onUpdateExtraWarranty = onUpdateExtraWarranty;
    vm.addNewExtraWarranty = addNewExtraWarranty;
    vm.oldWarranties = {};
    vm.updateWarranty = updateWarranty;
    vm.resolveGuarantor = resolveGuarantor;
    vm.cmsWarrantyData;

    if (vm.purchasedProduct.giftReceiverEmail != null) {
      vm.purchasedProduct.gift = 'Y';
    }
    vm.warrantyDetail;
    vm.warrentyModel;
    vm.countOtherProducts = countOtherProducts;
    vm.totalOtherProducts;
    setOpenedWarranty();

    vm.isGiftPresent;

    vm.hideShare = true;
    vm.shareCopyForMyself = false;
    loadGiftData();


    function loadGiftData() {
      if (vm.purchasedProduct.giftMakerDescription || vm.purchasedProduct.giftReceiverEmail) {
        vm.isGiftPresent = true;
        vm.giftInfo = {};
        vm.giftInfo.giftMakerDescription = vm.purchasedProduct.giftMakerDescription;
        vm.giftInfo.giftReceiverEmail = vm.purchasedProduct.giftReceiverEmail;
        vm.giftInfo.giftDescription = vm.purchasedProduct.giftDescription;
        vm.showGiftQuestion = false;
      }
    }

    //FIXME
    function countOtherProducts() {
      var totalCount = 0;
      vm.receipt.purchasedProducts.forEach(function (purchProd) {
        if (purchProd.product.underWarranty || (!purchProd.product.underWarranty && purchProd.product.productWarranty)) {
          totalCount++;
        }
      });
      vm.totalOtherProducts = totalCount;
    }

    vm.getBrandData = function (field) {
      if (vm.purchasedProduct && vm.purchasedProduct.product && vm.purchasedProduct.product.brand) {
        return vm.purchasedProduct.product.brand[field];
      }

    }

    vm.shareReceipt = function () {
      if (vm.shareRecipient) {
        $log.debug("Shareing receipt " + vm.receipt.id + " with " + vm.shareRecipient + " message: " + vm.shareMailBody);
        Receipt.share({ receiptId: vm.receipt.id, recipient: vm.shareRecipient, copyForMyself: vm.shareCopyForMyself }, { mailBody: vm.shareMailBody }).$promise
          .then(function (result) {
            $translate("garanteasyApp.purchasedProduct.detail.share.success").then(function (t) {
              AlertService.success(t);
              vm.hideShare = true;
            });
          },
            function (error) {
              $log.error("Errore nella condivisione ricevuta", error);
              AlertService.error(error);
            });
      }
    };

    vm.getManualImage = function (manual) {

      var manualImage = "../../../content/images_no_gulp/icone_elementi_prodotto/MANUALE_UTENTE.png";

      if (manual && manual.description) {
        switch (manual.description) {
          case "EU Product Fiche":
            manualImage = "../../../content/images_no_gulp/icone_elementi_prodotto/fiche.png";
            break;
          case "EU etichetta energetica":
            manualImage = "../../../content/images_no_gulp/icone_elementi_prodotto/etichetta_energetica.png";
            break;
          case "URL":
            manualImage = "../../../content/images_no_gulp/icone_elementi_prodotto/url.png";
            break;
          case "Volantino":
            manualImage = "../../../content/images_no_gulp/icone_elementi_prodotto/volantino.png";
            break;

          default:
            break;
        }
      }

      return manualImage;
    }

    vm.isLastWarranty = function (index, type) {
      index++;
      if (vm.purchasedProduct.purchasedProductWarranties.length) {
        for (; index < vm.purchasedProduct.purchasedProductWarranties.length; index++) {
          if (type === "C") {
            if (vm.purchasedProduct.purchasedProductWarranties[index].warranty.contractType === "CONVENTIONAL") {
              return false;
            }
          }
          if (type === "E") {
            if (vm.purchasedProduct.purchasedProductWarranties[index].warranty.contractType === "SERVICE_CONTRACT"
              || vm.purchasedProduct.purchasedProductWarranties[index].warranty.contractType === "INSURANCE_POLICY"
            ) {
              return false;
            }
          }
        }
      }
      return true;
    }

    vm.getTotalWarrantiesByTypes = function (types) {
      var count = 0;
      if (vm.purchasedProduct.purchasedProductWarranties.length && types && types.length) {
        types.forEach(function (type) {
          vm.purchasedProduct.purchasedProductWarranties.forEach(function (purchasedProductWarranty) {
            if (purchasedProductWarranty.warranty && purchasedProductWarranty.warranty.contractType === type) {
              count++;
            }
          });
        });
      }
      return count;
    };

    function resolveGuarantor(purchasedProductWarranty) {
      if (purchasedProductWarranty.warranty.contractType === 'LEGAL') {
        if (purchasedProductWarranty.receipt && purchasedProductWarranty.receipt.shop && purchasedProductWarranty.receipt.shop.description) {
          return purchasedProductWarranty.receipt.shop.description;
        }
      }
      if (purchasedProductWarranty.warranty.contractType === 'CONVENTIONAL') {
        if (purchasedProductWarranty.purchasedProduct && purchasedProductWarranty.purchasedProduct.product && purchasedProductWarranty.purchasedProduct.product.producer && purchasedProductWarranty.purchasedProduct.product.producer.name) {
          if (vm.cmsWarrantyData && vm.cmsWarrantyData.holder_company) {
            return vm.cmsWarrantyData.holder_company;
          }
          return purchasedProductWarranty.purchasedProduct.product.producer.name;
        }
      }
      if (purchasedProductWarranty.warranty.contractType === 'SERVICE_CONTRACT' ||
        purchasedProductWarranty.warranty.contractType === 'INSURANCE_POLICY'
      ) {
        return purchasedProductWarranty.warranty.description;
      }
    }

    function resolveBrand() {
      if (vm.purchasedProduct && vm.purchasedProduct.product && vm.purchasedProduct.product.brand && vm.purchasedProduct.product.brand.cmsReference) {
        BrandService.getBrandData(vm.purchasedProduct.product.brand.cmsReference).then(function (response) {
          vm.productBrandName = response[0].title;
          vm.productBrandLogo = CMS_URI + response[0].brand_image_full;
        });
      } else if (vm.purchasedProduct && vm.purchasedProduct.product && vm.purchasedProduct.product.brand) {
        vm.productBrandName = vm.purchasedProduct.product.brand.name;
      }
    }

    function resolveCmsData() {
      //resolve brand
      resolveBrand();
      //resolve warranty
      //vm.warrantyDetail.warranty.reference = "10ae5337-30e4-4144-b632-c28b62d02413";
      if (vm.warrantyDetail && vm.warrantyDetail.warranty && vm.warrantyDetail.warranty.reference) {
        //load data from cms
        CmsWarrantyService.getWarrantyData(vm.warrantyDetail.warranty.reference).then(function (response) {
          vm.cmsWarrantyData = response[0];
        });
      }

      if (vm.cmsShopReference) {
        CmsShopService.getShopData(vm.cmsShopReference).then(function (response) {
          vm.cmsShopData = response[0];
        });
      }
    }

    function onSuccessLoadReceipt(result) {
      vm.receipt = result;
      loadActiveCards();
      if (vm.receipt.purchasedProducts.length > 0) {
        var ind = vm.receipt.purchasedProducts.indexOf(vm.purchasedProduct);
        vm.receipt.purchasedProducts.splice(ind, ind + 1);
        var brandMap = new Map();
        var queryString = "";
        for (var i = 0; i < vm.receipt.purchasedProducts.length; i++) {
          if (vm.receipt.purchasedProducts[i] && vm.receipt.purchasedProducts[i].product && vm.receipt.purchasedProducts[i].product.brand && vm.receipt.purchasedProducts[i].product.brand.cmsReference) {
            var brandId = vm.receipt.purchasedProducts[i].product.brand.id;
            var reference = vm.receipt.purchasedProducts[i].product.brand.cmsReference;
            brandMap.set(brandId, reference);
            queryString += reference + ",";
          }
        }

        if (queryString.length != 0) {
          queryString = queryString.substring(0, queryString.length - 1);
          BrandService.getBrandData(queryString).then(function (response) {
            var resultMap = new Map();
            if (response) {
              for (var i = 0; i < response.length; i++) {
                if (response[i].title) {
                  resultMap.set(response[i].uuid, response[i].title);
                }
              }
              for (var i = 0; i < vm.receipt.purchasedProducts.length; i++) {
                if (vm.receipt.purchasedProducts[i] && vm.receipt.purchasedProducts[i].product && vm.receipt.purchasedProducts[i].product.brand && vm.receipt.purchasedProducts[i].product.brand.cmsReference) {
                  var brandUuid = vm.receipt.purchasedProducts[i].product.brand.cmsReference;
                  if (resultMap.has(brandUuid)) {
                    vm.receipt.purchasedProducts[i].product.brand.name = resultMap.get(brandUuid);
                  }
                }
              }
            }
          });

        }

      }

      //vm.cmsShopReference = '35795197-ec01-4bf6-84a1-0c24f688a2dc';
      if (vm.receipt.shop && vm.receipt.shop.id) {
        vm.hasShop = true;
        if (vm.receipt.shop.cmsReference) {
          vm.cmsShopReference = vm.receipt.shop.cmsReference;
          //TODO REMOVE THIS LINE FOR TEST ONLY
          //vm.cmsShopReference =  '9b8521fd-cbcc-48e3-9bdc-54db7ebe0629';
        }
      }
      for (var i = 0; i < vm.receipt.purchasedProducts.length; i++) {
        if (vm.receipt.purchasedProducts[i].id === vm.purchasedProduct.id) {
          vm.receipt.purchasedProducts.splice(i, 1);
        }
      }
      vm.countOtherProducts();
      resolveCmsData();
    }

    function onErrorLoadReceipt(result) {

    }
    Receipt.get({ id: vm.purchasedProduct.receipt.id }, onSuccessLoadReceipt, onErrorLoadReceipt);

    $scope.stateData.vm = vm;


    vm.datePickerOpenStatus.buyingDate = false;
    vm.datePickerOpenStatus.startDate = false;

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    buildWarrantyList();

    // BRANDS
    $scope.resetBrand = function () {
      vm.purchasedProduct.product.brand = null;
    };

    $scope.loadBrands = function (searchQuery) {
      if (searchQuery) {
        BrandLookup.query({ query: searchQuery }, onSuccessBrands, onErrorBrands);
      } else {
        $scope.brands = Brand.query({ isActive: true });
      }
    };


    $scope.loadProducts = function (searchQuery) {

      if (searchQuery && searchQuery.trim().length > 0) {
        ProductLookup.query({ query: searchQuery },
          function (result) {
            $scope.products = result;
          },
          function (response) {
            if (response.status === 404) {
              $scope.products = Product.query({ isActive: true },
                onSuccess,
                onError
              );
            }
          }
        );
      } else {
        ProductLookup.query({}, function (results) {
          $scope.products = results;
        });
      }
    };


    function onSuccessBrands(result) {
      $scope.brands = result;
    }

    function onErrorBrands(result) {
      $scope.brands = Brand.query({ isActive: true });
    }


    // SHOPS
    vm.shops = Shop.query({ filter: 'receipt-is-null' });

    $scope.resetShop = function () {
      if (vm.receipt) {
        vm.receipt.shop = null;
      }
    };

    $scope.loadShops = function (searchQuery) {
      if (searchQuery && searchQuery.trim().length > 1) {
        ShopLookup.query({ query: searchQuery }, function (result) {
          $scope.shops = result;
        }, function (response) {
          if (response.status === 404) {
            $scope.shops = Shop.query({ isActive: true });
          }
        });
      } else {
        $scope.shops = Shop.query({ isActive: true });
      }
    };

    vm.checkAccess = checkAccess;
    checkAccess();

    vm.barCodeOpt = undefined;
    vm.getBCOptions = function (format) {

      if (!vm.barCodeOpt) {

        vm.barCodeOpt = {
          format: (format || DEFAULT_BARCODE_ENCODING).toUpperCase(),
          lineColor: '#000000',
          width: 2,
          height: 100,
          displayValue: true,
          fontOptions: '',
          font: 'monospace',
          textAlign: 'center',
          textPosition: 'bottom',
          textMargin: 2,
          fontSize: 20,
          background: '#ffffff',
          margin: 0,
          marginTop: undefined,
          marginBottom: undefined,
          marginLeft: undefined,
          marginRight: undefined,
          valid: function (valid) { }
        };
      }

      return vm.barCodeOpt;
    };

    buildGallery();
    checkOfficial();

    vm.changeMainImage = function (image) {
      if (vm.purchasedProduct.product.images != undefined && vm.purchasedProduct.product.images.length > 1) {
        $('.zoomContainer').remove();
        vm.image = image;
      }
    };



    vm.gift = function (gift) {
      if (gift) {
        return 'SI';
      } else {
        return 'NO';
      }
    };

    function checkOfficial() {
      if (vm.purchasedProduct.receipt.source === 'MERCHANT') {
        vm.isOfficial = true;
      }
    }

    vm.editIfNotOfficial = function () {
      if (!vm.isOfficial) {
        vm.isEdit = true;
      }
    };

    function loadActiveCards() {
      if (vm.purchasedProduct.receipt.customer) {
        vm.receipt.customerId = vm.receipt.customer.id;
      }
      if (vm.receipt.customer && vm.receipt.buyingDate) {
        vm.cards = Card.queryActive(vm.receipt);
      }
    }

    function checkAccess() {
      Principal.identity().then(function (account) {
        if (vm.purchasedProduct.receipt.customer) {
          vm.isOwner = vm.purchasedProduct.receipt.customer.user.id === account.id;
        } else {
          vm.isOwner = false;
        }
        vm.isGiftReceiver = vm.purchasedProduct.giftReceiverUserId === account.id;
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });

        //If this is a gift, after notification warranties can be managed by gift receiver and not by owner
        vm.canManageWarranties = !vm.purchasedProduct.giftId || vm.isAdmin || (vm.isGiftReceiver && vm.purchasedProduct.giftReceiverNotified === true) || (vm.isOwner && vm.purchasedProduct.giftReceiverNotified === false);
      });


    }

    vm.fromReceipt = false;

    if ($stateParams.from) {
      $scope.from = $stateParams.from;
      vm.fromReceipt = true;
    }

    function onEditClickCheck() {
      if (!(vm.purchasedProduct.product.official && !vm.isAdmin)) {
        vm.isEdit = true;
      }
    }



    function setOpenedWarranty() {
      var id = $stateParams.warrantyId;
      if (id) {
        if (vm.purchasedProduct && vm.purchasedProduct.purchasedProductWarranties) {
          angular.forEach(vm.purchasedProduct.purchasedProductWarranties, function (productWarranty) {
            if (productWarranty.warranty && (productWarranty.id == id)) {
              vm.warrantyDetail = productWarranty;
              return;
            }
          });
        }
      }
    }

    vm.deleteImage = function (image) {
      ProductResources.delete({ id: image.id }, function () {
        var ind = vm.purchasedProduct.product.images.indexOf(image);
        vm.purchasedProduct.product.images.splice(ind, ind + 1);
        vm.image = vm.purchasedProduct.product.images[0];
      });
    };

    vm.uploadFile = function ($file) {
      vm.productResource = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.productResource.description = null;
          vm.productResource.productId = null;
          vm.productResource.fileData = null;
          vm.productResource.contentType = null;
          vm.productResource.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.toBase64($file, function (base64Data) {
            $scope.$apply(function () {
              vm.productResource.description = $file.name;
              vm.productResource.productId = vm.purchasedProduct.product.id;
              vm.productResource.fileData = base64Data;
              vm.productResource.contentType = $file.type;
              vm.productResource.type = ProductResources.RType.IMAGE;
              ProductResources.save(vm.productResource, function (result) {
                vm.purchasedProduct.product.images.push(result);
                if (result) {
                  vm.image = result;
                  $('.zoomContainer').remove();
                }
              });
            });
          });
        }
      }
    };

    vm.uploadReceiptFile = function ($file, isNewProduct) {
      vm.receiptFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.receiptFile.receiptId = null;
          vm.receiptFile.fileData = null;
          vm.receiptFile.contentType = null;
          vm.receiptFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.toBase64($file, function (base64Data) {
            $scope.$apply(function () {
              vm.receiptFile.receiptId = vm.receipt.id;
              vm.receiptFile.fileData = base64Data;
              vm.receiptFile.fileContentType = $file.type;
              vm.receiptFile.fileName = $file.name;
              if (isNewProduct) {
                if (!vm.receipt.purchasedProduct.receiptFiles) {
                  vm.receipt.purchasedProduct.receiptFiles = [];
                }
                vm.receipt.purchasedProduct.receiptFiles.push(vm.receiptFile);
              } else {
                vm.receiptFile.purchasedProductId = vm.purchasedProduct.id;
                ReceiptFile.save(vm.receiptFile, function (result) {
                  if (result) {
                    vm.purchasedProduct.receiptFiles.push(result);
                    buildGallery();
                    /* vm.image = result;
                    $('.zoomContainer').remove(); */
                  }
                });
              }
            });
          });
        }
      }
    };

    vm.visibleGallery = function () {
      return $rootScope.visibleGallery;
    };

    vm.activateNgGallery = function (imageId) {
      $rootScope.visibleGallery = true;
      var index;
      for (var i in vm.purchasedProduct.product.images) {
        if (vm.purchasedProduct.product.images[i].id === imageId)
          index = i;
      }
      if (index)
        index = Number(index);
      $rootScope.openGallery(index);

    };

    function ssize() {
      return '/' + bsScreenSize.state;
    }

    vm.imagesForGallery = [];

    vm.tranformImage = function () {
      var screenSize = vm.ssize();
      for (var i in vm.purchasedProduct.product.images) {
        var imageId = (vm.purchasedProduct.product.images[i].id);
        vm.imagesForGallery[i] = { "thumb": "api/product-resources/image/" + imageId + "?width=-&heigth=100", "img": "api/product-resources/image/" + imageId + '?width=-&heigth=350' };
      }
    };
    vm.tranformImage();



    vm.saveGift = function () {
      if (vm.giftInfo) {
        if (!vm.giftInfo.giftMakerDescription) {
          vm.purchasedProduct.giftMakerDescription = vm.receipt.customer.legalName;
        } else {
          vm.purchasedProduct.giftMakerDescription = vm.giftInfo.giftMakerDescription;
        }
        vm.purchasedProduct.giftReceiverEmail = vm.giftInfo.giftReceiverEmail;
        vm.purchasedProduct.giftDescription = vm.giftInfo.giftDescription;
        vm.isSaving = false;


        PurchasedProduct.update(vm.purchasedProduct, onSaveSuccess, onSaveError)
      }
    }

    vm.updateSerial = function () {
      if (vm.newSerialNumber) {
        vm.purchasedProduct.serialNumber = vm.newSerialNumber;
      }
      PurchasedProduct.update(vm.purchasedProduct, onSaveSuccess, onSaveError)
    }


    vm.saveFirst = function () {

      var promiseArray = [];


      vm.isSaving = false;
      $rootScope.viewPanelSuccess = true;
      $rootScope.receiptId = vm.purchasedProduct.id;
      $q.all(promiseArray).then(onSaveSuccess).catch(onSaveError);
      if (!vm.purchasedProduct.product.official) {
        Product.update(vm.purchasedProduct.product, onSaveSuccess, onSaveError);
      }
      if (vm.giftInfo) {
        PurchasedProduct.update(vm.purchasedProduct, onSaveSuccess, onSaveError)
      }
      Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
    };

    vm.saveSecond = function () {
      Receipt.update(vm.purchasedProduct.receipt, onSaveSuccess, onSaveError);
    };

    vm.saveThird = function () {
      if (vm.purchasedProduct.receipt.shop.address.id !== null) {
        Address.update(vm.purchasedProduct.receipt.shop.address, onSaveSuccess, onSaveError);
      } else {
        Address.save(vm.receipt.shop.address, onSaveSuccess, onSaveError);
      }
      if (vm.purchasedProduct.receipt.shop.phoneNumber && vm.purchasedProduct.receipt.shop.phoneNumber.id !== null) {
        PhoneNumber.update(vm.purchasedProduct.receipt.shop.phoneNumber, onSaveSuccess, onSaveError);
      } else {
        if (vm.purchasedProduct.receipt.shop.phoneNumber != null)
          PhoneNumber.save(vm.purchasedProduct.receipt.shop.phoneNumber, onSaveSuccess, onSaveError);
      }
      if (vm.purchasedProduct.receipt.shop.id !== null) {
        Shop.update(vm.purchasedProduct.receipt.shop, onSaveSuccess, onSaveError);
      } else {
        Shop.save(vm.purchasedProduct.receipt.shop, onSaveSuccess, onSaveError);
      }
    };

    vm.saveShop = function () {
      vm.isSaving = true;
      Receipt.update(vm.receipt, onSaveSuccessShop, onSaveError);
    };

    $scope.loadExtraWarranties = function (searchQuery) {
      ExtensionLookup.query({
        query: searchQuery
      },
        function (result) {
          vm.extensionList = result;
        },
        function (response) {
          $log.error("Error in warrantylokup", response);
          AlertService.error("Error: " + response.status);
        }
      );
    };

    function onSaveSuccess(result) {
      vm.isSaving = false;
      vm.isEdit = false;
      vm.isEdit2 = false;
      vm.isEdit3 = false;
      //vm.giftInfo = null;
      /* vm.purchasedProduct.warranty = null;
      vm.purchasedProduct.warrantyExtension = null; */
    }

    function onSaveSuccessShop(result) {
      onSaveSuccess();
      vm.hasShop = true;
    }

    function onSaveError(error) {
      vm.error = error;
      /*if(error.statusText === 'Forbidden'){
          AlertService.error("zdfsf");
      }*/
      vm.isSaving = false;
      vm.isEdit = false;
      vm.isEdit2 = false;
      vm.isEdit3 = false;
    }

    vm.getUrl = function () {
      if (vm.purchasedProduct.product.brand != null && vm.purchasedProduct.product.brand.warrantyUrl != null)
        $window.location.href = '' + vm.purchasedProduct.product.brand.warrantyUrl;
    };

    vm.initGift = function () {
      vm.purchasedProduct.giftNo = true;
      vm.purchasedProduct.giftYes = false;

      if (vm.purchasedProduct.gift) {
        vm.purchasedProduct.giftNo = false;
        vm.purchasedProduct.giftYes = true;
      }
    };

    vm.changeState = function () {
      if (vm.purchasedProduct.giftYes) {
        vm.purchasedProduct.giftNo = false;
      } else {
        vm.purchasedProduct.giftNo = true;
      }
    };

    function canShow(contentType) {
      return contentType != null && contentType.toLowerCase().indexOf('image') === 0 && contentType.toLowerCase().indexOf('tiff') === -1;
    }

    function getImageUrl(receiptFile) {
      if (!receiptFile || !receiptFile.id) {
        vm.imagesPath[receiptFile.id] = null;
      } else
        if (_.isUndefined(vm.imagesPath[receiptFile.id])) {
          vm.imagesPath[receiptFile.id] = "api/receipt-files/content/" + receiptFile.id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
        }
      return vm.imagesPath[receiptFile.id];
    }

    vm.getWarrantyUrl = function () {
      /* OLD
      if (!vm.purchasedProduct.receiptFiles || !vm.purchasedProduct.receiptFiles.length) {
        return null;
      }
      var image;
      image = vm.purchasedProduct.receiptFiles.find(function (file) {
        return file.typeForProduct === 'warranty';
      });
      if (image && image.fileData && image.fileContentType) {
        return 'data:' + image.fileContentType + ';base64,' + image.fileData;
      }
      return;
      */

      if (!vm.receiptFile) {
        return null;
      }
      if (vm.receiptFile && vm.receiptFile.fileData && vm.receiptFile.fileContentType) {
        return 'data:' + vm.receiptFile.fileContentType + ';base64,' + vm.receiptFile.fileData;
      }
    }

    var unsubscribe = $rootScope.$on('garanteasyApp:purchasedProductUpdate', function (event, result) {
      vm.purchasedProduct = result;
    });
    $scope.$on('$destroy', unsubscribe);

    $scope.$on('$destroy', function (event, next, current) {
      $('.zoomContainer').remove();
    });

    $(document).ready(function () {
      window.scrollTo(0, 0);
    });

    function deleteFile(file, isNewProduct) {
      if (isNewProduct) {
        var index = vm.receipt.purchasedProduct.receiptFiles.indexOf(file);
        return vm.receipt.purchasedProduct.receiptFiles.splice(index, 1);
      }
      ReceiptFile.delete({ id: file.id }, function () {
        var ind = vm.purchasedProduct.receiptFiles.indexOf(file);
        vm.purchasedProduct.receiptFiles.splice(ind, 1);
      });
    }


    function addPurchasedProduct() {
      vm.isSaving = true;
      if (
        angular.isDefined(vm.receipt.purchasedProduct) &&
        angular.isDefined(vm.receipt.purchasedProduct.product)
      ) {
        if (vm.receipt.purchasedProduct.product.id) {
          vm.receipt.purchasedProduct.allProductId = vm.receipt.purchasedProduct.product.id;
          vm.receipt.purchasedProduct.product = {};
        }
        vm.receipt.purchasedProduct.receiptId = vm.receipt.id;
        PurchasedProduct.save(vm.receipt.purchasedProduct, onSaveSuccessProduct, onSaveErrorProduct);
      }
    }

    function onSaveErrorProduct(error) {
      vm.error = error;
      vm.isSaving = false;
      AlertService.error(error.data.message);
    }

    function onSaveSuccessProduct(result) {
      var promiseArray = [];
      vm.isSaving = false;
      vm.addProduct = false;
      vm.receipt.purchasedProducts.push(result);
      if (vm.receipt.purchasedProduct.receiptFiles && vm.receipt.purchasedProduct.receiptFiles.length) {
        vm.receipt.purchasedProduct.receiptFiles.forEach(function (receiptFile) {
          if (receiptFile.fileDataChanged) {
            receiptFile.fileData = receiptFile.fileDataChanged;
          }
          delete receiptFile.fileDataChanged;
          delete receiptFile.changed;
          receiptFile.receiptId = vm.receipt.id;
          receiptFile.purchasedProductId = result.id;
          promiseArray.push(ReceiptFile.save(receiptFile, function (result) { }, onSaveErrorProduct).$promise);
        });
      }
      if (vm.receipt.purchasedProduct.warrantyConventional && result.id) {
        vm.receipt.purchasedProduct.warrantyConventional.purchasedProductId = result.id;
        promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProduct.warrantyConventional));
      }
      if (vm.receipt.purchasedProduct.warrantyService && result.id) {
        vm.receipt.purchasedProduct.warrantyService.purchasedProductId = result.id;
        promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProduct.warrantyService));
      }
      if (vm.receipt.purchasedProduct.warrantyInsurance && result.id) {
        vm.receipt.purchasedProduct.warrantyInsurance.purchasedProductId = result.id;
        promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProduct.warrantyInsurance));
      }
      $q.all(promiseArray).then(function () {
        vm.receipt.purchasedProduct = {};
      });
    }

    function editWarranty(i) {
      if (vm.isOfficial) {
        return;
      }
      if (!vm.otherWarranties[i]) {
        return false;
      }
      vm.otherWarranties[i].edit = true;
      vm.oldWarranties[i] = Object.assign({}, vm.otherWarranties[i]);
    }

    function saveWarranty(i) {
      vm.isSaving = true;
      Warranty.update(vm.editingWarranties[i], function (result) {
        vm.isSaving = false;
        vm.nonLegalWarranties[i].warranty = result;
        vm.nonLegalWarranties[i].edit = false;
        vm.editingWarranties[i] = null;
      }, onSaveError);
    }

    function saveUpdatePurchasedProductWarranty(purchasedProductWarranty, old) {
      delete purchasedProductWarranty.receipt;
      var promise = new Promise(function (resolve) {
        resolve(purchasedProductWarranty);
      });
      if (!purchasedProductWarranty.warranty.id) {
        promise = Warranty.save(purchasedProductWarranty.warranty).$promise;
      } else if (!purchasedProductWarranty.warranty.official && purchasedProductWarranty.warranty.id && purchasedProductWarranty.warranty.code === 'CUSTOM') {
        promise = Warranty.update(purchasedProductWarranty.warranty).$promise.then(function (result) {
          purchasedProductWarranty.warranty = result;
          return purchasedProductWarranty;
        });
      }
      if (!purchasedProductWarranty.id) {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || purchasedProductWarranty.warranty.id || warranty.id;
          return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
        });
      } else if (purchasedProductWarranty.id && old && purchasedProductWarranty.warranty.id !== old.warranty.id) {
        promise = promise.then(function (warranty) {
          return PurchasedProductWarranty.delete({ id: old.id }).$promise;
        }).then(function (warranty) {
          purchasedProductWarranty.id = null;
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || purchasedProductWarranty.warranty.id || warranty.id;
          return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
        });
      }
      return promise.then(function (result) {
        purchasedProductWarranty = result;
        return purchasedProductWarranty;
      });
    }

    function onUpdateWarranty(purchasedProductWarranty, valid) {
      if (!purchasedProductWarranty) {
        return;
      }
      for (var i = 0; i < vm.otherWarranties.length; i++) {
        if (vm.otherWarranties[i].id && vm.otherWarranties[i].id === purchasedProductWarranty.id) {
          vm.otherWarranties[i] = purchasedProductWarranty;
        }
      }
    }

    function buildGallery() {
      //vm.carouselImages = [];
      if (vm.purchasedProduct.receiptFiles && vm.purchasedProduct.receiptFiles.length) {
        vm.purchasedProduct.receiptFiles = vm.purchasedProduct.receiptFiles.filter(function (item) {
          return item.purchasedProductId === vm.purchasedProduct.id;
        });
        vm.purchasedProduct.receiptFiles.forEach(function (file) {
          if (file && file.id && vm.canShow(file.fileContentType)) {
            file.src = vm.getImageUrl(file);
            vm.carouselImages.push(file);
          }
        });
      }
    }

    Product.get({ id: vm.purchasedProduct.product.id },
      function (product) {
        $log.debug(product);
        vm.barCodes = [];
        vm.manuals = (product || {}).manuals;

        if (((product || {}).eanCodes || []).length) {
          product.eanCodes.forEach(function (item) {
            vm.barCodes.push(item);
          });

        }
        if (((product || {}).sku)) {
          vm.barCodes.push(product.sku);
        }
        if (product && product.images && product.images.length) {
          product.images.forEach(function (image) {
            if (image.localCopy === null || image.localCopy) {
              image.src = 'api/product-resources/image/' + image.id + '?width=-&heigth=350';
            } else {
              image.src = image.value;
            }
            vm.carouselImages.push(image);

          });
          product.images.sort(function (a, b) {
            if (a.main) {
              return -1;
            } else if (b.main) {
              return 1;
            }
            return 0;
          });
        }
        GalleryCheckService.setData(vm.carouselImages);
      }, function (error) { $log.error(error); });
    //     .then( fuction ( product ){
    //
    // }, function(error) { });


    function buildWarrantyList() {
      vm.otherWarranties = [];
      vm.conventionalWarranty = vm.purchasedProduct.otherWarrenties.length > 0;
      vm.extraWarranty = null;
      for (var i = 0; i < vm.purchasedProduct.purchasedProductWarranties.length; i++) {
        if (vm.purchasedProduct.purchasedProductWarranties[i].warranty.warrantyType === 'LEGAL' && vm.purchasedProduct.purchasedProductWarranties[i].status === 'valid') {
          vm.legalWarrantiesValid.push(vm.purchasedProduct.purchasedProductWarranties[i].warranty);
        }
        if (vm.purchasedProduct.purchasedProductWarranties[i].warranty.warrantyType === 'LEGAL' && vm.purchasedProduct.purchasedProductWarranties[i].status === 'expired') {
          vm.legalWarrantiesExpired.push(vm.purchasedProduct.purchasedProductWarranties[i].warranty);
        }
        if (vm.purchasedProduct.purchasedProductWarranties[i].warranty.warrantyType === 'LEGAL' && vm.purchasedProduct.purchasedProductWarranties[i].status === 'expiring') {
          vm.legalWarrantyExpiring.push(vm.purchasedProduct.purchasedProductWarranties[i].warranty);
        }

        if (!vm.conventionalWarranty && vm.purchasedProduct.purchasedProductWarranties[i].warranty.warrantyType !== 'LEGAL' && vm.purchasedProduct.purchasedProductWarranties[i].warranty.warrantyType === 'CONVENTIONAL' &&
          vm.purchasedProduct.purchasedProductWarranties[i].warranty.applicabilityTypes === 'STANDARD' && vm.purchasedProduct.purchasedProductWarranties[i].warranty.protectedRisk === 'DEFECT' && vm.purchasedProduct.purchasedProductWarranties[i].warranty.guarantorType === 'PRODUCER') {
          // vm.purchasedProduct.warrantyConventional = vm.purchasedProduct.purchasedProductWarranties[i].warranty;
          vm.conventionalWarranty = vm.purchasedProduct.purchasedProductWarranties[i];
        } else if (vm.purchasedProduct.purchasedProductWarranties[i].warranty.warrantyType === 'LEGAL') {
          vm.otherWarranties.push(vm.purchasedProduct.purchasedProductWarranties[i]);
        }

        /* if (vm.purchasedProduct.purchasedProductWarranties[i].warranty.type !== 'LEGAL' && vm.purchasedProduct.purchasedProductWarranties[i].warranty.type === 'SERVICE_CONTRACT') {
          vm.purchasedProduct.warrantyService = vm.purchasedProduct.purchasedProductWarranties[i].warranty;
        }
        if (vm.purchasedProduct.purchasedProductWarranties[i].warranty.type !== 'LEGAL' && vm.purchasedProduct.purchasedProductWarranties[i].warranty.type === 'INSURANCE') {
          vm.purchasedProduct.warrantyInsurance = vm.purchasedProduct.purchasedProductWarranties[i].warranty;
        } */
        /*vm.totalNumExpired += data[i].totalWarranties.numExpired;
        vm.totalNumValid += data[i].totalWarranties.numValid;
        vm.totalNumExpiring += data[i].totalWarranties.numExpiring;
        vm.validWarranties += data[i].totalWarranties.numValid;
        vm.expiringWarranties += data[i].totalWarranties.numExpiring;
        vm.expiredWarranties += data[i].totalWarranties.numExpired;*/
      }
    }

    function onUpdateWarrantyNewProduct(purchasedProductWarranty, valid) {
      if (!purchasedProductWarranty) {
        return;
      }
      switch (purchasedProductWarranty.warranty.type) {
        case 'CONVENTIONAL':
          vm.receipt.purchasedProduct.warrantyConventional = Object.assign({}, purchasedProductWarranty);
          break;
        case 'SERVICE_CONTRACT':
          vm.receipt.purchasedProduct.warrantyService = Object.assign({}, purchasedProductWarranty);
          break;
        case 'INSURANCE':
          vm.receipt.purchasedProduct.warrantyInsurance = Object.assign({}, purchasedProductWarranty);
          break;
      }
    }

    function editConventionalWarranty(editing) {
      if (vm.isOfficial) {
        return;
      }
      if (editing) {
        vm.conventionalWarrantyEdit = true;
        if (vm.conventionalWarranty) {
          vm.oldConventionalWarranty = Object.assign({}, vm.conventionalWarranty);
        }
      } else {
        vm.conventionalWarrantyEdit = false;
        if (vm.oldConventionalWarranty) {
          vm.conventionalWarranty = Object.assign({}, vm.oldConventionalWarranty);
        }
        if (vm.conventionalWarranty && !vm.conventionalWarranty.id) {
          vm.conventionalWarranty = null;
        }
        vm.oldConventionalWarranty = null;
      }
    }

    vm.onUpdateNewConventionalWarranty = function (purchasedProductWarranty) {
      vm.newConventionalWarranty = Object.assign({}, purchasedProductWarranty);
    };

    vm.onUpdateNewExtraWarranty = function (purchasedProductWarranty) {
      vm.newExtraWarranty = Object.assign({}, purchasedProductWarranty);
    };

    vm.convertIcon = function convertIcon(warrantyExtension) {
      if (warrantyExtension && warrantyExtension.warranty) {
        if (warrantyExtension.warranty.guarantorType === 'PRODUCER') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/azienda.png";
        }
        if (warrantyExtension.warranty.guarantorType === 'INSURANCE') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/ombrello_scudo_bianco.png";
        }
        if (warrantyExtension.warranty.guarantorType === 'ASSISTANCE_CENTER') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/assistenza_1scudo_bianco.png";
        }
      }
      return "../../../content/images/icone_new/icone_sfondo_bianco/punto_domanda.png";
    }

    function addExtralWarranty(purchasedProductWarranty) {
      if (purchasedProductWarranty) {
        saveUpdatePurchasedProductWarranty(purchasedProductWarranty).then(function (res) {
          vm.purchasedProduct.purchasedProductWarranties.push(res);
          vm.addExtraWarrany = false;
          vm.newConventionalWarranty = null;
          vm.newExtraWarranty = null;
          vm.receiptFile = {};
          PurchasedProduct.update(vm.purchasedProduct, onUpdateSuccess, onUpdateError);
          return buildWarrantyList();
        });
      }
    }

    function onUpdateSuccess() {

    }

    function onUpdateError() {

    }

    function saveConvetionalWarranty(purchasedProductWarranty, old) {
      if (!purchasedProductWarranty && old && old.id) {
        PurchasedProductWarranty.delete({ id: old.id }).$promise.then(function (res) {
          for (var i = 0; i < vm.purchasedProduct.purchasedProductWarranties.length; i++) {
            if (vm.purchasedProduct.purchasedProductWarranties[i].id === old.id) {
              vm.purchasedProduct.purchasedProductWarranties.splice(i, 1);
              vm.conventionalWarrantyEdit = false;
              return buildWarrantyList();
            }
          }
        });
      } else if (purchasedProductWarranty) {
        saveUpdatePurchasedProductWarranty(purchasedProductWarranty, old).then(function (res) {
          for (var i = 0; i < vm.purchasedProduct.purchasedProductWarranties.length; i++) {
            if (vm.purchasedProduct.purchasedProductWarranties[i].id === purchasedProductWarranty.id) {
              vm.purchasedProduct.purchasedProductWarranties[i] = res;
              vm.conventionalWarrantyEdit = false;
              return buildWarrantyList();
            }
          }
          vm.purchasedProduct.purchasedProductWarranties.push(res);
          vm.conventionalWarrantyEdit = false;
          return buildWarrantyList();
        });
      }
    }

    function onUpdateConventionalWarranty(purchasedProductWarranty) {
      vm.conventionalWarranty = Object.assign({}, purchasedProductWarranty);
    }

    function onUpdateExtraWarranty(purchasedProductWarranty) {
      vm.extraWarranty = Object.assign({}, purchasedProductWarranty);
    }

    function addNewExtraWarranty() {
      vm.extraWarranty = {};
      vm.addExtraWarrany = true;
    }

    function updateWarranty(purchasedProductWarranty, i) {
      if (!purchasedProductWarranty && vm.oldWarranties[i] && vm.oldWarranties[i].id) {
        PurchasedProductWarranty.delete({ id: vm.oldWarranties[i].id }).$promise.then(function (res) {
          for (var i = 0; i < vm.purchasedProduct.purchasedProductWarranties.length; i++) {
            if (vm.purchasedProduct.purchasedProductWarranties[i].id === vm.oldWarranties[i].id) {
              vm.purchasedProduct.purchasedProductWarranties.splice(i, 1);
              purchasedProductWarranty.edit = false;
              return buildWarrantyList();
            }
          }
        });
      } else if (purchasedProductWarranty) {
        saveUpdatePurchasedProductWarranty(purchasedProductWarranty, vm.oldWarranties[i]).then(function (res) {
          for (var i = 0; i < vm.purchasedProduct.purchasedProductWarranties.length; i++) {
            if (vm.purchasedProduct.purchasedProductWarranties[i].id === purchasedProductWarranty.id) {
              vm.purchasedProduct.purchasedProductWarranties[i] = res;
              purchasedProductWarranty.edit = false;
              return buildWarrantyList();
            }
          }
          vm.purchasedProduct.purchasedProductWarranties.push(res);
          purchasedProductWarranty.edit = false;
          return buildWarrantyList();
        });
      }
    }

    vm.updateShop = function (newShop) {
      vm.receipt.shop = newShop;
    };

    function addImageToProduct() {
      if (!vm.purchasedProduct.receiptFiles) {
        vm.purchasedProduct.receiptFiles = [];
      }
      var index = vm.purchasedProduct.receiptFiles.indexOf(vm.receiptFile);
      if (index !== -1) {
        vm.purchasedProduct.receiptFiles[index] = vm.receiptFile;
      } else {
        vm.purchasedProduct.receiptFiles.push(vm.receiptFile);
      }
      /* vm.activeSlide = 3; */
    }

    vm.uploadWarrantyFile = function ($file) {
      vm.receiptFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000 && $file.type.indexOf('image') === -1 || $file.size > 20480000) {
          $translate('garanteasyApp.receipt.error.fileSize').then(function (error) {
            AlertService.error(error);
          }, function (translationId) {
            AlertService.error(translationId);
          });
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.receiptFile.receiptId = null;
          vm.receiptFile.fileData = null;
          vm.receiptFile.contentType = null;
          vm.receiptFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.resizeifNecessary($file, function (base64Data) {
            $scope.$apply(function () {
              vm.receiptFile.fileData = base64Data;
              vm.receiptFile.fileContentType = $file.type;
              vm.receiptFile.fileName = $file.name;
              var extension = $file.name.substr($file.name.lastIndexOf('.'));
              vm.receiptFile.fileName = 'garanzia' + extension;
              vm.receiptFile.typeForProduct = 'warranty';
              addImageToProduct();
              //showProductImages(typeForProduct);
            });
          });
        }
      }
    };

  }


})
  ();
