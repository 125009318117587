(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ReceiptGiftDialogController', ReceiptGiftDialogController);

    ReceiptGiftDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Principal', 'PurchasedProduct', 'Receipt', 'Product', 'Gift'];

    function ReceiptGiftDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Principal, PurchasedProduct, Receipt, Product, Gift) {
        var vm = this;

        var params = $stateParams.listSelected;
        vm.receipt = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.saveGift = saveGift;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function saveGift() {
            vm.isSaving = true;
            if(params) {
                if (params.length === 1) {
                    params[0].deliveryDate = vm.purchasedProductGift.deliveryDate;
                    params[0].giftReceiverEmail = vm.purchasedProductGift.giftReceiverEmail;
                    params[0].giftNotificationDate = vm.purchasedProductGift.giftNotificationDate;
                    params[0].giftMakerDescription = vm.purchasedProductGift.giftMakerDescription;
                    params[0].giftDescription = vm.purchasedProductGift.giftDescription;
                    PurchasedProduct.update(params[0], onSaveSuccess, onSaveError);
                } else if (params.length > 1) {
                    angular.forEach(params, function (item) {
                        item.deliveryDate = vm.purchasedProductGift.deliveryDate;
                        item.giftReceiverEmail = vm.purchasedProductGift.giftReceiverEmail;
                        item.giftNotificationDate = vm.purchasedProductGift.giftNotificationDate;
                        item.giftMakerDescription = vm.purchasedProductGift.giftMakerDescription;
                        item.giftDescription = vm.purchasedProductGift.giftDescription;
                    });
                    PurchasedProduct.updateList(params, onSaveSuccess, onSaveError);
                }
/*                else if (params.length === 0) {
                    var gift = {};
                    gift.giftReceiverEmail = vm.purchasedProductGift.giftReceiverEmail;
                    gift.giftNotificationDate = vm.purchasedProductGift.giftNotificationDate;
                    gift.giftMakerDescription = vm.purchasedProductGift.giftMakerDescription;
                    gift.giftDescription = vm.purchasedProductGift.giftDescription;
                    gift.giftNotifiedReceiver = 0;
                    Gift.save(gift);
                }*/
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyApp:purchasedProductUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.deliveryDate = false;
        vm.datePickerOpenStatus.giftNotificationDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
