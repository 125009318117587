'use strict';
(function () {
  angular
    .module('garanteasyApp')
    .controller('ErrorController', ErrorController);
  ErrorController.$inject = ['$state','$log', 'Principal'];

  function ErrorController($state, $log, Principal) {
    if( $state.current.name === 'accessdenied'){
      var isAuthenticated = Principal.isAuthenticated();
      if( isAuthenticated){
        $state.go("receipt");
      }
    }
  }
})();
