(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('AccountDeleteController',AccountDeleteController);

    AccountDeleteController.$inject = ['$state', '$stateParams', '$uibModalInstance', 'entity', 'Account','Principal', 'Auth', 'AlertService', '$translate', 'AuthServerProvider'];

    function AccountDeleteController($state, $stateParams, $uibModalInstance, entity, Account, Auth, Principal, AlertService, $translate, AuthServerProvider) {
        var vm = this;
        vm.account = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.logout = logout;

        function logout() {
            Auth.logout();
            vm.account = null;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            if (vm.login) {
                Account.deleteUser({login: vm.login}, onSuccess, onError);
            }
        }

        function onSuccess(){
            $uibModalInstance.close(true);
            $state.param = 'userDeleted';
        }

        function onError(){
            $translate("garanteasyApp.customer.merchantPrivacyPolicy.loginErr").then( function(t) {
                $uibModalInstance.close(true);
                AlertService.error(t);
                // $state.param = 'settings';
                $state.param = $state.current.parent;
            });

        }
    }
})();
