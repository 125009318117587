(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('MerchantOperatorController', MerchantOperatorController);

    MerchantOperatorController.$inject = ['$scope', '$state', 'MerchantOperator', 'AddressSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', '$translate'];

    function MerchantOperatorController ($scope, $state, MerchantOperator, AddressSearch, ParseLinks, AlertService, pagingParams, paginationConstants, $translate) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.currentSearchOfficial = pagingParams.official;
        vm.officialOperators = pagingParams.official;
        vm.enable = enable;
        vm.disable = disable;

        loadAll();

        function loadAll() {
            if (pagingParams.search || pagingParams.official) {
                MerchantOperator.search({
                    query: pagingParams.search,
                    official: pagingParams.official,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                MerchantOperator.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.merchantOperators = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                official: vm.currentSearchOfficial
            });
        }

        function search(searchQuery) {
            if (!searchQuery && !vm.officialOperators) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.currentSearchOfficial = vm.officialOperators;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.currentSearchOfficial = null;
            vm.transition();
        }

        function enable(merchantOperator) {
            if (merchantOperator) {
                // PhoneNumber.update(vm.phoneNumber, onSaveSuccess, onSaveError);
                MerchantOperator.enable(merchantOperator, onSuccessEnable, onErrorEnable);
            }

            function onSuccessEnable(data, headers) {
                $state.go('merchantOperator', null, {reload: 'merchantOperator'});
            }

            function onErrorEnable(error) {
                if(error.headers()['x-garanteasyapp-error']){
                    var errorType = error.headers()['x-garanteasyapp-error'];
                    $translate("garanteasyApp.merchantOperator."+errorType).then( function(t){
                        // vm.errorMessage = t;
                        AlertService.error(t);
                    });
                }else {
                    $translate("garanteasyApp.merchantOperator.error.genericEnableError").then( function(t){
                        vm.errorMessage = t;
                    });
                }
            }
        }

        function disable (merchantOperator) {
            if (merchantOperator) {
                MerchantOperator.disable(merchantOperator, onSuccessDisable, onErrorDisable);
            }
            function onSuccessDisable(data, headers) {
                $state.go('merchantOperator', null, {reload: 'merchantOperator'});
            }
            function onErrorDisable(error) {
                if(error.headers()['x-garanteasyapp-error']){
                    var errorType = error.headers()['x-garanteasyapp-error'];
                    $translate("garanteasyApp.merchantOperator."+errorType).then( function(t){
                        // vm.errorMessage = t;
                        AlertService.error(t);
                    });
                } else {
                    $translate("garanteasyApp.merchantOperator.error.genericDisableError").then( function(t){
                        vm.errorMessage = t;
                    });
                }
            }
        }
    }
})();
