(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CardTypeDetailController', CardTypeDetailController);

    CardTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'CardType', 'User'];

    function CardTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, CardType, User) {
        var vm = this;

        vm.cardType = entity;
        
        if($stateParams.from) {
            $scope.from = $stateParams.from
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:cardTypeUpdate', function(event, result) {
            vm.cardType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
