(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ReceiptFastTrackController', ReceiptFastTrackController);

  ReceiptFastTrackController.$inject = ['$timeout', '$scope', '$q', 'entity', 'Principal', 'Receipt', 'Customer', 'Contact', 'Shop', 'Merchant', 'MerchantLookup', 'Card', '$state', 'DataUtils', 'ReceiptFile', 'AlertService', 'ProductLookup', 'BrandLookup', 'BrandSearch', 'CompanyLookup', 'AuthServerProvider', '$log', 'Warranty', '$rootScope', '$translate', 'PurchasedProductWarranty', 'uibDateParser', 'PurchasedProduct', 'ProductResources'];

  function ReceiptFastTrackController($timeout, $scope, $q, entity, Principal, Receipt, Customer, Contact, Shop, Merchant, MerchantLookup, Card, $state, DataUtils, ReceiptFile, AlertService, ProductLookup, BrandLookup, BrandSearch, CompanyLookup, AuthServerProvider, $log, Warranty, $rootScope, $translate, PurchasedProductWarranty, uibDateParser, PurchasedProduct, ProductResources) {
    var vm = this;
    vm.activeSlide = 1;
    vm.useCard = null;
    vm.receipt = entity;
    if (!vm.receipt.receiptType) {
      vm.receipt.receiptType = 'RECEIPT';
    }
    vm.receipt.transactionCurrency = 'EUR';
    vm.clear = clear;
    vm.isNewProduct = false;
    vm.onNextClick = onNextClick;
    vm.receipt.buyingDate = new Date();
    vm.datePickerOpenStatus = {};
    vm.alerts = [];
    vm.datePickerOptions = {
      maxDate: new Date(),
      initDate: uibDateParser.parse(new Date())
    };
    vm.savedReceiptFile = false;
    vm.openCalendar = openCalendar;
    vm.save = save;
    vm.saveUpdateOnlyRecipe = saveUpdateOnlyRecipe;
    vm.saveReceiptFile = saveReceiptFile;
    vm.savePurchasedProduct = savePurchasedProduct;
    vm.onUpdateWarrantyNewProduct = onUpdateWarrantyNewProduct;
    vm.addImageToProduct = addImageToProduct;
    vm.getImageProductUrl = getImageProductUrl;
    vm.onUpdateConventionalWarranty = onUpdateConventionalWarranty;
    vm.showProductImages = showProductImages;
    vm.addOnBlurProduct = addOnBlurProduct;
    vm.onChangeProduct = onChangeProduct;
    vm.onKeyUpPorduct = onKeyUpPorduct;
    vm.onChangeReceiptType = onChangeReceiptType;
    vm.images = [];
    vm.icecatProducts = [];
    vm.products = [];
    vm.receiptFiles = [];
    vm.selectedReceiptFiles = [];
    vm.selectedType = '';
    vm.tempProductData;
    vm.onReceiptCompleted = onReceiptCompleted;
    vm.tempSavingProduct = null;
    vm.getConventionalWarranty = getConventionalWarranty;
    vm.customers = Customer.query({}, function (result) {
      vm.receipt.customer = result[0];
      vm.receipt.customerId = vm.receipt.customer.id;
      vm.receipt.profile = vm.receipt.business ? 'BUSINESS' : 'PERSONAL';
    });
    vm.contacts = Contact.queryActive();
    /* vm.shops = Shop.query({
      filter: 'receipt-is-null'
    });
    $q.all([vm.receipt.$promise, vm.shops.$promise]).then(function () {
      if (!vm.receipt.shopId) {
        return $q.reject();
      }
      return Shop.get({
        id: vm.receipt.shopId
      }).$promise;
    }).then(function (shop) {
      vm.shops.push(shop);
    }); */

    vm.productIdAssociation = new Map();

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });
    vm.isValidInput = isValidInput;
    vm.disableAddProduct = disableAddProduct;
    vm.validMerchant = null;
    vm.validAddress = null;

    // Le ricevute inviate da un negozio ufficiale (o in attesa di conferma dopo un claim) sono quasi totalmente readonly
    vm.isOfficial = vm.receipt.source === 'MERCHANT' || vm.receipt.source === 'CLAIM' || vm.receipt.source === 'CLAIM_RESOLVED';
    vm.isAdmin = null;

    var last_profile = vm.receipt.profile;
    vm.receipt.profile = vm.receipt.business ? 'BUSINESS' : 'PERSONAL';
    if (last_profile && vm.receipt.profile != last_profile) {
      $translate("garanteasyApp.receipt.changeProfile").then(function (t) {
        AlertService.info(t);
        /*vm.alert = t;*/
      });
    }


    var selectCustomer = function (customer) {
      if (customer) {
        vm.receipt.customer = customer;
        vm.receipt.customerId = vm.receipt.customer.id;
      } else {
        vm.receipt.customer = null;
        vm.receipt.customerId = null;
      }
    };

    vm.getImageReceiptUrl = function () {
      "api/receipt-files/content/" + vm.receiptFileUrl + "?width=-&height=377&token=" + AuthServerProvider.getToken();
    }

    vm.closeAlert = function (index) {
      vm.alerts.splice(index, 1);
    }

    vm.customerId = {
      wrap: function (customerId) {
        if (angular.isDefined(customerId)) {
          selectCustomer(_.find(vm.customers, function (cust) {
            return cust.id === customerId;
          }));
        }
        return vm.receipt.customerId;
      }
    };

    vm.addPurchasedProduct = addPurchasedProduct;
    vm.images = [];
    vm.documents = [];
    vm.customer = {};
    vm.merchantPrivacyPolicies = [];
    vm.typeCodes = ["GTIN", "EAN", "UPC"];
    vm.contPurchasedProducts = 0;
    vm.receipt.purchasedProducts = [];
    vm.receipt.numProducts = vm.receipt.purchasedProducts.length;
    if (!vm.receipt.shop) {
      vm.receipt.shop = {};
    }
    $rootScope.receipt = vm.receipt;
    if (!vm.receipt.shop.address) {
      vm.receipt.shop.address = {};
    }
    if (!vm.receipt.shop.address.country) {
      vm.receipt.shop.address.country = {};
    }
    vm.receipt.shop.address.country.code = 'IT';

    vm.ALL_SHOPS = -1;
    vm.PREFERRED_SHOPS = 0;
    vm.EDIT_SHOP = 1;
    vm.isNewShopCollapsed = vm.PREFERRED_SHOPS;

    /* Togliere */
    vm.ALL_PRODUCTS = -1;
    vm.PREFERRED_PRODUCTS = 0;
    vm.NEW_PRODUCT = 1;
    vm.isNewProductCollapsed = vm.PREFERRED_PRODUCTS;
    /* Togliere */

    vm.receipt.purchasedProduct = {};

    vm.crediCardCollapsed = true;

    vm.basePaymentType = ['CASH', 'CHEQUE', 'BANK', 'ATM_CARD', 'PAYPAL'];
    $scope.$watch('vm.receipt.paymentType', function (value) {
      if (!value || vm.basePaymentType.includes(value)) {
        vm.crediCardCollapsed = true;
      } else {
        vm.crediCardCollapsed = false;
      }
      $log.debug('vm.receipt.paymentType', value);
    });

    getAccount();
    // getAccountMerchants();


    vm.checkAccess = checkAccess;
    checkAccess();

    function checkAccess() {
      Principal.identity().then(function (account) {
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    $scope.resetShop = function () {
      vm.receipt.shop = null;
    };

    $scope.resetMerchant = function () {
      if (vm.receipt.shop !== null) {
        vm.receipt.shop.merchant = null;
      }
    };


    $scope.loadMerchants = function (searchQuery) {
      if (searchQuery) {
        MerchantLookup.query({
          query: searchQuery
        }, function (result) {
          $scope.merchants = result;
        }, function (response) {
          if (response.status === 404) {
            $scope.merchants = Merchant.query({
              isActive: true
            });
          }
        });
      } else {
        $scope.merchants = Merchant.query({
          isActive: true
        });
      }
    };



    // Constraints beyond the ones in the form
    function isValidInput() {

      if (vm.receipt.receiptType && vm.receipt.buyingDate) {
        return true;
      }
    }

    function onReceiptCompleted() {
      vm.saveUpdateOnlyRecipe();
      vm.activeSlide = 3;
    }

    function saveUpdateOnlyRecipe() {
      delete vm.receipt.shop;
      vm.isSaving = true;
      vm.receipt.cardId = vm.useCard ? vm.receipt.cardId : null;
      if (vm.receipt.id !== null) {
        Receipt.update(vm.receipt, onSaveSuccessReceipt, onSaveError);
        vm.isUpdatingReceipt = true;
      } else {
        Receipt.save(vm.receipt, onSaveSuccessReceipt, onSaveError);
      }
    }

    function onSaveSuccessReceipt(result) {
      if (vm.isUpdatingReceipt) {
        vm.alerts.push(
          {
            type: 'success',
            msg: "garanteasyApp.receipt.created",
            timeout: 20000
          });
      }
      vm.isUpdatingReceipt = false;
      vm.receipt.id = result.id;
      vm.receipt.creationDate = result.creationDate;
      vm.isSaving = false;
    }

    function getConventionalWarranty() {
      if (vm.receipt.purchasedProduct.purchasedProductWarranties) {
        var res = vm.receipt.purchasedProduct.purchasedProductWarranties
          .find(function (warranty) { return warranty.type === 'CONVENTIONAL'; })
        if (res != -1) {
          return res;
        }
      }
    }

    function saveReceiptFile() {
      var promiseArray = [];
      vm.receiptFiles.forEach(function (receiptFile) {
        if (receiptFile.fileDataChanged) {
          receiptFile.fileData = receiptFile.fileDataChanged;
        }
        delete receiptFile.fileDataChanged;
        delete receiptFile.changed;
        receiptFile.receiptId = vm.receipt.id;
        promiseArray.push(ReceiptFile.save(receiptFile, function (result) {
          vm.images.push(result);
          if (result) {
            vm.image = result;
            vm.images.push(result);
          }
        }).$promise);
      });
      $q.all(promiseArray).then(function (res) {
        vm.receiptFileRes = res[0];
        vm.isSaving = false;
        //vm.receiptFiles = [];
        vm.activeSlide = 1;
        vm.savedReceiptFile = true;
        vm.addProduct = true;
      }).catch(function (error) {
        vm.error = error;
        vm.isSaving = false;
        AlertService.error(error.data.message);
      });
    }

    function save() {
      delete vm.receipt.shop;
      vm.isSaving = true;
      vm.receipt.cardId = vm.useCard ? vm.receipt.cardId : null;
      if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length) {
        vm.receipt.purchasedProducts = vm.receipt.purchasedProducts.map(function (purchasedProduct) {
          if (purchasedProduct.product.id) {
            purchasedProduct.allProductId = purchasedProduct.product.id;
            purchasedProduct.product = {};
          }
          return purchasedProduct;
        });
      }
      if (vm.receipt.id !== null) {
        Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
      } else {
        vm.receipt.purchasedProduct = null;
        // if (!vm.receipt.receiptNumber) {
        //   vm.receipt.receiptNumber = Base64.encode(vm.receipt.buyingDate.toString());
        // }
        Receipt.save(vm.receipt, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      var receiptId = result.id;
      var promiseArray = [];
      vm.receipt.id = receiptId;
      if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length &&
        result.purchasedProducts && result.purchasedProducts.length) {
        for (var i = 0; i < result.purchasedProducts.length; i++) {
          if (vm.receipt.purchasedProducts[i] && vm.receipt.purchasedProducts[i].receiptFiles &&
            vm.receipt.purchasedProducts[i].receiptFiles.length && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].receiptFiles.map(function (file) {
              file.purchasedProductId = result.purchasedProducts[i].id;
              return file;
            });
            vm.receiptFiles = vm.receiptFiles.concat(vm.receipt.purchasedProducts[i].receiptFiles);
          }
          if (vm.receipt.purchasedProducts[i].warrantyConventional && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].warrantyConventional.purchasedProductId = result.purchasedProducts[i].id;
            promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProducts[i].warrantyConventional));
          }
          if (vm.receipt.purchasedProducts[i].warrantyService && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].warrantyService.purchasedProductId = result.purchasedProducts[i].id;
            promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProducts[i].warrantyService));
          }
          if (vm.receipt.purchasedProducts[i].warrantyInsurance && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].warrantyInsurance.purchasedProductId = result.purchasedProducts[i].id;
            promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProducts[i].warrantyInsurance));
          }
        }
      }
      if (vm.receiptFiles && vm.receiptFiles.length) {
        vm.receiptFiles.forEach(function (receiptFile) {
          if (receiptFile.fileDataChanged) {
            receiptFile.fileData = receiptFile.fileDataChanged;
          }
          delete receiptFile.fileDataChanged;
          delete receiptFile.changed;
          receiptFile.receiptId = receiptId;
          promiseArray.push(ReceiptFile.save(receiptFile, function (result) {
            vm.images.push(result);
            if (result) {
              vm.image = result;
              vm.images.push(result);
            }
          }).$promise);
        });
        vm.receiptFiles = [];
      }
      $q.all(promiseArray).then(function () {
        $scope.$emit('garanteasyApp:receiptUpdate', result);
        vm.isSaving = false;
        $state.go('receipt', null, {
          reload: true
        });
      });
    }

    function onSaveError(error) {
      vm.error = error;
      vm.isSaving = false;
      AlertService.error(error.data.message);
    }

    vm.datePickerOpenStatus.buyingDate = false;

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    vm.canShow = function (contentType) {
      return contentType != null && contentType.toLowerCase().indexOf('image') === 0 && contentType.toLowerCase().indexOf('tiff') === -1;
    };

    vm.uploadFile = function ($file, typeForProduct) {
      vm.receiptFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000 && $file.type.indexOf('image') === -1 || $file.size > 20480000) {
          $translate('garanteasyApp.receipt.error.fileSize').then(function (error) {
            AlertService.error(error);
          }, function (translationId) {
            AlertService.error(translationId);
          });
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.receiptFile.receiptId = null;
          vm.receiptFile.fileData = null;
          vm.receiptFile.contentType = null;
          vm.receiptFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.resizeifNecessary($file, function (base64Data) {
            $scope.$apply(function () {
              vm.receiptFile.fileData = base64Data;
              vm.receiptFile.fileContentType = $file.type;
              vm.receiptFile.fileName = $file.name;
              if (typeForProduct) {
                var extension = $file.name.substr($file.name.lastIndexOf('.'));
                switch (typeForProduct) {
                  case 'warranty':
                    vm.receiptFile.fileName = 'garanzia' + extension;
                    vm.receiptFile.typeForProduct = 'warranty';
                    break;
                  case 'codes':
                    vm.receiptFile.fileName = 'codici' + extension;
                    vm.receiptFile.typeForProduct = 'codes';
                    break;
                  default:
                    vm.receiptFile.typeForProduct = 'default';
                }
                addImageToProduct();
                showProductImages(typeForProduct);
              } else {
                vm.saveUpdateOnlyRecipe();
                vm.receiptFiles.push(vm.receiptFile);
                vm.receiptFile = null;
                vm.activeSlide = 2;
              }
            });
          });
        }
      }
    };

    vm.getImageTest = function (data) {
      return 'data:image/jpeg;base64,' + data;
    };

    var imagesPath = {};
    vm.getImageUrl = function (receiptFile) {
      if (!receiptFile || !receiptFile.id) {
        imagesPath[receiptFile.id] = null;
      } else if (_.isUndefined(imagesPath[receiptFile.id])) {
        imagesPath[receiptFile.id] = "api/receipt-files/content/" + receiptFile.id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
      }
      return imagesPath[receiptFile.id];

    };
    if (vm.receipt.receiptFiles != null && vm.receipt.receiptFiles.length > 0) {
      vm.receipt.receiptFiles.forEach(function (item) {
        if (item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") === 0)
          vm.images.push(item);
        else
          vm.receiptFiles.push(item);
      });
    }


    if (vm.images.length > 0) {
      vm.image = vm.images[0];
    }

    vm.deleteFile = function (receiptFile, isProduct) {
      var index;
      if (isProduct) {
        index = vm.receipt.purchasedProduct.receiptFiles.indexOf(receiptFile);
        vm.receipt.purchasedProduct.receiptFiles.splice(index, 1);
        return showProductImages(vm.selectedType);
      }
      index = vm.receiptFiles.indexOf(receiptFile);
      vm.receiptFile = {};
      vm.receiptFiles.splice(index, 1);
      vm.activeSlide = 1;
      vm.savedReceiptFile = false;
    };

    function addImageToProduct() {
      if (!vm.receipt.purchasedProduct.receiptFiles) {
        vm.receipt.purchasedProduct.receiptFiles = [];
      }
      var index = vm.receipt.purchasedProduct.receiptFiles.indexOf(vm.receiptFile);
      if (index !== -1) {
        vm.receipt.purchasedProduct.receiptFiles[index] = vm.receiptFile;
      } else {
        vm.receipt.purchasedProduct.receiptFiles.push(vm.receiptFile);
      }
      vm.receiptFile = null;
      /* vm.activeSlide = 3; */
    }

    function getImageProductUrl(type, inputProduct) {
      if (!vm.receipt.purchasedProduct.receiptFiles || !vm.receipt.purchasedProduct.receiptFiles.length) {
        return null;
      }
      var image;
      image = vm.receipt.purchasedProduct.receiptFiles.find(function (file) {
        return file.typeForProduct === type;
      });
      if (image && image.fileData && image.fileContentType) {
        return 'data:' + image.fileContentType + ';base64,' + image.fileData;
      }
      return;
    }

    function onNextClick() {
      vm.alreadySelected = false;
      vm.receipt.gift = 'N';
      if (vm.newSavedProductId) {
        vm.isNewProduct = false;
        vm.newSavedProductId = null;
      }
      vm.savePurchasedProduct();
    }

    function addPurchasedProduct(saveReceipt) {
      vm.addProduct = false;
      if (
        angular.isDefined(vm.receipt.purchasedProduct) &&
        angular.isDefined(vm.receipt.purchasedProduct.product)
      ) {
        var index = vm.receipt.purchasedProducts.indexOf(vm.receipt.purchasedProduct);
        if (index === -1) {
          if (vm.isNewProduct) {
            vm.isNewProduct = false;
            vm.newSavedProductId = vm.receipt.purchasedProduct.id;
          }
          vm.receipt.purchasedProducts.push(vm.receipt.purchasedProduct);
        } else {
          vm.receipt.purchasedProducts[index] = vm.receipt.purchasedProduct;
        }
        //TODO vm.receipt.purchasedProduct = {};
      }
      vm.receipt.numProducts = vm.receipt.purchasedProducts.length;
      if (saveReceipt) {
        save();
      } else {
        vm.addProduct = true;
        if (!(vm.receipt.purchasedProduct && vm.receipt.purchasedProduct.id && vm.getFullId(vm.receipt.purchasedProduct.id))) {
          vm.receipt.purchasedProduct = {};
        }
      }
    }

    function savePurchasedProduct(finish) {
      vm.finish = finish;
      vm.tempSavingProduct = vm.receipt.purchasedProduct.product;
      if (vm.receipt.purchasedProduct && vm.receipt.purchasedProduct.id && vm.getFullId(vm.receipt.purchasedProduct.id)) {
        console.log("IS OFFICIAL")
        if (vm.receipt.purchasedProduct.warrantyConventional) {
          saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProduct.warrantyConventional).then(function () {
            console.log("RECEIPT SAVED")
          });

        }
        if (vm.receipt.purchasedProduct.purchasedProductWarranties &&
          vm.receipt.purchasedProduct.purchasedProductWarranties.length > 0) {
          vm.receipt.purchasedProduct = {};
          if (vm.finish) {
            $state.go('receipt', null, {
              reload: true
            });
          }
          return;
        }
      }
      if (vm.lastProductToDelete) {
        vm.receipt.purchasedProduct.id = null;
        vm.lastProductToDelete = null;
      }
      vm.isSaving = true;
      if (vm.receipt.purchasedProduct && vm.receipt.purchasedProduct.product) {
        if (vm.receipt.purchasedProduct.id) {
          return addPurchasedProduct();
        }
        if (angular.isUndefined(vm.receipt.purchasedProduct.giftMakerDescription)) {
          vm.receipt.purchasedProduct.giftMakerDescription = vm.receipt.customer.legalName;
        }
        if (vm.receipt.purchasedProduct.product.id) {
          vm.receipt.purchasedProduct.allProductId = vm.receipt.purchasedProduct.product.id;
          vm.receipt.purchasedProduct.product = {};
          vm.receipt.purchasedProduct.product.id = vm.receipt.purchasedProduct.allProductId;
        }
        vm.receipt.purchasedProduct.receiptId = vm.receipt.id;
        PurchasedProduct.save(vm.receipt.purchasedProduct, onSaveSuccessProduct, onSaveError);
      } else {
        $state.go('receipt', null, {
          reload: true
        });
      }
    }

    function onSaveSuccessProduct(result) {
      vm.tempSavingProduct = null;
      var promiseArray = [];
      vm.productValid = false;
      if (!vm.productIdAssociation.has(result.id) && vm.receipt.purchasedProduct.allProductId) {
        vm.productIdAssociation.set(result.id, vm.receipt.purchasedProduct.allProductId);
      }
      vm.receipt.purchasedProduct.id = result.id;
      vm.receipt.purchasedProduct.product = result.product;
      if (result.purchasedProductWarranties) {
        vm.receipt.purchasedProduct.purchasedProductWarranties = result.purchasedProductWarranties;
      }
      if (vm.receipt.purchasedProduct.receiptFiles && vm.receipt.purchasedProduct.receiptFiles.length) {
        vm.receipt.purchasedProduct.receiptFiles.forEach(function (receiptFile) {
          receiptFile.purchasedProductId = result.id;
          if (receiptFile.fileDataChanged) {
            receiptFile.fileData = receiptFile.fileDataChanged;
          }
          delete receiptFile.fileDataChanged;
          delete receiptFile.changed;
          receiptFile.receiptId = vm.receipt.id;
          promiseArray.push(ReceiptFile.save(receiptFile, function (result) {
            vm.images.push(result);
            if (result) {
              vm.image = result;
              vm.images.push(result);
            }
          }).$promise);
        });
      }
      if (vm.receipt.purchasedProduct.warrantyConventional) {
        vm.receipt.purchasedProduct.warrantyConventional.purchasedProductId = result.id;
        vm.receipt.purchasedProduct.warrantyConventional.warranty.brands = [result.product.brand];
        promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProduct.warrantyConventional));
      }
      $q.all(promiseArray).then(function () {
        $scope.$emit('garanteasyApp:receiptUpdate', result);
        vm.isSaving = false;
        if (vm.finish) {
          $state.go('receipt', null, {
            reload: true
          });
        } else {
          vm.addPurchasedProduct();
          if (!(vm.receipt.purchasedProduct && vm.receipt.purchasedProduct.id && vm.getFullId(vm.receipt.purchasedProduct.id))) {
            vm.receipt.purchasedProduct = {};
          }
        }
      });
    }

    vm.removePurchasedProduct = function (index) {
      PurchasedProduct.delete({ id: vm.receipt.purchasedProducts[index].id }).$promise.then(function (res) {
        vm.receipt.purchasedProducts.splice(index, 1);
        vm.receipt.numProducts = vm.receipt.purchasedProducts.length;
        vm.lastProductToDelete = null;
        vm.alerts.push(
          {
            type: 'success',
            msg: "garanteasyApp.purchasedProduct.deleted",
            timeout: 20000
          });
      });
    };

    vm.getFullId = function (productId) {
      return vm.productIdAssociation.get(productId);
    }

    vm.toPreviousPurchasedProduct = function () {
      vm.addProduct = true;
      if (vm.receipt.purchasedProducts.length) {
        vm.receipt.purchasedProduct = vm.receipt.purchasedProducts[(vm.receipt.purchasedProducts.length - 1)];
      }
    };

    vm.isValidPurchasedProduct = function () {
      if (!vm.receipt.purchasedProduct || !vm.receipt.purchasedProduct.product || !vm.receipt.purchasedProduct.product.name) {
        return false;
      }
      return true;
    };


    /* vm.getShopByMerchants = function () {
      if (angular.isDefined(vm.merchant)) {
        Shop.getShopByMerchants({ merchant_id: vm.merchant.id },
          function (result) {
            vm.shops = result;
          },
          function (response) {
            if (response.status === 404) {
              vm.shops = {};
            }
          }
        );
      }
    }; */

    function getAccount() {
      Principal.identity().then(function (account) {
        vm.account = account;
        vm.isAuthenticated = Principal.isAuthenticated;
      });
    }

    function saveUpdatePurchasedProductWarranty(purchasedProductWarranty) {
      var promise = new Promise(function (resolve) {
        resolve();
      });
      purchasedProductWarranty.receiptId = vm.receipt.id;
      purchasedProductWarranty.warranty.brand
      if (!purchasedProductWarranty.warranty.id) {
        promise = Warranty.save(purchasedProductWarranty.warranty).$promise;
      } else if (purchasedProductWarranty.warranty.id && purchasedProductWarranty.warranty.code === 'CUSTOM') {
        promise = Warranty.update(purchasedProductWarranty.warranty).$promise;
      }
      if (!purchasedProductWarranty.id) {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || warranty.id;
          return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
        });
      } else {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || warranty.id;
          return PurchasedProductWarranty.update(purchasedProductWarranty).$promise;
        });
      }
      return promise.then(function (result) {
        purchasedProductWarranty = result;
        return purchasedProductWarranty;
      });
    }

    function onUpdateWarrantyNewProduct(purchasedProductWarranty, valid) {
      if (!purchasedProductWarranty) {
        return;
      }
      switch (purchasedProductWarranty.warranty.type) {
        case 'CONVENTIONAL':
          vm.receipt.purchasedProduct.warrantyConventional = Object.assign({}, purchasedProductWarranty);
          break;
        case 'SERVICE_CONTRACT':
          vm.receipt.purchasedProduct.warrantyService = Object.assign({}, purchasedProductWarranty);
          break;
        case 'INSURANCE':
          vm.receipt.purchasedProduct.warrantyInsurance = Object.assign({}, purchasedProductWarranty);
          break;
      }
    }

    $scope.loadProducts = function (searchQuery) {
      ProductLookup.query({
        query: searchQuery
      },
        function (result) {
          vm.products = result;
          angular.forEach(vm.products, function (product, key) {
            product.description = String(product.description).replace(/<[^>]+>/gm, '');
          });
          var product = vm.products.find(function (item) {
            return item.brandAndName === searchQuery.trim();
          });
          if (searchQuery && !product) {
            vm.products.unshift(transformProduct(searchQuery));
          }
        },
        function (response) {
          $log.error("Error in productlokup", response);
          AlertService.error("Error: " + response.status);
        }
      );
    };

    $scope.loadCompanies = function (searchQuery) {
      CompanyLookup.query({
        query: searchQuery
      },
        function (result) {
          $scope.companies = result;
        }
      );
    };

    vm.onSelectedCompany = function (selectedCompany) {
      $log.debug("Selected company", selectedCompany);
      BrandSearch.query({
        "query": {
          "owner.id": selectedCompany.id
        }
      }, function (result) {
        $scope.brands = result;
      });
    };

    function disableAddProduct() {
      if (vm.receipt.purchasedProduct == null || vm.receipt.purchasedProduct.product == null || vm.receipt.purchasedProduct.product.name == null ||
        (vm.receipt.purchasedProduct.product.eanCodes == null && vm.receipt.purchasedProduct.product.eanCode == null) ||
        vm.receipt.purchasedProduct.product.brand == null ||
        (vm.receipt.gift === 'Y' && vm.receipt.purchasedProduct.giftReceiverEmail == null)
      ) {
        return true;
      } else return false;
    }

    function onUpdateConventionalWarranty(purchasedProductWarranty) {
      vm.receipt.purchasedProduct.warrantyConventional = Object.assign({}, purchasedProductWarranty);
    }

    function showProductImages(type) {
      vm.selectedType = type;
      vm.selectedReceiptFiles = [];
      if (!vm.receipt.purchasedProduct.receiptFiles) {
        return;
      }
      vm.selectedReceiptFiles = vm.receipt.purchasedProduct.receiptFiles.filter(function (image) {
        return image.typeForProduct == type;
      });
      vm.activeSlide = 4;
    }

    function transformProduct(new_value) {
      var new_product = {};
      new_product.name = new_value;
      new_product.brandAndName = new_value;

      return new_product;
    }


    function addOnBlurProduct($select) {
      $select.searchInput.on('blur', function () {
        $timeout(function () {
          if ($select.search && !$select.selected && (!vm.receipt.purchasedProduct.product || !vm.receipt.purchasedProduct.product.name)
            || (vm.receipt.purchasedProduct.product && !vm.receipt.purchasedProduct.product.idxId && $select.search.trim() !== vm.receipt.purchasedProduct.product.name)) {
            var product = vm.products.find(function (item) {
              return item.brandAndName === $select.search.trim();
            });
            vm.receipt.purchasedProduct.product = product;
          }
          if (vm.tempSavingProduct) {
            vm.receipt.purchasedProduct.product = vm.tempSavingProduct;
          }
        }, 250);
      });

    }

    function onChangeProduct($select) {
      var deleteIndex = 0;
      if ($select.search && !vm.receipt.purchasedProduct.product) {
        $select.search = '';
        vm.productValid = false;
      }
      if (!vm.alreadySelected) {
        vm.alreadySelected = true;
      } else {
        vm.receipt.purchasedProducts.forEach(function (val) {
          if (val.id === vm.newSavedProductId) {
            vm.lastProductToDelete = val;
            return;
          }
          deleteIndex++;
        })

      }
      if (vm.lastProductToDelete && vm.lastProductToDelete.id) {
        var tempProd = vm.receipt.purchasedProduct.product;
        vm.receipt.purchasedProduct = {};
        vm.receipt.purchasedProduct.product = tempProd;
        vm.removePurchasedProduct(deleteIndex);
      }
      if (vm.receipt.purchasedProduct.product.idxId) {
        vm.isNewProduct = true;
        savePurchasedProduct();
      }

    }

    function onKeyUpPorduct(search) {
      if (search && search.length) {
        vm.productValid = true;
      } else if (!search && !vm.receipt.purchasedProduct.product) {
        vm.productValid = false;
      }
    }

    function onChangeReceiptType() {
      if (vm.receipt.receiptType === 'INVOICE') {
        vm.receipt.profile = 'BUSINESS';
      } else if (vm.receipt.receiptType === 'RECEIPT') {
        vm.receipt.profile = 'PERSONAL';
      }
    }

  }
})();
