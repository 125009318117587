'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .factory('ExtensionService', ExtensionService);

  ExtensionService.$inject = ['$http', '$log'];

  function ExtensionService($http, $log) {
    var service = {
      linkWarrantyToPurchasedProduct: linkWarrantyToPurchasedProduct
    };

    return service;

    function linkWarrantyToPurchasedProduct(warrantyPurchasedProductId, purchasedProductId) {
      var uri = '/api/purchased-product-warranties/linkWarrantyToPurchasedProduct';
      var request = { warrantyPurchasedProductId: parseInt(warrantyPurchasedProductId), purchasedProductId: parseInt(purchasedProductId) };
      return $http.put(uri, request);
    }


  }
})();
