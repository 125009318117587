(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('MerchantPrivacyPolicyDisapproveController',MerchantPrivacyPolicyDisapproveController);

    MerchantPrivacyPolicyDisapproveController.$inject = ['$state', '$uibModalInstance', 'entity', 'MerchantPrivacyPolicy', 'Account', 'Principal', 'Auth'];

    function MerchantPrivacyPolicyDisapproveController($state, $uibModalInstance, entity, MerchantPrivacyPolicy, Account, Principal, Auth) {
        var vm = this;

        vm.merchantPrivacyPolicy = entity;
        vm.clear = clear;
        vm.confirmDisapprove = confirmDisapprove;
        checkAccess();
        function checkAccess() {
            Principal.identity().then(function(account) {
                vm.account = account; });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDisapprove (mpp) {
            if(!mpp.approved){
                mpp.approved = true;
            }
            else {mpp.approved = false;}
            MerchantPrivacyPolicy.update(mpp,
                function () {
                    $uibModalInstance.close(true);
                    Auth.logout();
                    $state.go('home', null, { reload: true });
                });
        }

    }
})();
