(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyClaimRuleDetailController', WarrantyClaimRuleDetailController);

    WarrantyClaimRuleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'WarrantyClaimRule', 'previousState', 'Principal', 'entity', 'Product', 'User', 'Brand','DataUtils', 'ProductResources', 'Maintenance', 'DEFAULT_BARCODE_ENCODING'];

    function WarrantyClaimRuleDetailController($scope, $rootScope, $stateParams, WarrantyClaimRule, previousState, Principal, entity, Product, User, Brand, DataUtils, ProductResources, Maintenance, DEFAULT_BARCODE_ENCODING) {
        var vm = this;
        vm.warrantyClaimRule = entity;
        if($stateParams.from) {
            $scope.from = $stateParams.from
        }
        if($stateParams.from2nd) {
            $scope.from2nd = $stateParams.from2nd
        }

        vm.isOwner=null;
        vm.isAdmin=null;
        checkAccess();

        function checkAccess() {
            Principal.identity().then(function(account) {
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                    	vm.isAdmin=true;
                    } else {
                    	vm.isAdmin=false;
                    }
                });
            });
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:warrantyClaimRuleUpdate', function(event, result) {
            vm.warrantyClaimRule = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
