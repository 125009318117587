(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Product', 'Brand', 'BrandLookup','DataUtils','Upload']; 
    
    function ProductDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Product, Brand, BrandLookup, DataUtils) {
        var vm = this;

        vm.product = entity;
        vm.clear = clear;
        vm.save = save;
        vm.brands = Brand.query({filter: 'product-is-null'});
        $q.all([vm.product.$promise, vm.brands.$promise]).then(function() {
            if (!vm.product.brand || !vm.product.brand.id) {
                return $q.reject();
            }
            return Brand.get({id : vm.product.brand.id}).$promise;
        }).then(function(brand) {
            vm.brands.push(brand);
        });
        vm.isValidInput = isValidInput;
        vm.hasBrand = null;
        vm.validBrand = null;

        $scope.resetBrand = function() {
           	vm.product.brand = null;
        }

        $scope.loadBrands = function(searchQuery) {
            if (searchQuery){
            	BrandLookup.query({query: searchQuery}, function(result) {
                    $scope.brands = result;
                }, function(response) {
                    if(response.status === 404) {
                        $scope.brands = Brand.query({isActive: true});
                    }
                });
            } else {
                $scope.brands = Brand.query({isActive: true});
            }
        };        

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        vm.uploadFile = function ($file) {
        	vm.productResource = [];
            vm.clear = clear;
            vm.save = save;
            vm.byteSize = DataUtils.byteSize;
            vm.openFile = DataUtils.openFile;
            vm.fileTooLarge = false;
                if ($file && $file.$error === 'pattern') {
                    return;
                }
                if ($file) {
                	if($file.size>5120000) {
                        vm.fileTooLarge = true;
                        // Reset data in case there was a previous image selected
                        vm.productResource.description = null;
                    	vm.productResource.productId = null;
                    	vm.productResource.fileData = null;
                    	vm.productResource.contentType = null;
                	} else {
                        vm.fileTooLarge = false;
    	                DataUtils.toBase64($file, function(base64Data) {
    	                    $scope.$apply(function() {
    	                    	vm.productResource.description = $file.name;
    	                    	vm.productResource.productId = vm.product.id;
    	                    	vm.productResource.fileData = base64Data;
    	                    	vm.productResource.contentType = $file.type;
    	                    });
    	                });
                	}
                }
            
        	
        	
//            Upload.upload({
//                url: 'upload/url',
//                data: {file: file, 'username': $scope.username}
//            }).then(function (resp) {
//                console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
//            }, function (resp) {
//                console.log('Error status: ' + resp.status);
//            }, function (evt) {
//                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
//                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
//            });
        };

        
        function fileUploadSuccess(ret){
            vm.product.image = ret.data.data[0];
        }
        
        function fileUploadError(ret){
            this.onAjaxError(ret);
        }
        
        function deleteImage(){
        	vm.product.image = null;
            //this.$scope.$apply();
        }

        function save () {
            vm.isSaving = true;
            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        // Constraints beyond the ones in the form
        function isValidInput () {
            vm.hasBrand = vm.product.brand != null;
            vm.validBrand = vm.hasBrand && vm.product.brand.name != null && vm.product.brand.name.trim().length > 0;

        	return vm.validBrand;
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
