(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .directive('backImg', function () {
      return function (scope, element, attrs) {
        var defaultImg = '/content/images/empty-product-placeholder.png';
        if (attrs.backImgDefault === 'shop') {
          defaultImg = '/content/images/empty-shop-placeholder.png';
        } else if (attrs.backImgDefault === 'warranty') {
          defaultImg = '/content/images/icone_new/document/Scontrino.png';
        }
        var url = attrs.backImg ? attrs.backImg : defaultImg;
        element.css({
          'background-image': 'url(' + url + ')'
        });
      };
    })
    .controller('ReceiptController', ReceiptController);

  ReceiptController.$inject = ['$scope', '$state', 'DataUtils', 'Receipt', 'Principal', 'ReceiptSearch', 'ParseLinks', 'AlertService', '$stateParams', 'Searchbar', '$rootScope', '$translate', 'ReceiptFile', 'AuthServerProvider', 'pagingParams', '$localStorage', 'CmsShopService'];

  function ReceiptController($scope, $state, DataUtils, Receipt, Principal, ReceiptSearch, ParseLinks, AlertService, $stateParams, Searchbar, $rootScope, $translate, ReceiptFile, AuthServerProvider, pagingParams, $localStorage, CmsShopService) {
    var vm = this;
    vm.id = Date.now();
    $rootScope.receiptsChange = false;
    $rootScope.querySearch = '';
    vm.name = 'receipt.controller';
    $scope.stateData.vm = vm;
    $rootScope.showSearchBar = true;
    // Looks as the follow filter is unsed
    // var filterWarranty = $stateParams.filterWarranty;
    vm.receipts = [];
    vm.loadPage = loadPage;
    vm.page = pagingParams.page;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.gridView = $rootScope.gridView;
    vm.links = {
      last: 0
    };
    var imagesPath = {};

    vm.isLoading = false;
    vm.reset = reset;
    vm.clear = clear;
    vm.loadAll = loadAll;
    vm.search = search;
    vm.openFile = DataUtils.openFile;
    vm.byteSize = DataUtils.byteSize;
    vm.abbreviate = DataUtils.abbreviate;
    vm.images = [];
    vm.documents = [];
    vm.currentUserId = null;
    vm.isAdmin = null;

    vm.validWarranties = 0;
    vm.expiredWarranties = 0;
    vm.transition = transition;
    vm.setViewType = setViewType;

    vm.getValidReceiptsFistCall = true;
    vm.getImageUrl = function (receipt) {
      if (!receipt || !receipt.id) {
        imagesPath[receipt.id] = null;
      } else if (angular.isUndefined(imagesPath[receipt.id])) {
        imagesPath[receipt.id] = null;
        if (receipt.receiptFiles.length > 0) {
          var image = _.find(receipt.receiptFiles, function (o) { return o.fileContentType.startsWith('image/') });
          if (image) {
            imagesPath[receipt.id] = "api/receipt-files/content/" + image.id + "?width=-&height=150&token=" + AuthServerProvider.getToken();
          }
        } else {
          // Se manca l'immagine della ricevuta mostro quella del prodotto.
          if (receipt.purchasedProducts) {
            receipt.purchasedProducts.forEach(function (purchasedProduct) {
              if (purchasedProduct.product && purchasedProduct.product.images[0]) {
                imagesPath[receipt.id] = purchasedProduct.product.images[0].value;
              }
            });
          }
        }
      }
      return imagesPath[receipt.id];

    };
    checkGrid();
    function checkGrid() {
      if ($localStorage.receiptView) {
        vm.setViewType($localStorage.receiptView);
      }
    }

    function setViewType(type) {
      if (type === "grid") {
        $localStorage.receiptView = "grid";
        vm.gridView = true;
      }
      if (type === "list") {
        $localStorage.receiptView = "list";
        vm.gridView = false;
      }
    }

    vm.getValidReceipts = function () {
      if (!vm.getValidReceiptsFistCall) {
        return;
      }
      Receipt.getValid({}, onSuccess, onError);
      vm.valid = vm.validReceipt;
      function onSuccess(data) {
        vm.validWarranties = data.valid;
        vm.expiredWarranties = data.expired;
        vm.expiringWarranties = data.expiring;
      }
      function onError(error) {
        AlertService.error(error.data.message);
      }

    }

    vm.getValidReceipts();




    vm.document = function (receipt) {
      if (receipt.receiptFiles.length > 0) {
        if (receipt.receiptFiles[0].fileContentType && receipt.receiptFiles[0].fileContentType.toLowerCase().indexOf("image") === 0) {
          return receipt.receiptFiles[0];
        }
        else {
          return receipt.receiptFiles[0];
        }
      }
    }

    vm.checkAccess = checkAccess;
    checkAccess();



    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.currentUserId = account.id;
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    vm.totalNumExpired = 0;
    vm.totalNumValid = 0;
    vm.totalNumExpiring = 0;
    vm.oldSearch = undefined;

    setTimeout(function () {
      if (!$scope.globalSearchChangeHandled) {
        loadAll();
      }
    }, 500);
    //loadAll();
    function loadAll(stringSearch) {
      if (vm.isLoading) return;
      $scope.globalSearchChangeHandled = true;
      vm.isLoading = true;
      vm.currentSearch = stringSearch || vm.oldSearch;
      if (vm.currentSearch) {
        ReceiptSearch.query({
          query: vm.currentSearch,
          page: vm.page,
          size: 20,
          sort: sort()
        }, onSuccess, onError);
      } else {
        Receipt.query({
          page: vm.page,
          size: 20,
          sort: sort()
        }, onSuccess, onError);
      }
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        if (vm.predicate !== 'id') {
          result.push('id');
        }
        return result;
      }
      function onSuccess(data, headers) {
        vm.links = ParseLinks.parse(headers('link'));
        vm.totalItems = headers('X-Total-Count');
        if (data.total === 1) {
          Receipt.query({}, redirectToDetail, null);
        }
        for (var i = 0; i < data.length; i++) {
          if (data[i].totalWarranties) {
            vm.totalNumExpired += data[i].totalWarranties.numExpired;
            vm.totalNumValid += data[i].totalWarranties.numValid;
            vm.totalNumExpiring += data[i].totalWarranties.numExpiring;
          }
          // if (filterWarranty) {
          //   if (filterWarranty === 'valid' && data[i].totalWarranties.numValid > 0) {
          //     vm.receipts.push(data[i]);
          //   }
          //   else if (filterWarranty === 'expired' && data[i].totalWarranties.numExpired > 0) {
          //     vm.receipts.push(data[i]);
          //   }
          //   else if (filterWarranty === 'expiring' && data[i].totalWarranties.numExpiring > 0) {
          //     vm.receipts.push(data[i]);
          //   }
          // }
          // else {
          //   vm.receipts.push(data[i]);
          // }
          vm.receipts.push(data[i]);
        }
        loadCmsShopData(data);
        vm.isLoading = false;
      }



      function onError(error) {
        vm.isLoading = false;
        AlertService.error(error.message);
      }
    }

    function loadCmsShopData(loadedData) {

      var shopMap = new Map();
      var queryString = "";
      for (var i = 0; i < loadedData.length; i++) {
        if (loadedData[i] && loadedData[i].shop && loadedData[i].shop.cmsReference) {
          var shopId = loadedData[i].shop.id;
          var cmsReference = loadedData[i].shop.cmsReference;
          shopMap.set(shopId, cmsReference);
          queryString += cmsReference + ",";
        }
      }

      if (queryString.length != 0) {
        queryString = queryString.substring(0, queryString.length - 1);
        CmsShopService.getShopData(queryString).then(function (response) {
          var resultMap = new Map();
          if (response) {
            for (var i = 0; i < response.length; i++) {
              if (response[i].title) {
                resultMap.set(response[i].uuid, response[i]);
              }
            }
            for (var i = 0; i < loadedData.length; i++) {
              if (loadedData[i] && loadedData[i].shop && loadedData[i].shop.id) {
                var shopCmsUuid = loadedData[i].shop.cmsReference;
                if (resultMap.has(shopCmsUuid)) {

                  loadedData[i].shop.cmsShopData = resultMap.get(shopCmsUuid);
                }
              }
            }
          }
        });
      }
    }

    function redirectToDetail(data, headers) {
      if (data.total === 1) {
        var id = data[0].id;
        $state.go('receipt.detail', { id: id }, { reload: true });
      }
    }

    function loadPage(page) {
      vm.page = page;
      vm.loadAll();
    }

    function transition() {
      reset();
      vm.loadAll();
    }

    function reset() {
      vm.page = 0;
      vm.receipts = [];
    }

    function clear() {
      vm.receipts = [];
      vm.links = null;
      vm.page = 0;
      vm.predicate = 'id';
      vm.reverse = true;
      vm.searchQuery = null;
      vm.currentSearch = null;
      vm.transition();
    }

    function search(searchQuery) {
      if (!searchQuery) {
        return vm.clear();
      }
      vm.receipts = [];
      vm.links = null;
      vm.page = 0;
      vm.predicate = '_score';
      vm.reverse = false;
      vm.currentSearch = searchQuery;
      vm.loadAll();
    }

    $scope.$on('$destroy', function (event, next, current) {
      $rootScope.querySearch = '';
      $rootScope.showSearchBar = false;
      // $rootScope.inputSearch = false;
    });

    $scope.$on('$init', function (event, next, current) {
      $rootScope.showSearchBar = true;
    });

    vm.canShow = function (contentType) {
      return contentType != null && contentType.toLowerCase().indexOf('image') == 0 && contentType.toLowerCase().indexOf('tiff') == -1;
    }

    vm.initReceiptDetail = function (receipt) {
      if (receipt.receiptFiles.length > 0) {
        receipt.receiptFiles.forEach(function (item) {
          if (item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") == 0)
            vm.images.push(item);
          else
            vm.documents.push(item);
        })
      }
      if (vm.images.length > 0) {
        vm.image = vm.images[0];
      }
    }

  }
})();
