(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .filter('removeHTMLTags', removeHTMLTags);

  function removeHTMLTags() {
    return removeHTMLTagsFilter;

    function removeHTMLTagsFilter(text) {
      return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    }
  }
})();
