(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ShopDetailController', ShopDetailController);

  ShopDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Shop', 'Merchant', 'User', 'Address', 'CmsService', 'CMS_URI'];

  function ShopDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Shop, Merchant, User, Address, CmsService, CMS_URI) {
    var vm = this;

    vm.shop = entity;
    $rootScope.shop = entity;

    vm.isOwner = null;
    vm.isAdmin = null;
    $scope.stateData.vm = vm;
    checkAccess();
    vm.save = save;
    vm.productBrandLogo = "";
    loadBrandLogo();

    function loadBrandLogo() {
      var brandCode = vm.shop.signboard.code
      if (brandCode) {
        CmsService.getBrandByCode(brandCode).then(function (responseBrand) {
          vm.productBrandLogo = CMS_URI + responseBrand[0].brand_image_full;
        });
      }
    }

    function save() {
      vm.isSaving = true;
      Shop.update(vm.shop, onSaveSuccess, onSaveError);
    }

    function onSaveSuccess(result) {
      // $scope.$emit('garanteasyApp:shopUpdate', result);
      vm.isSaving = false;
      vm.isEdit3 = false;
    }

    function onSaveError() {
      vm.isSaving = false;
      vm.isEdit3 = true;
    }

    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.isOwner = vm.shop.createdBy.id == account.id;
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    if ($stateParams.from) {
      $scope.from = $stateParams.from
    }

    $rootScope.shopUpdate = function (shop) {
      if (shop) {
        vm.shop = shop;
      }
    };

    var unsubscribe = $rootScope.$on('garanteasyApp:shopUpdate', function (event, result) {
      vm.shop = result;
    });
    $scope.$on('$destroy', unsubscribe);
  }
})();
