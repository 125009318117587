'use strict';
(function () {
  angular
    .module('garanteasyApp')
    .factory('Customer', Customer);

  Customer.$inject = ['$resource', 'DateUtils'];

  function Customer($resource, DateUtils) {
    var resourceUrl = 'api/customers/:id';

    return $resource(resourceUrl, {}, {
      'query': {
        method: 'GET',
        isArray: true,
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
            if (Array.isArray(data)) {
              data.forEach(function (customer) {
                customer.birthDate = DateUtils.convertLocalDateFromServer(customer.birthDate);
              });
            }
          }
          return data;
        }
      },

      'getAllCustomersFiltered': {
        method: 'GET',
        url: 'api/customersFiltered',
        isArray: true,
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
            if (Array.isArray(data)) {
              data.forEach(function (customer) {
                customer.birthDate = DateUtils.convertLocalDateFromServer(customer.birthDate);
              });
            }
          }
          return data;
        }
      },

      'get': {
        method: 'GET',
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
            data.birthDate = DateUtils.convertLocalDateFromServer(data.birthDate);
          }
          return data;
        }
      },
      'update': {
        method: 'PUT',
        transformRequest: function (data) {
          var dataToSend = angular.copy(data);
          dataToSend.birthDate = DateUtils.convertLocalDateToServer(dataToSend.birthDate);
          return angular.toJson(dataToSend);
        }
      },
      'save': {
        method: 'POST',
        transformRequest: function (data) {
          var dataToSend = angular.copy(data);
          dataToSend.birthDate = DateUtils.convertLocalDateToServer(dataToSend.birthDate);
          return angular.toJson(dataToSend);
        }
      }
    });
  }
})();
