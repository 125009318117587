(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyClaimController', WarrantyClaimController);

    WarrantyClaimController.$inject = ['$scope', '$rootScope', '$state', 'WarrantyClaim', 'WarrantyClaimSearch', 'DataUtils', 'pagingParams', 'AlertService', 'ParseLinks', '$localStorage'];

    function WarrantyClaimController($scope, $rootScope, $state, WarrantyClaim, WarrantyClaimSearch, DataUtils, pagingParams, AlertService, ParseLinks, $localStorage) {
        var vm = this;
        vm.warrantyClaims = [];
        vm.gridView = $rootScope.gridView;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.abbreviate = DataUtils.abbreviate;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.setViewType = setViewType;
        vm.links = {
            last: 0
        };
        setTimeout(function () {
            if (!$scope.globalSearchChangeHandled) {
                loadAll();
            }
        }, 500);

        checkGrid();
        function checkGrid() {
            if ($localStorage.warrantyClaimView) {
                vm.setViewType($localStorage.purchasedProductView);
            }
        }

        function setViewType(type) {
            if (type === "grid") {
                $localStorage.warrantyClaimView = "grid";
                vm.gridView = true;
            }
            if (type === "list") {
                $localStorage.warrantyClaimView = "list";
                vm.gridView = false;
            }
        }

        function loadAll() {
            if (vm.currentSearch) {
                WarrantyClaimSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                WarrantyClaim.query({
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }
        }

        function search() {
            return vm.loadAll();
        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            // vm.links = ParseLinks.parse(headers('link'));
            // vm.totalItems = headers('X-Total-Count');
            vm.warrantyClaims = data;
            vm.isLoading = false;
        }

        function onError(error) {
            vm.isLoading = false;
            AlertService.error(error.message ? error.message : error.data.message);
        }
    }
})();
