(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('purchased-product', {
        parent: 'entity',
        url: '/purchased-product?page&sort&search&documents&shops&products&warrenties&s',
        ncyBreadcrumb: {
          label: "{{'global.menu.products' | translate}}"
        },
        data: {
          authorities: ['ROLE_USER'],
          pageTitle: 'garanteasyApp.purchasedProduct.home.title'
        },
        views: {
          'sidebar@': {
            templateUrl: 'app/entities/purchased-product/sidebar-purchased-products.html'
          },
          'content@': {
            templateUrl: 'app/entities/purchased-product/purchased-products.html',
            controller: 'PurchasedProductController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'receipt.buyingDate,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('purchasedProduct');
            $translatePartialLoader.addPart('receipt');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('mandatoryFields');
            return $translate.refresh();
          }]
        }
      })
      .state('purchased-product-gift', {
        parent: 'entity',
        url: '/purchased-product-gift?page&sort&search&documents&shops&products&warrenties&s',
        ncyBreadcrumb: {
          label: "{{'global.menu.products' | translate}}"
        },
        data: {
          authorities: ['ROLE_USER'],
          pageTitle: 'garanteasyApp.purchasedProduct.home.title'
        },
        views: {
          'sidebar@': {
            templateUrl: 'app/entities/purchased-product/sidebar-purchased-products.html'
          },
          'content@': {
            templateUrl: 'app/entities/purchased-product/purchased-products-gift.html',
            controller: 'PurchasedProductController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'receipt.buyingDate,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('purchasedProduct');
            $translatePartialLoader.addPart('receipt');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('mandatoryFields');
            return $translate.refresh();
          }]
        }
      })
      .state('purchased-product-extension', {
        parent: 'entity',
        url: '/purchased-product-extension/{extensionId}?page&sort&search&documents&shops&products&warrenties&s',
        ncyBreadcrumb: {
          label: "{{'global.menu.products' | translate}}"
        },
        data: {
          authorities: ['ROLE_USER'],
          pageTitle: 'garanteasyApp.purchasedProduct.home.title'
        },
        views: {
          'sidebar@': {
            templateUrl: 'app/entities/purchased-product/sidebar-purchased-products.html'
          },
          'content@': {
            templateUrl: 'app/entities/purchased-product/purchased-products-extension.html',
            controller: 'PurchasedProductController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'receipt.buyingDate,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('purchasedProduct');
            $translatePartialLoader.addPart('receipt');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('mandatoryFields');
            return $translate.refresh();
          }]
        }
      })
      .state('purchased-product.detail', {
        parent: 'entity',
        url: '/purchasedProduct/{id}/warranty/{warrantyId}?from&receiptId',
        ncyBreadcrumb: {
          label: "{{'garanteasyApp.purchasedProduct.detail.title' | translate}}",
          parent: function ($scope) {
            return $scope.from || 'purchased-product';
          }
        },
        data: {
          authorities: ['ROLE_USER'],
          pageTitle: 'garanteasyApp.purchasedProduct.detail.title'
        },
        views: {
          'sidebar@': {
            templateUrl: 'app/entities/purchased-product/sidebar-purchased-product-detail.html',
            controller: 'PurchasedProductDetailController',
            controllerAs: 'vm'
          },
          'content@': {
            templateUrl: 'app/entities/purchased-product/purchased-product-detail.html',
            controller: 'PurchasedProductDetailController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {

          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('purchasedProduct');
            $translatePartialLoader.addPart('receipt');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('warrantyClaim');
            $translatePartialLoader.addPart('warrantyClaimStatus');
            $translatePartialLoader.addPart('timeUnit');
            $translatePartialLoader.addPart('product');
            $translatePartialLoader.addPart('shop');
            $translatePartialLoader.addPart('address');
            $translatePartialLoader.addPart('merchant');
            $translatePartialLoader.addPart('paymentType');
            $translatePartialLoader.addPart('customer');
            $translatePartialLoader.addPart('receiptType');
            $translatePartialLoader.addPart('brand');
            $translatePartialLoader.addPart('dashboard');
            $translatePartialLoader.addPart('mandatoryFields');
            $translatePartialLoader.addPart('purchasedProductWarranty');
            $translatePartialLoader.addPart('card');
            $translatePartialLoader.addPart('gift');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }],
          entity: ['$stateParams', 'PurchasedProduct', function ($stateParams, PurchasedProduct) {
            return PurchasedProduct.get({ id: $stateParams.id }).$promise;
          }],
          previousState: ["$state", function ($state) {
            var currentStateData = {
              name: $state.current.name || 'purchased-product',
              params: $state.params,
              url: $state.href($state.current.name, $state.params)
            };
            return currentStateData;
          }]
        }
      })
      .state('purchased-product.detail.edit', {
        parent: 'purchased-product.detail',
        url: '/detail/edit',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product/purchased-product-dialog.html',
            controller: 'PurchasedProductDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: ['PurchasedProduct', function (PurchasedProduct) {
                return PurchasedProduct.get({ id: $stateParams.id }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('^', {}, { reload: false });
          }, function () {
            $state.go('^');
          });
        }]
      })


      .state('purchased-product.new', {
        parent: 'purchased-product',
        url: '/new',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product/purchased-product-dialog.html',
            controller: 'PurchasedProductDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: function () {
                return {
                  serialNumber: null,
                  deliveryDate: null,
                  id: null
                };
              }
            }
          }).result.then(function () {
            $state.go('purchased-product', null, { reload: true });
          }, function () {
            $state.go('purchased-product');
          });
        }]
      })
      .state('purchased-product-new-page', {
        parent: 'entity',
        url: '/addPurchasedProductPage',
        data: {
          authorities: ['ROLE_USER']
        },
        views: {
          'sidebar@': {
            templateUrl: 'app/entities/receipt/sidebar-receipts.html'
          },
          'content@': {
            templateUrl: 'app/entities/purchased-product/purchased-product-new-page.html',
            controller: 'PurchasedProductNewPageController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          entity: function () {
            return {
              serialNumber: null,
              giftReceiverEmail: null,
              giftReceiverDescription: null,
              deliveryDate: null,
              id: null
            };
          },
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('purchasedProduct');
            $translatePartialLoader.addPart('product');
            $translatePartialLoader.addPart('shop');
            $translatePartialLoader.addPart('receipt');
            $translatePartialLoader.addPart('paymentType');
            $translatePartialLoader.addPart('dataSource');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('receiptType');
            $translatePartialLoader.addPart('address');
            $translatePartialLoader.addPart('timeUnit');
            $translatePartialLoader.addPart('mandatoryFields');
            return $translate.refresh();
          }]
        }
      })
      .state('purchased-product.detailNew', {
        parent: 'purchased-product.detail',
        url: '/new',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product/purchased-product-dialog.html',
            controller: 'PurchasedProductDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: function () {
                return {
                  serialNumber: null,
                  deliveryDate: null,
                  id: null
                };
              }
            }
          }).result.then(function () {
            $state.go('^', null, { reload: true });
          }, function () {
            $state.go('^');
          });
        }]
      })

      .state('purchased-product.delete', {
        parent: 'purchased-product.detail',
        url: '/{id}/delete',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product/purchased-product-delete-dialog.html',
            controller: 'PurchasedProductDeleteController',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
              entity: ['PurchasedProduct', function (PurchasedProduct) {
                return PurchasedProduct.get({ id: $stateParams.id }).$promise;
              }]
            }
          }).result.then(function (arg) {
            if ($state.params.receiptId == undefined)
              $state.go('purchased-product', null, { reload: true });
            else
              $state.go('receipt.detail', { id: $state.params.receiptId }, { reload: false, location: true, relative: false, inherit: false });
          }, function () {
            $state.go('^');
          });
        }]
      })
      .state('purchased-product.warranty-new', {
        parent: 'purchased-product.detail',
        url: '/{id}/addWarranty',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty/warranty-dialog.html',
            controller: 'WarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: function () {
                return {
                  code: 'CUSTOM',
                  description: null,
                  reference: null,
                  appliesFrom: null,
                  appliesTo: null,
                  legal: false,
                  validity: null,
                  validityUnit: 'DAYS',
                  official: 0,
                  id: null,
                  purchasedProductId: $stateParams.id
                };
              }
            }
          }).result.then(function () {
            $state.go('purchased-product.detail', null, { reload: true });
          }, function () {
            $state.go('purchased-product.detail');
          });
        }]
      })
      .state('purchased-product.warranty-select', {
        parent: 'purchased-product.detail',
        url: '/{id}/selectWarranty',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty/warranty-dialog-select.html',

            controller: 'PurchasedProductWarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('purchasedProduct');
                $translatePartialLoader.addPart('warranty');
                return $translate.refresh();
              }],
              entity: function () {
                return {
                  expirationDate: null,
                  notifiedApplication: 0,
                  notifiedExpiration: 0,
                  notifiedClaimExpiration: 0,
                  purchasedProductId: $stateParams.id,
                  warrantyId: null,
                  id: null
                };
              }
            }
          }).result.then(function () {
            $state.go('purchased-product.detail', null, { reload: true });
          }, function () {
            $state.go('purchased-product.detail');
          });
        }]
      })
      .state('purchased-product.warranty-edit', {
        parent: 'purchased-product.detail',
        url: '/{ppId}/{wId}/editWarranty',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty/warranty-dialog.html',
            controller: 'WarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: ['Warranty', function (Warranty) {
                return Warranty.get({ id: $stateParams.wId }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('purchased-product.detail', null, { reload: true });
          }, function () {
            $state.go('purchased-product.detail');
          });
        }]
      })
      .state('purchased-product.warranty-delete', {
        parent: 'purchased-product.detail',
        url: '/{id}/deleteWarranty/{ppwId}',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product-warranty/purchased-product-warranty-delete-dialog.html',
            controller: 'PurchasedProductWarrantyDeleteController',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
              entity: ['PurchasedProductWarranty', function (PurchasedProductWarranty) {
                return PurchasedProductWarranty.get({ id: $stateParams.ppwId }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('purchased-product.detail', null, { reload: true });
          }, function () {
            $state.go('purchased-product.detail');
          });
        }]
      })
      /*.state('purchased-product.warranty-claim-new', {
parent: 'purchased-product.detail',
url: '/{id}/addWarrantyClaim',
ncyBreadcrumb: {
skip: true // Never display this state in breadcrumb.
},
data: {
    authorities: ['ROLE_USERPLUS']
},
onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    $uibModal.open({
        templateUrl: 'app/entities/warranty-claim/warranty-claim-dialog.html',
        controller: 'WarrantyClaimDialogController',
        controllerAs: 'vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('warrantyClaimStatus');
                return $translate.refresh();
            }],
            entity: function () {
                return {
                    requestDate: new Date(),
                    annotations: null,
                    status: 'WAITING',
                    closingDate: null,
                    id: null,
                    notifiedShop: 0,
                  notifiedGaranteasy: 0,
                    purchasedProductId: $stateParams.id
                };
            }
        }
    }).result.then(function(result) {
        $state.go('warranty-claim.detail', {"id": result.id}, { reload: true });
    }, function() {
        $state.go('purchased-product.detail');
    });
}]
})*/
      .state('purchased-product.warranty-claim-new', {
        parent: 'purchased-product.detail',
        url: '/addWarrantyClaim',
        params: { ppId: null, listProducts: null },
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USERPLUS']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty-claim/warranty-claim-dialog.html',
            controller: 'WarrantyClaimDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('warrantyClaimStatus');
                return $translate.refresh();
              }],
              entity: function () {
                return {
                  requestDate: new Date(),
                  annotations: null,
                  status: 'WAITING',
                  closingDate: null,
                  id: null,
                  notifiedShop: 0,
                  notifiedGaranteasy: 0,
                  purchasedProductId: $stateParams.id
                };
              }
            }
          }).result.then(function (result) {
            $state.go('purchased-product', { "id": result.id }, { reload: true });
          }, function () {
            $state.go('purchased-product.detail');
          });
        }]
      })

      /*.state('purchased-product.warranty-claim-edit', {
        parent: 'purchased-product.detail',
        url: '/{id}/editWarrantyClaim/{claimId}',
  ncyBreadcrumb: {
    skip: true // Never display this state in breadcrumb.
  },
        data: {
            authorities: ['ROLE_USERPLUS']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/warranty-claim/warranty-claim-dialog.html',
                controller: 'WarrantyClaimDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['WarrantyClaim', function(WarrantyClaim) {
                        return WarrantyClaim.get({id : $stateParams.claimId}).$promise;
                    }]
                }
            }).result.then(function() {
                $state.go('purchased-product.detail', null, { reload: true });
            }, function() {
                $state.go('purchased-product.detail');
            });
        }]
    })*/
      .state('purchased-product.warranty-claim-edit', {
        parent: 'purchased-product.detail',
        url: '/{id}/editWarrantyClaim/{claimId}',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USERPLUS']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty-claim/warranty-claim-dialog.html',
            controller: 'WarrantyClaimDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: ['WarrantyClaim', function (WarrantyClaim) {
                return WarrantyClaim.get({ id: $stateParams.claimId }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('purchased-product.detail', null, { reload: true });
          }, function () {
            $state.go('purchased-product.detail');
          });
        }]
      })
      /*.state('purchased-product.warranty-claim-delete', {
parent: 'purchased-product.detail',
url: '/{id}/deleteWarrantyClaim/{claimId}',
ncyBreadcrumb: {
skip: true // Never display this state in breadcrumb.
},
data: {
    authorities: ['ROLE_USERPLUS']
},
onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    $uibModal.open({
        templateUrl: 'app/entities/warranty-claim/warranty-claim-delete-dialog.html',
        controller: 'WarrantyClaimDeleteController',
        controllerAs: 'vm',
        size: 'md',
        resolve: {
            entity: ['WarrantyClaim', function(WarrantyClaim) {
                return WarrantyClaim.get({id : $stateParams.claimId}).$promise;
            }]
        }
    }).result.then(function() {
        $state.go('purchased-product.detail', null, { reload: true });
    }, function() {
        $state.go('purchased-product.detail');
    });
}]
})*/
      .state('purchased-product.warranty-claim-delete', {
        parent: 'purchased-product.detail',
        url: '/{id}/deleteWarrantyClaim/{claimId}',
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ['ROLE_USERPLUS']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty-claim/warranty-claim-delete-dialog.html',
            controller: 'WarrantyClaimDeleteController',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
              entity: ['WarrantyClaim', function (WarrantyClaim) {
                return WarrantyClaim.get({ id: $stateParams.claimId }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('purchased-product.detail', null, { reload: true });
          }, function () {
            $state.go('purchased-product.detail');
          });
        }]
      })

      .state('purchased-product.gift-delete', {
        parent: 'purchased-product',
        url: '/{giftId}/deleteGift',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/gift/gift-delete-dialog.html',
            controller: 'GiftDeleteController',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('purchasedProduct');
                return $translate.refresh();
              }],
              entity: ['Gift', function (Gift) {
                return Gift.get({ id: $stateParams.giftId }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('purchased-product', null, { reload: true });
          }, function () {
            $state.go('purchased-product');
          });
        }]
      });
  }

})();
