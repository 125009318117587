(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ReceiptDetailController', ReceiptDetailController);

  ReceiptDetailController.$inject = ['$state', '$scope', '$rootScope', '$q', 'ReceiptFile', 'DataUtils', 'entity', 'Principal', 'Receipt', 'Customer', 'Contact', 'PurchasedProduct', 'AlertService', '$translate', 'Shop', 'ShopLookup', 'Card', 'Address', 'PhoneNumber', 'AuthServerProvider', 'ProductLookup', 'BrandLookup', 'Warranty', 'PurchasedProductWarranty', 'BrandService', '$stateParams', '$log', '$window', '$anchorScroll', '$location', 'CMS_URI', 'CmsShopService'];

  function ReceiptDetailController($state, $scope, $rootScope, $q, ReceiptFile, DataUtils, entity, Principal, Receipt, Customer, Contact, PurchasedProduct, AlertService, $translate, Shop, ShopLookup, Card, Address, PhoneNumber, AuthServerProvider, ProductLookup, BrandLookup, Warranty, PurchasedProductWarranty, BrandService, $stateParams, $log, $window, $anchorScroll, $location, CMS_URI, CmsShopService) {
    var vm = this;

    vm.paymentTypes = [
      { code: 'CASH', cash: true },

      { code: 'PAYPAL', cash: false, bancomat: false, creditCard: true },
      { code: 'VISA', cash: false, bancomat: false, creditCard: true },
      { code: 'MASTERCARD', cash: false, bancomat: false, creditCard: true },
      { code: 'AM_EXP', cash: false, bancomat: false, creditCard: true },
      { code: 'DINERS', cash: false, bancomat: false, creditCard: true },
      { code: 'BANK', cash: false, bancomat: false, creditCard: true },
      { code: 'CHEQUE', cash: false, bancomat: false, creditCard: true },
      { code: 'ATM_CARD', cash: false, bancomat: true, creditCard: false },
      { code: 'BANCOMAT', cash: false, bancomat: true },
      { code: 'OTHER', cash: false, bancomat: false, creditCard: true }


      // ,'PAYPAL','VISA','MASTERCARD','AM_EXP','DINERS','BANK','CHEQUE','OTHER','BANCOMAT'
    ];
    vm.typeCodes = ["GTIN", "EAN", "UPC"];

    vm.ALL_SHOPS = -1;
    vm.PREFERRED_SHOPS = 0;
    vm.EDIT_SHOP = 1;
    vm.isNewShopCollapsed = vm.PREFERRED_SHOPS;
    vm.giftCheck = 'N';
    vm.receiptHasGifts = receiptHasGifts;
    vm.cmsUri = CMS_URI;

    vm.ALL_PRODUCTS = -1;
    vm.PREFERRED_PRODUCTS = 0;
    vm.NEW_PRODUCT = 1;
    vm.isNewProductCollapsed = vm.PREFERRED_PRODUCTS;
    vm.showGiftQuestion = false;
    vm.hasGiftableProducts = hasGiftableProducts;

    vm.crediCardCollapsed = true;

    vm.basePaymentType = ['CASH', 'CHEQUE', 'BANK', 'ATM_CARD', 'PAYPAL'];
    $scope.$watch('vm.receipt.paymentType', function (value) {
      if (!value || vm.basePaymentType.includes(value)) {
        vm.crediCardCollapsed = true;
      } else {
        vm.crediCardCollapsed = false;
      }
    });


    vm.isPayment = function (type) {

      if (vm.receipt && vm.receipt.paymentType) {
        var pt = _.find(vm.paymentTypes, function (item) { return item.code === vm.receipt.paymentType; });
        return pt && pt[type];
      }

      return false;
    };

    vm.editAddCard = function () {
      vm.card = {};
      vm.isEdit2 = false;
    };
    vm.onCancelCard = function () {
      vm.card = null;
      vm.isEdit2 = true;
    };
    function onSaveSuccessCard(result) {
      $scope.loadActiveCards();
    };

    vm.hideShare = true;
    vm.shareCopyForMyself = false;
    vm.shareReceipt = function () {
      if (vm.shareRecipient) {
        $log.debug("Shareing receipt " + entity.id + " with " + vm.shareRecipient + " message: " + vm.shareMailBody);
        Receipt.share({ receiptId: entity.id, recipient: vm.shareRecipient, copyForMyself: vm.shareCopyForMyself }, { mailBody: vm.shareMailBody }).$promise
          .then(function (result) {
            $translate("garanteasyApp.purchasedProduct.detail.share.success").then(function (t) {
              AlertService.success(t);
              vm.hideShare = true;
            });
          },
            function (error) {
              $log.error("Errore nella condivisione ricevuta", error);
              AlertService.error(error);
            });
      }
    };

    vm.name = 'receipt.detail.controller';
    vm.receipt = entity;
    vm.customers = Customer.query();
    vm.contacts = Contact.queryActive();
    vm.saveGift = saveGift;
    // Le ricevute inviate da un negozio ufficiale (o in attesa di conferma dopo un claim) sono quasi totalmente readonly
    vm.isOfficial = vm.receipt.source === 'MERCHANT' || vm.receipt.source === 'CLAIM' || vm.receipt.source === 'CLAIM_RESOLVED';
    $scope.stateData.vm = vm;
    vm.isOwner = null;
    vm.isAdmin = null;
    vm.currentUserId;
    vm.images = [];
    vm.documents = [];
    vm.listSelected = [];
    vm.receiptFiles = [];
    vm.pdfFiles = [];
    vm.isEdit = false;
    vm.isEdit2 = false;
    vm.isEdit3 = false;
    vm.datePickerOpenStatus = {};
    vm.datePickerOptions = { maxDate: new Date() };
    vm.openCalendar = openCalendar;
    vm.officialReceipt = false;
    vm.typeReceipt = false;
    vm.invoice = false;
    vm.hasShop = false;
    vm.onUpdateWarrantyNewProduct = onUpdateWarrantyNewProduct;
    vm.showBuildReceiptImage = showBuildReceiptImage;
    vm.printReceipt = printReceipt;
    vm.downloadReceipt = downloadReceipt;
    vm.isGift = false;
    vm.isOfficial = false;

    vm.datePickerOpenStatus.buyingDate = false;
    vm.datePickerOpenStatus.paymentDate = false;
    vm.datePickerOpenStatus.startDate = false;
    vm.datePickerOpenStatus.deliveryDate = false;

    vm.resolveGuarantor = resolveGuarantor;
    vm.warrentyModel;
    vm.warrantyContractTypesAggregate = {};

    $scope.stateData.vm.validWarranties = vm.receipt.totalWarranties.numValid;
    $scope.stateData.vm.expiringWarranties = vm.receipt.totalWarranties.numExpiring;
    $scope.stateData.vm.expiredWarranties = vm.receipt.totalWarranties.numExpired;
    $scope.stateData.vm.undefinedWarranties = 0;
    $scope.stateData.vm.nonLegalPurchasedProductWarranties = [];
    $scope.stateData.vm = vm;

    checkGift();
    checkOfficial();
    resolveCmsData();
    buildWarrantyAggregate();

    console.log(vm.warrantyContractTypesAggregate);

    vm.receiptHasGifts();
    function receiptHasGifts() {
      vm.receipt.purchasedProducts.forEach(function (product) {
        if (product.giftReceiverEmail) {
          vm.giftCheck = 'Y';
        }
      });
    }

    function resolveCmsData() {
      //resolve brand
      resolveBrands();
      //resolve warranty
      //vm.warrantyDetail.warranty.reference = "10ae5337-30e4-4144-b632-c28b62d02413";
      if (vm.receipt.shop && vm.receipt.shop.cmsReference) {
        CmsShopService.getShopData(vm.receipt.shop.cmsReference).then(function (response) {
          vm.cmsShopData = response[0];
          vm.receipt.shop.cmsShopData = response[0];
        });
      }
    }

    var last_profile = vm.receipt.profile;
    vm.receipt.profile = vm.receipt.business ? 'BUSINESS' : 'PERSONAL';
    if (last_profile && vm.receipt.profile != last_profile)
      $translate("garanteasyApp.receipt.changeProfile").then(function (t) {
        AlertService.info(t);
        /*vm.alert = t;*/
      });

    function hasGiftableProducts() {
      //check if at least one receipt product can be gifted
      var found = false;
      if (vm.receipt.purchasedProducts.length) {
        for (var i = 0; i < vm.receipt.purchasedProducts.length; i++) {
          if (!vm.receipt.purchasedProducts[i].giftId) {
            found = true;
            break;
          }
        }
      }
      return found;
    }

    vm.goToProduct = function () {
      vm.addProduct = true; vm.receipt.purchasedProduct = {};
      vm.returnToGift = true;
      var x = 'addProduct';
      var newHash = 'anchor' + x;
      if ($location.hash() !== newHash) {
        // set the $location.hash to `newHash` and
        // $anchorScroll will automatically scroll to it
        $location.hash('anchor' + x);
      } else {
        // call $anchorScroll() explicitly,
        // since $location.hash hasn't changed
        $anchorScroll();
      }
    }

    vm.goToGift = function () {
      vm.returnToGift = false;
      var x = 'Gift';
      var newHash = 'anchor' + x;
      if ($location.hash() !== newHash) {
        // set the $location.hash to `newHash` and
        // $anchorScroll will automatically scroll to it
        $location.hash('anchor' + x);
      } else {
        // call $anchorScroll() explicitly,
        // since $location.hash hasn't changed
        $anchorScroll();
      }
    }

    var selectCustomer = function (customer) {
      if (customer) {
        vm.receipt.customer = customer;
        vm.receipt.customerId = vm.receipt.customer.id;
      } else {
        vm.receipt.customer = null;
        vm.receipt.customerId = null;
      }
    };

    vm.customerId = {
      wrap: function (customerId) {
        if (angular.isDefined(customerId)) {
          selectCustomer(_.find(vm.customers, function (cust) { return cust.id == customerId; }));
        }
        return vm.receipt.customerId;
      }
    };

    if (vm.receipt.shop && vm.receipt.shop.id) {
      vm.hasShop = true;
    }

    vm.receipt.purchasedProducts.forEach(function (purchasedProduct) {
      purchasedProduct.purchasedProductWarranties.forEach(function (purchasedProductWarranty) {
        if (purchasedProductWarranty.warranty.warrantyType !== "LEGAL") {
          vm.nonLegalPurchasedProductWarranties.push(purchasedProductWarranty);
        }
      });
    });

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    function setWarrantyModel(result) {
      switch (result.contractType) {
        case "CONVENTIONAL":
          vm.warrentyModel = {
            'image': 'azienda.png',
            'title': 'garanteasyApp.receipt.detail.infoConventionalWarrantyMobile'
          };
          break;
        case "LEGAL":
          vm.warrentyModel = {
            'image': 'bilancia_scudo_bianco.png',
            'title': 'garanteasyApp.receipt.detail.infoLegalWarrantyMobile'
          };
          break;
        case "INSURANCE_POLICY":
          vm.warrentyModel = {
            'image': 'ombrello_scudo_bianco.png',
            'title': 'garanteasyApp.receipt.detail.infoInsuranceWarrantyMobile'
          };
          break;
        case "SERVICE_CONTRACT":
          vm.warrentyModel = {
            'image': 'assistenza_1scudo_bianco.png',
            'title': 'garanteasyApp.receipt.detail.infoServiceContractWarranryMobile'
          };
          break;
        default:
          vm.warrentyModel = {
            'image': 'azienda.png',
            'title': 'garanteasyApp.receipt.detail.infoLegalWarrantyMobile'
          };
      }
    }

    vm.addPurchasedProductToListSelected = function () {
      if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length === 1) {
        vm.listSelected.splice(0, 0, vm.receipt.purchasedProducts[0]);
      }
    };

    vm.addPurchasedProductToListSelected();

    if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length === 0) {
      $translate("garanteasyApp.receipt.detail.suggestAddProduct").then(function (t) {
        AlertService.info(t);
        /*vm.alert = t;*/
      });
    }
    vm.warrantyDetail;
    setOpenedWarranty();



    function setOpenedWarranty() {
      var id = $stateParams.warrantyId;
      if (id) {
        if (vm.receipt.purchasedProductWarranty && vm.receipt.purchasedProductWarranty.id == id) {
          vm.warrantyDetail = vm.receipt.purchasedProductWarranty;
          setWarrantyModel(vm.warrantyDetail)
        }
      }
    }


    vm.enableButtonGift = function () {
      if (vm.receipt.purchasedProducts.length > 1) {
        return vm.listSelected.length > 0;
      }
      else
        return true;
    };

    vm.addToListSelected = function (index, pp) {
      if (vm.listSelected && vm.listSelected.indexOf(pp) < 0) {
        vm.listSelected.splice(index, 0, pp);
      }
      else if (vm.listSelected && vm.listSelected.indexOf(pp) >= 0) {
        for (var i = 0; i < vm.listSelected.length; i++) {
          if (vm.listSelected[i].id === pp.id)
            vm.listSelected.splice(i, 1);
        }
      }
    };

    /*        angular.forEach(vm.receipt.purchasedProducts, function (value) {
                if (value) {
                    value.selected = false;
                }
            });*/

    vm.goToGift = function () {
      $state.go('receipt.detail.editGift', { 'id': vm.receipt.id, 'listSelected': vm.listSelected });
    };

    vm.actionReso = function () {
      if (vm.listSelected && vm.listSelected.length > 0) {
        $state.go('receipt.purchased-product-reso', { 'listSelected': vm.listSelected, 'id': vm.receipt.id, 'ppId': vm.listSelected[0].id });
      }
    };

    function resolveGuarantor(purchasedProductWarranty) {
      if (purchasedProductWarranty.warranty.contractType === 'LEGAL') {
        if (purchasedProductWarranty.receipt && purchasedProductWarranty.receipt.shop && purchasedProductWarranty.receipt.shop.description) {
          return purchasedProductWarranty.receipt.shop.description;
        }
      }
      if (purchasedProductWarranty.warranty.contractType === 'CONVENTIONAL') {
        if (purchasedProductWarranty.purchasedProduct && purchasedProductWarranty.purchasedProduct.product && purchasedProductWarranty.purchasedProduct.product.producer && purchasedProductWarranty.purchasedProduct.product.producer.name) {
          if (vm.cmsWarrantyData && vm.cmsWarrantyData.holder_company) {
            return vm.cmsWarrantyData.holder_company;
          }
          return purchasedProductWarranty.purchasedProduct.product.producer.name;
        }
      }
      if (purchasedProductWarranty.warranty.contractType === 'SERVICE_CONTRACT' ||
        purchasedProductWarranty.warranty.contractType === 'INSURANCE_POLICY'
      ) {
        return purchasedProductWarranty.warranty.description;
      }
    }

    function resolveBrands() {
      var brandMap = new Map();
      var queryString = "";
      for (var i = 0; i < vm.receipt.purchasedProducts.length; i++) {
        if (vm.receipt.purchasedProducts[i] && vm.receipt.purchasedProducts[i].product && vm.receipt.purchasedProducts[i].product.brand && vm.receipt.purchasedProducts[i].product.brand.cmsReference) {
          var brandId = vm.receipt.purchasedProducts[i].product.brand.id;
          var reference = vm.receipt.purchasedProducts[i].product.brand.cmsReference;
          brandMap.set(brandId, reference);
          queryString += reference + ",";
        }
      }

      if (queryString.length != 0) {
        queryString = queryString.substring(0, queryString.length - 1);
        BrandService.getBrandData(queryString).then(function (response) {
          var resultMap = new Map();
          if (response) {
            for (var i = 0; i < response.length; i++) {
              if (response[i].title) {
                resultMap.set(response[i].uuid, response[i].title);
              }
            }
            for (var i = 0; i < vm.receipt.purchasedProducts.length; i++) {
              if (vm.receipt.purchasedProducts[i] && vm.receipt.purchasedProducts[i].product && vm.receipt.purchasedProducts[i].product.brand && vm.receipt.purchasedProducts[i].product.brand.cmsReference) {
                var brandUuid = vm.receipt.purchasedProducts[i].product.brand.cmsReference;
                if (resultMap.has(brandUuid)) {
                  vm.receipt.purchasedProducts[i].product.brand.name = resultMap.get(brandUuid);
                }
              }
            }
          }
        });
      }
    }
    vm.printPdf = function () {
      var pdf = new $window.jsPDF();
      var content = document.getElementById('receipt-image-build-panel');
      pdf.addHTML(content, function () {
        pdf.save(vm.receipt.id + '.pdf');
      });
    };

    function saveGift() {
      vm.isSaving = true;
      if (vm.purchasedProduct.id !== null) {
        PurchasedProduct.update(vm.purchasedProduct, onSaveGiftSuccess, onSaveGiftError);
      } else {
        PurchasedProduct.save(vm.purchasedProduct, onSaveGiftSuccess, onSaveGiftError);
      }
    }
    function onSaveGiftSuccess(result) {
      $scope.$emit('garanteasyApp:purchasedProductUpdate', result);
      // $uibModalInstance.close(result);
      vm.isSaving = false;
    }

    function onSaveGiftError() {
      vm.isSaving = false;
    }

    var imagesPath = {};
    vm.getImagePdf = function (id) {
      return "api/receipt-files/content/" + id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
    };
    vm.getImageUrl = function (receiptFile) {
      if (!receiptFile || !receiptFile.id) {
        imagesPath[receiptFile.id] = null;
      } else
        if (_.isUndefined(imagesPath[receiptFile.id])) {
          imagesPath[receiptFile.id] = "api/receipt-files/content/" + receiptFile.id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
        }
      return imagesPath[receiptFile.id];

    };
    if (vm.receipt.receiptFiles && vm.receipt.receiptFiles.length > 0) {
      vm.receipt.receiptFiles.forEach(function (item) {
        /*                 if(item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") == 0){
                            item.src = vm.getImageUrl(item);
                        } */
        if (!item.purchasedProductId) {
          item.src = vm.getImageUrl(item);
          if (item.fileContentType === 'application/pdf') {
            vm.pdfFiles.push(item);
          } else if (item.fileContentType !== 'build') {
            vm.receiptFiles.push(item);
          }
        }
      });
      if (vm.receiptFiles.length === 0) {
        vm.selectedFile = { fileContentType: 'build', receipt: vm.receipt };
      }
      // if ((vm.receipt.receiptType === 'RECEIPT' || !vm.receipt.receiptType || vm.receipt.receiptType === 'OFFICIAL_RECEIPT') && vm.receipt.shop) {
      //   vm.receiptFiles.push({ fileContentType: 'build', receipt: vm.receipt });
      // }
    }


    if (vm.images.length > 0) {
      vm.image = vm.images[0];
    }

    vm.changeMainImage = function (image) {
      /*$('.zoomContainer').remove();*/
      vm.image = image;
    };

    vm.checkAccess = checkAccess;
    checkAccess();

    vm.byteSize = DataUtils.byteSize;
    vm.openFile = DataUtils.openFile;

    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.currentUserId = account.id;
        if (vm.receipt.id && vm.receipt.customer) {
          vm.isOwner = vm.receipt.customer.user.id === account.id;
        } else {
          // New receipt
          vm.isOwner = true;
        }
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    $scope.productVisible = function (purchasedProduct) {
      return vm.isOwner || vm.isAdmin || (purchasedProduct.giftReceiverUserId === vm.currentUserId && purchasedProduct.giftReceiverNotified === true);
    };

    vm.canShow = function (contentType) {
      return contentType != null && contentType.toLowerCase().indexOf('image') === 0 && contentType.toLowerCase().indexOf('tiff') === -1;
    };

    vm.deleteFile = function (file, isProduct) {
      if (isProduct) {
        var index = vm.receipt.purchasedProduct.receiptFiles.indexOf(file);
        return vm.receipt.purchasedProduct.receiptFiles.splice(index, 1);
      }
      ReceiptFile.delete({ id: file.id }, function () {
        var ind = vm.receiptFiles.indexOf(file);
        vm.receiptFiles.splice(ind, 1);
      });
    };

    vm.shops = Shop.query({ filter: 'receipt-is-null' });

    $scope.resetShop = function () {
      if (vm.receipt) {
        vm.receipt.shop = null;
      }
    };

    // $scope.loadShops = function (searchQuery) {
    //   if (searchQuery && searchQuery.trim().length > 1) {
    //     ShopLookup.query({ query: searchQuery }, function (result) {
    //       $scope.shops = result;
    //     }, function (response) {
    //       if (response.status === 404) {
    //         $scope.shops = Shop.query({ isActive: true });
    //       }
    //     });
    //   } else {
    //     $scope.shops = Shop.query({ isActive: true });
    //   }
    // };

    vm.saveFirst = function () {
      Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
      vm.alertOne = true;
    };


    vm.saveSecond = function () {
      Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
    };

    String.prototype.hashCode = function () {
      var hash = 0, i, chr;
      if (this.length === 0) return hash;
      for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    };

    function checkGift() {
      if (vm.receipt.purchasedProducts) {
        vm.receipt.purchasedProducts.forEach(function (purchasedProduct) {
          if (purchasedProduct.giftId) {
            vm.isGift = true;
          }
        });
      }
    }

    function checkOfficial() {
      if (vm.receipt.source === 'MERCHANT') {
        vm.isOfficial = true;
      }
    }

    vm.computeShopCode = function () {
      var shopCode = vm.receipt.shop.shopSignboard.hashCode();
      vm.receipt.shop.code = shopCode;
    };

    vm.phoneNumberUpdated = function () {
      vm.receipt.shop.phoneNumber.activated = false;
      vm.phoneNumberIsDirty = true;
    };

    vm.saveThird = function () {
      if (vm.receipt.id != null) {
        Receipt.update(vm.receipt, onSaveSuccessShop, onSaveErrorShop);
      } else {
        Receipt.save(vm.receipt, onSaveSuccessShop, onSaveErrorShop);
      }
    };

    function onSaveSuccess(result) {
      vm.isSaving = false;
      vm.isEdit = false;
      vm.isEdit2 = false;
      vm.isEdit3 = false;
    }
    function onSaveSuccessShop(result) {
      if (!vm.hasShop && result.shop && result.shop.id) {
        vm.receipt.shop = result.shop;
        vm.hasShop = true;
      }
      vm.isSaving = false;
      vm.isEdit = false;
      vm.isEdit2 = false;
      vm.isEdit3 = false;
    }

    function onSaveError(error) {
      vm.error = error;
      /*if(error.statusText === 'Forbidden'){
          AlertService.error("zdfsf");
      }*/
      vm.isSaving = false;
      vm.isEdit = false;
      vm.isEdit2 = false;
      vm.isEdit3 = false;
    }

    function onSaveErrorShop(error) {
      vm.error = error;
      /*if(error.statusText === 'Forbidden'){
          AlertService.error("zdfsf");
      }*/
      vm.isSaving = false;
      vm.isEdit = false;
      vm.isEdit2 = false;
      vm.isEdit3 = false;
    }

    var unsubscribe = $rootScope.$on('garanteasyApp:receiptUpdate', function (event, result) {
      vm.receipt = result;
    });

    vm.uploadFile = function ($file, isProduct) {
      vm.receiptFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.receiptFile.receiptId = null;
          vm.receiptFile.fileData = null;
          vm.receiptFile.contentType = null;
          vm.receiptFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.resizeifNecessary($file, function (base64Data) {
            $scope.$apply(function () {
              vm.receiptFile.receiptId = vm.receipt.id;
              vm.receiptFile.fileData = base64Data;
              vm.receiptFile.fileContentType = $file.type;
              vm.receiptFile.fileName = $file.name;
              if (isProduct) {
                if (!vm.receipt.purchasedProduct.receiptFiles) {
                  vm.receipt.purchasedProduct.receiptFiles = [];
                }
                vm.receipt.purchasedProduct.receiptFiles.push(vm.receiptFile);
              } else {
                ReceiptFile.save(vm.receiptFile, function (result) {
                  if (result) {
                    result.src = vm.getImageUrl(result);
                    vm.receiptFiles.push(result);
                    /* vm.image = result;
                    $('.zoomContainer').remove(); */
                  }
                });
              }
            });
          });
        }
      }
    };

    vm.addPurchasedProduct = function () {
      vm.isSaving = true;
      if (
        angular.isDefined(vm.receipt.purchasedProduct) &&
        angular.isDefined(vm.receipt.purchasedProduct.product)
      ) {
        if (vm.receipt.purchasedProduct.product.id) {
          vm.receipt.purchasedProduct.allProductId = vm.receipt.purchasedProduct.product.id;
          vm.receipt.purchasedProduct.product = {};
        }
        vm.receipt.purchasedProduct.receiptId = vm.receipt.id;
        PurchasedProduct.save(vm.receipt.purchasedProduct, onSaveSuccessProduct, onSaveErrorProduct);
      }
    };

    function onSaveErrorProduct(error) {
      vm.error = error;
      vm.isSaving = false;
      AlertService.error(error.data.message);
    }

    function onSaveSuccessProduct(result) {
      var promiseArray = [];
      vm.isSaving = false;
      vm.addProduct = false;
      vm.receipt.purchasedProducts.push(result);
      if (vm.receipt.purchasedProduct.receiptFiles && vm.receipt.purchasedProduct.receiptFiles.length) {
        vm.receipt.purchasedProduct.receiptFiles.forEach(function (receiptFile) {
          if (receiptFile.fileDataChanged) {
            receiptFile.fileData = receiptFile.fileDataChanged;
          }
          delete receiptFile.fileDataChanged;
          delete receiptFile.changed;
          receiptFile.receiptId = vm.receipt.id;
          receiptFile.purchasedProductId = result.id;
          promiseArray.push(ReceiptFile.save(receiptFile, function (result) { }, onSaveErrorProduct).$promise);
        });
      }
      if (vm.receipt.purchasedProduct.warrantyConventional && result.id) {
        vm.receipt.purchasedProduct.warrantyConventional.purchasedProductId = result.id;
        promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProduct.warrantyConventional));
      }
      if (vm.receipt.purchasedProduct.warrantyService && result.id) {
        vm.receipt.purchasedProduct.warrantyService.purchasedProductId = result.id;
        promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProduct.warrantyService));
      }
      if (vm.receipt.purchasedProduct.warrantyInsurance && result.id) {
        vm.receipt.purchasedProduct.warrantyInsurance.purchasedProductId = result.id;
        promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProduct.warrantyInsurance));
      }

      $q.all(promiseArray).then(function () {
        vm.receipt.purchasedProduct = {};
      }).catch(onSaveErrorProduct);
    }

    $scope.loadActiveCards = function () {
      if (vm.receipt.customer)
        vm.receipt.customerId = vm.receipt.customer.id;
      if (vm.receipt.customer && vm.receipt.buyingDate)
        vm.cards = Card.queryActive(vm.receipt);
    };

    vm.products = [];
    $scope.loadProducts = function (searchQuery) {
      if (vm.isNewProductCollapsed === vm.PREFERRED_PRODUCTS) {
        ProductLookup.query({}, function (results) {
          vm.products = results;
        });
      } else {
        ProductLookup.query({ query: searchQuery },
          function (result) {
            vm.products = result;
            angular.forEach(vm.products, function (product, key) {
              product.description = String(product.description).replace(/<[^>]+>/gm, '');
            });
          },
          function (response) {
            $log.error(response);
            AlertService.error("Error: " + response.status);
          }
        );
      }
    };

    $scope.loadBrands = function (searchQuery) {
      BrandLookup.query({ query: searchQuery },
        function (result) {
          $scope.brands = result;
        }
      );
    };

    $scope.loadActiveCards();

    $scope.$on('$destroy', unsubscribe);

    $scope.$on('$destroy', function (event, next, current) {
      $('.zoomContainer').remove();
    });

    $(document).ready(function () {
      window.scrollTo(0, 0);
    });

    function saveUpdatePurchasedProductWarranty(purchasedProductWarranty) {
      var promise = new Promise(function (resolve) {
        resolve();
      });
      if (!purchasedProductWarranty.warranty.id) {
        promise = Warranty.save(purchasedProductWarranty.warranty).$promise;
      } else if (purchasedProductWarranty.warranty.id && purchasedProductWarranty.warranty.code === 'CUSTOM') {
        promise = Warranty.update(purchasedProductWarranty.warranty).$promise;
      }
      if (!purchasedProductWarranty.id) {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || warranty.id;
          return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
        });
      } else {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || warranty.id;
          return PurchasedProductWarranty.update(purchasedProductWarranty).$promise;
        });
      }
      return promise.then(function (result) {
        purchasedProductWarranty = result;
        return purchasedProductWarranty;
      });
    }

    function onUpdateWarrantyNewProduct(purchasedProductWarranty, valid) {
      if (!purchasedProductWarranty) {
        return;
      }
      switch (purchasedProductWarranty.warranty.warrantyType) {
        case 'CONVENTIONAL':
          vm.receipt.purchasedProduct.warrantyConventional = Object.assign({}, purchasedProductWarranty);
          break;
        case 'SERVICE_CONTRACT':
          vm.receipt.purchasedProduct.warrantyService = Object.assign({}, purchasedProductWarranty);
          break;
        case 'INSURANCE':
          vm.receipt.purchasedProduct.warrantyInsurance = Object.assign({}, purchasedProductWarranty);
          break;
      }
    }

    function showBuildReceiptImage() {
      return !vm.receiptFiles.length && vm.receipt.shop && !vm.isEdit && (vm.receipt.receiptType === 'RECEIPT' || !vm.receipt.receiptType || vm.receipt.receiptType === 'OFFICIAL_RECEIPT');
    }

    function printReceipt() {
      var content = document.getElementById('receiptPrintContent').innerHTML;
      var headContent = document.getElementsByTagName('head')[0].innerHTML;
      var popupWin = window.open('', '_blank');
      popupWin.document.open();
      var style = '.receipt-print{ max-width: 600px; } @media print {@page {size: A4 portrait; } .receipt-print{ max-width: 300px; margin: auto; padding: 0; }	}';
      popupWin.document.write('<html><head>' + headContent + '<style type="text/css">' + style + '</style></head><body onload="window.print()"><div class="receipt-print">' + content + '</div></body></html>');
      popupWin.document.close();
    }
    function downloadReceipt() {
      var source = document.getElementById('receiptPrintContent').innerHTML;
      var pdf = new jsPDF('p', 'pt', 'letter');

      // we support special element handlers. Register them with jQuery-style
      // ID selector for either ID or node name. ("#iAmID", "div", "span" etc.)
      // There is no support for any other type of selectors
      // (class, of compound) at this time.
      var specialElementHandlers = {
        // element with id of "bypass" - jQuery style selector
        '#bypassme': function (element, renderer) {
          // true = "handled elsewhere, bypass text extraction"
          return true;
        }
      };
      var margins = {
        top: 80,
        bottom: 60,
        left: 40,
        width: 522
      };
      // all coords and widths are in jsPDF instance's declared units
      // 'inches' in this case
      pdf.fromHTML(
        source // HTML string or DOM elem ref.
        , margins.left // x coord
        , margins.top // y coord
        , {
          'width': margins.width // max width of content on PDF
          ,
          'elementHandlers': specialElementHandlers
        },
        function (dispose) {
          // dispose: object with X, Y of the last line add to the PDF
          //          this allow the insertion of new lines after html

          //Didn't work
          //   console.log("Saving HTMLclick");
          // pdf.save('Test.pdf');
        },
        margins
      );

      pdf.save('receipt.pdf');

    }
    vm.updateShop = function (newShop) {
      vm.receipt.shop = newShop;
    };

    function buildWarrantyAggregate() {
      if (vm.receipt && Array.isArray(vm.receipt.contractTypesAggregate)) {
        vm.receipt.contractTypesAggregate.forEach(function (item) {
          vm.warrantyContractTypesAggregate[item.contractType] = item;
          vm.warrantyContractTypesAggregate[item.contractType].total = item.numExpired || 0 + item.numExpiring || 0 + item.numValid || 0;
        });
      }
    }

  }
})();
