(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('SearchController', SearchController);

    SearchController.$inject = ['$stateParams', '$rootScope', 'PurchasedProductWarrantySearch', 'ParseLinks', '$q', 'ReceiptSearch', 'PurchasedProductSearch', 'ShopSearch'];

    function SearchController($stateParams, $rootScope, PurchasedProductWarrantySearch, ParseLinks, $q, ReceiptSearch, PurchasedProductSearch, ShopSearch) {
        var vm = this;
        vm.totalCount = 0;
        var initialSortState = {
            documents: { field: 'shop.merchant.owner.name', type: 'desc' },
            products: { field: 'receipt.buyingDate', type: 'desc' },
            shops: { field: 'merchant.owner.name', type: 'desc' },
            warrenties: { field: 'expirationDate', type: 'desc' }
        };
        vm.pagination = {
            documents: {
                totalCount: 0,
                link: {},
                page: 0
            },
            shops: {
                totalCount: 0,
                link: {},
                page: 0
            },
            products: {
                totalCount: 0,
                link: {},
                page: 0
            },
            warrenties: {
                totalCount: 0,
                link: {},
                page: 0
            }
        }
        vm.totalItems = 0;
        var s = ($stateParams.s ? JSON.parse($stateParams.s) : initialSortState);
        while (typeof s === 'string' || s instanceof String) {
            s = JSON.parse(s);
        }
        vm.model = {
            documents: $stateParams.documents === "true",
            shops: $stateParams.shops === "true",
            products: $stateParams.products === "true",
            warrenties: $stateParams.warrenties === "true",
            search: $stateParams.search,
            page: $stateParams.page || 0,
            size: $stateParams.size || 20,
            documentsList: [],
            shopsList: [],
            productsList: [],
            warrentiesList: [],
            list: [],
            s: s,
        };
        vm.gridView = $rootScope.gridView;
        vm.isLoading = false;

        function loadAllPurchasedProductWarranty(data) {
            var d = $q.defer();
            if (data.page > vm.pagination.warrenties.link.last || !vm.model.warrenties) {
                d.resolve([]);
            } else {
                data.sort = 'expirationDate,desc';
                PurchasedProductWarrantySearch.query(data, function (result, headers) {
                    vm.pagination.warrenties.link = ParseLinks.parse(headers('link'));
                    vm.pagination.warrenties.totalCount = +headers('X-Total-Count');
                    d.resolve(result.$promise);
                });
            }
            return d.promise;
        }
        function loadAllReceipts(data) {
            var d = $q.defer();
            if (data.page > vm.pagination.documents.link.last || !vm.model.documents) {
                d.resolve([]);
            } else {
                data.sort = 'buyingDate,desc';
                ReceiptSearch.query(data, function (result, headers) {
                    vm.pagination.documents.link = ParseLinks.parse(headers('link'));
                    vm.pagination.documents.totalCount = +headers('X-Total-Count');
                    d.resolve(result);
                });
            }
            return d.promise;
        }
        function loadAllPurchasedProduct(data) {
            var d = $q.defer();
            if (data.page > vm.pagination.products.link.last || !vm.model.products) {
                d.resolve([]);
            } else {
                data.sort = 'receipt.buyingDate,desc';
                PurchasedProductSearch.query(data, function (result, headers) {
                    vm.pagination.products.link = ParseLinks.parse(headers('link'));
                    vm.pagination.products.totalCount = +headers('X-Total-Count');
                    d.resolve(result);
                });
            }
            return d.promise;
        }
        function loadAllShop(data) {
            var d = $q.defer();
            if (data.page > vm.pagination.shops.link.last || !vm.model.shops) {
                d.resolve([]);
            } else {
                data.sort = 'merchant.owner.name,asc';
                ShopSearch.query(data, function (result, headers) {
                    vm.pagination.shops.link = ParseLinks.parse(headers('link'));
                    vm.pagination.shops.totalCount = +headers('X-Total-Count');
                    d.resolve(result);
                });
            }
            return d.promise;
        }
        loadAll();

        function loadAll() {
            vm.isLoading = true;
            if (!vm.model.search) {
                vm.model.list = [];
                return;
            }
            var data = {
                query: vm.model.search,
                page: vm.model.page,
                size: vm.model.size,
            };
            $q.all([
                loadAllReceipts(data),
                loadAllPurchasedProduct(data),
                loadAllShop(data),
                loadAllPurchasedProductWarranty(data)
            ]).then(function (data, headers) {
                vm.model.documentsList = vm.model.documentsList.concat(data[0].map(function (x) { return { type: "document", obj: x }; }));
                vm.model.productsList = vm.model.productsList.concat(data[1].map(function (x) { return { type: "product", obj: x }; }));
                vm.model.shopsList = vm.model.shopsList.concat(data[2].map(function (x) { return { type: "shop", obj: x }; }));
                vm.model.warrentiesList = vm.model.warrentiesList.concat(data[3].map(function (x) { return { type: "warrenty", obj: x }; }));
                vm.model.list = vm.model.documentsList.concat(vm.model.shopsList).concat(vm.model.productsList).concat(vm.model.warrentiesList);
                vm.isLoading = false;
                vm.totalCount = +vm.pagination.documents.totalCount + vm.pagination.shops.totalCount + vm.pagination.warrenties.totalCount + vm.pagination.products.totalCount;
            }).catch(function (e) {
                console.log(e);
                vm.isLoading = false;
            });
        }
        vm.loadPage = function (page) {
            if (vm.model.page != page) {
                vm.model.page = page;
                loadAll();
            }
        }
        vm.disableInfiniteScroll = function () {
            return vm.isLoading || vm.page >= vm.links['last']
        }
    }
})();
