(function () {
  'use strict';
  angular
    .module('garanteasyApp')
    .factory('GalleryCheckService', GalleryCheckService);

  GalleryCheckService.$inject = [];

  function GalleryCheckService() {
    var elements = [];
    var service = {
      getData: getData,
      setData: setData
    }
    return service;

    function getData() {
      return elements;
    }

    function setData(val) {
      return elements = val;
    }
  }
})();
