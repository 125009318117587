(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('ProductResources', ProductResources);

    ProductResources.$inject = ['$resource'];

    function ProductResources ($resource) {
        var resourceUrl =  'api/product-resources/:id';

        var ret = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getProductMainImage': {
                method: 'GET',
                url: 'api/product-resources/mainImage/:productId',
                cache: true,
                transformResponse: function (data) {
                  if (data) {
                    data = angular.fromJson(data);
                  }
                  return data;
                }
              },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' }
        });
        
        ret.RType={IMAGE:'IMAGE',VIDEO:'VIDEO',MANUAL:'MANUAL' };
        
        return ret;
    }
})();
