(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('RegisterController', RegisterController);


  RegisterController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', '$location', '$filter', '$stateParams', 'PrivacyPolicy', 'AlertService', '$rootScope', 'JhiLanguageService', '$log'];

  function RegisterController($translate, $timeout, Auth, LoginService, $location, $filter, $stateParams, PrivacyPolicy, AlertService, $rootScope, JhiLanguageService, $log) {
    var vm = this;

    vm.doNotMatch = null;
    vm.error = null;
    vm.errorSocialLoginError = $stateParams.SocialLoginError;
    vm.errorUserExists = null;
    vm.errorServiceConsent = null;
    vm.login = LoginService.open;
    vm.register = register;
    vm.registerAccount = {};
    vm.success = null;
    vm.lang = $translate.use();
    vm.arrayLegalese = function (stringhe) {
      if (stringhe) {
        vm.array = [];
        vm.array = vm.array.concat(stringhe);
        vm.array = stringhe.split(',');
      } else {
        $log.error("Mancano le clausole di provacy")
      }
    };



    /*
 * JhiLanguageService.getCurrent().then(function (data) { vm.lang =
 * data; });
 */



    function loadMerchantPrivacyPolicies() {
      if (vm.merchantCode)
        PrivacyPolicy.regMerchantCode({ merchant_code: vm.merchantCode, userPolicies: true, language: vm.lang }, onSuccess, onError);
      else
        PrivacyPolicy.reg({}, onSuccess, onError);
    }

    function onSuccess(data) {
      vm.registerAccount.merchantPrivacyPolicies = [];

      if (data && data.length > 0) {
        data.forEach(function (pp) {
          var mpp = {
            policyId: pp.id,
            code: pp.code,
            merchant: pp.merchant,
            mandatory: pp.mandatory,
            approved: false,
            merchantCode: pp.merchant.code
          };
          if (pp.description && pp.description.length > 0) {
            pp.description.forEach(function (ppDescr) {
              if (ppDescr.language !== vm.lang) {
                $log.error("Attenzione la lingua della policy ( " + ppDescr.language + " e quella della interfaccia " + vm.lang + " non corrispondono");
              }
              mpp.privacyPolicy = ppDescr.description;
              mpp.policyItem = ppDescr;
              if(ppDescr.url) {
                mpp.policyItem.urls = ppDescr.url.split(',');
              }
            });
          }

          vm.registerAccount.merchantPrivacyPolicies.push(mpp);
        });
      }

    }

    function onError(error) {
      AlertService.error(error.data.message);
    }


    if ($location.search().merchantCode) {
      vm.merchantCode = $location.search().merchantCode.toUpperCase();
    } else {

      //vm.merchantCode = 'GARANTEASY';
    }


    $timeout(function () { angular.element('#login').focus(); });

    loadMerchantPrivacyPolicies();

    function register() {
      if (vm.registerAccount.password !== vm.confirmPassword) {
        vm.doNotMatch = 'ERROR';
      } else {
        vm.registerAccount.langKey = $translate.use();
        vm.registerAccount.merchantCode = vm.merchantCode;
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.errorEmailExists = null;
        vm.errorServiceConsent = null;
        vm.isSaving = true;

        // Removing extra elements from the array
        vm.registerAccount.merchantPrivacyPolicies = $filter('filter')(vm.registerAccount.merchantPrivacyPolicies, function (v, i, a) { return v.code });
        // console.log(vm.registerAccount.merchantPrivacyPolicies);
        vm.registerAccount.login = vm.registerAccount.email;
        Auth.createAccount(vm.registerAccount).then(function () {
          vm.success = 'OK';
          vm.isSaving = false;
          // PrivacyPolicy.requiredPost({},onSuccessPost, onError);
        }).catch(function (response) {
          vm.success = null;
          vm.isSaving = false;
          if (response.status === 400 && response.data === 'login already in use') {
            vm.errorUserExists = 'ERROR';
          } else if (response.status === 400 && response.data === 'e-mail address already in use') {
            vm.errorEmailExists = 'ERROR';
          } else if (response.status === 400 && response.data === 'service consent mandatory') {
            vm.errorServiceConsent = 'ERROR';
          } else {
            vm.error = 'ERROR';
          }
        });
      }
    }
  }
})();
