    (function() {
        'use strict';

        angular
            .module('garanteasyApp')
            .controller('DashboardSidebarController', DashboardSidebarController);

        DashboardSidebarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

        function DashboardSidebarController ($state, Auth, Principal, ProfileService, LoginService) {
            var vm = this;
            vm.showSuggestions = false;
            vm.name = 'DashboardSidebarController';
            vm.isNavbarCollapsed = true;
            vm.isAuthenticated = Principal.isAuthenticated;
            vm.currentState = $state.current.parent;
            ProfileService.getProfileInfo().then(function(response) {
                vm.inProduction = response.inProduction;
                vm.swaggerEnabled = response.swaggerEnabled;
            });

            vm.login = login;
            vm.logout = logout;
            vm.toggleNavbar = toggleNavbar;
            vm.collapseNavbar = collapseNavbar;
            vm.$state = $state;

            function login() {
                collapseNavbar();
                LoginService.open();
            }

            function logout() {
                collapseNavbar();
                Auth.logout();
                $state.go('home');
            }

            function toggleNavbar() {
                vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
            }

            function collapseNavbar() {
                vm.isNavbarCollapsed = true;
            }
        }
    })();
