(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', 'Auth'];

    function SidebarController ($state, Auth) {
        var vm = this;
        vm.showSuggestions = false;
        vm.name = 'SidebarController';
        vm.triggerObj = {};
        vm.triggerObj.trigger = false;
        vm.showSidebarGrey = showSidebarGrey;

        function showSidebarGrey() {
            if(vm.triggerObj.trigger == true){
                vm.triggerObj.trigger = false;
            }
            else {
                vm.triggerObj.trigger = true;
            }
        }
    }
})();
