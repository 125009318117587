(function () {
  'use strict';
  angular
    .module('garanteasyApp')
    .controller('DashboardController', DashboardController);

  DashboardController.$inject = ['$scope', 'DashboardUser', '$rootScope', 'Receipt', '$state'];

  function DashboardController($scope, DashboardUser, $rootScope, Receipt, $state) {
    var vm = this;
    vm.name = 'DashboardController';
    vm.init = init;
    $scope.stateData.vm = vm;
    // var filterWarranty = $stateParams.filterWarranty;
    vm.isLoading = false;
    vm.receiptData = {};
    vm.shopData = {};
    vm.purchasedProductsData = {};
    vm.purchaseModeData = {};
    vm.warrantiesData = {};
    vm.accessInfoData = {};
    vm.warrantiesTotal = {};
    vm.init();
    function redirectToDetail(data, headers) {
      if (data.length === 1) {
        var id = data[0].id;
        $state.go('receipt.detail', { id: id }, { reload: true });
      }
    }
    function init() {
      DashboardUser.getReceipts().$promise.then(function (data) {
        vm.receiptData = data;
        if ($rootScope.firstime) {
          if (data.total === 1) {
            Receipt.query({}, redirectToDetail, null);
          }
        }
        $rootScope.firstime = false;
      });
      DashboardUser.getShops().$promise.then(function (data) {
        vm.shopData = data;
      });
      DashboardUser.getPurchaseMode().$promise.then(function (data) {
        vm.purchaseModeData = data;
        vm.purchaseModeData.total = (data.typesTotal.CASH || 0) + (data.typesTotal.BANK || 0) + (data.typesTotal.MASTERCARD || 0)
          + (data.incomplete || 0);

      });
      DashboardUser.getPurchasedProducts().$promise.then(function (data) {
        vm.purchasedProductsData = data;
      });
      DashboardUser.getWarranties().$promise.then(function (data) {
        vm.warrantiesData = data;
        var totals = { total: 0, incomplete: 0, expired: 0, aboutToExpire: 0 }
        var obj = data.typesTotal;
        for (var prop in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            totals.total += obj[prop].total || 0;
            totals.incomplete += obj[prop].incomplete;
            totals.expired += (obj[prop].expirationTotal || {}).expired || 0;
            totals.aboutToExpire += (obj[prop].expirationTotal || {}).aboutToExpire || 0;
          }
        }
        vm.warrantiesTotal = totals;
      });
      DashboardUser.getAccessInfo().$promise.then(function (data) {
        vm.accessInfoData = data;
      });
    }
  }
})();
