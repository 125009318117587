(function () {
  'use strict';

  var giftReceiptSelectComponent = {
    templateUrl: 'app/entities/gift/components/gift-receipt-select-component.html',
    controller: GiftReceiptSelectController,
    controllerAs: 'vm',
    bindings: {
      receipt: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('giftReceiptSelectComponent', giftReceiptSelectComponent);

  GiftReceiptSelectController.$inject = ['$scope', 'Warranty', '$log', 'PurchasedProduct'];

  function GiftReceiptSelectController($scope, Warranty, $log, PurchasedProduct) {
    var vm = this;
    vm.giftInfo = {};
    vm.onSave = onSave;
    vm.selectedProducts = [];
    vm.clickedCheck = clickedCheck;

    function onSave() {
      vm.selectedProducts.forEach(function (pProductId) {
        var product = vm.receipt.purchasedProducts.find(function (pProduct) {
          return pProduct.id === pProductId;
        });
        if (!vm.giftInfo.giftMakerDescription) {
          product.giftMakerDescription = vm.receipt.customer.legalName;
        } else {
          product.giftMakerDescription = vm.giftInfo.giftMakerDescription;
        }
        product.giftReceiverEmail = vm.giftInfo.giftReceiverEmail;
        product.giftDescription = vm.giftInfo.giftDescription;
        PurchasedProduct.update(product, onSaveSuccess, onSaveError);
      });
    }

    function onSaveSuccess() {
    }

    function onSaveError() {

    }

    function clickedCheck(purchasedProduct) {
      if (purchasedProduct) {
        if (purchasedProduct.checked) {
          vm.selectedProducts.push(purchasedProduct.id);
        } else {
          var result = vm.selectedProducts.indexOf(purchasedProduct.id);
          if (result > -1) {
            vm.selectedProducts.splice(result, 1);
          }
        }
      }
    }

  }
})();
