(function () {
  "use strict";

  angular.module("garanteasyApp").config(stateConfig);

  stateConfig.$inject = ["$stateProvider"];

  function stateConfig($stateProvider) {
    $stateProvider
      .state("shop", {
        parent: "entity",
        url: "/shop??page&sort&search&documents&shops&products&warrenties&s",
        ncyBreadcrumb: {
          label: "{{'garanteasyApp.shop.home.title' | translate}}"
        },
        data: {
          authorities: ["ROLE_USER"],
          pageTitle: "garanteasyApp.shop.home.title"
        },
        views: {
          "sidebar@": {
            templateUrl: "app/entities/shop/sidebar-shops.html"
          },
          "content@": {
            templateUrl: "app/entities/shop/shops.html",
            controller: "ShopController",
            controllerAs: "vm"
          }
        },
        params: {
          page: {
            value: "0",
            squash: true
          },
          sort: {
            value: "merchant.owner.name,asc",
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: [
            "$stateParams",
            "PaginationUtil",
            function ($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                ascending: PaginationUtil.parseAscending($stateParams.sort),
                search: $stateParams.search
              };
            }
          ],
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader
                .addPart("shop")
                .addPart("global")
                .addPart("mandatoryFields")
                .addPart("purchasedProduct")
                .addPart("receipt");
              return $translate.refresh();
            }
          ]
        }
      })
      .state("shop-detail", {
        parent: "entity",
        url: "/shop/{id}?from",
        ncyBreadcrumb: {
          label: "{{'garanteasyApp.shop.detail.title' | translate}}",
          parent: function ($scope) {
            return $scope.from || "shop";
          }
        },
        data: {
          authorities: ["ROLE_USER"],
          pageTitle: "garanteasyApp.shop.detail.title"
        },
        views: {
          "content@": {
            templateUrl: "app/entities/shop/shop-detail.html",
            controller: "ShopDetailController",
            controllerAs: "vm"
          }
        },
        resolve: {
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader
                .addPart("shop")
                .addPart("address")
                .addPart("merchant")
                .addPart("mandatoryFields")
                .addPart("receipt");
              return $translate.refresh();
            }
          ],
          entity: [
            "$stateParams",
            "Shop",
            function ($stateParams, Shop) {
              return Shop.get({ id: $stateParams.id }).$promise;
            }
          ],
          previousState: [
            "$state",
            function ($state) {
              var currentStateData = {
                name: $state.current.name || "shop",
                params: $state.params,
                url: $state.href($state.current.name, $state.params)
              };
              return currentStateData;
            }
          ]
        }
      })
      .state("shop-detail.edit", {
        parent: "shop-detail",
        url: "/detail/edit",
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/shop/shop-dialog.html",
                controller: "ShopDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  translatePartialLoader: [
                    "$translate",
                    "$translatePartialLoader",
                    function ($translate, $translatePartialLoader) {
                      $translatePartialLoader.addPart("phoneNumber");
                      return $translate.refresh();
                    }
                  ],
                  entity: [
                    "Shop",
                    function (Shop) {
                      return Shop.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("^", {}, { reload: false });
                },
                function () {
                  $state.go("^");
                }
              );
          }
        ]
      })
      .state("shop.new", {
        parent: "shop",
        url: "/new",
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/shop/shop-dialog.html",
                controller: "ShopDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  translatePartialLoader: [
                    "$translate",
                    "$translatePartialLoader",
                    function ($translate, $translatePartialLoader) {
                      $translatePartialLoader.addPart("address");
                      $translatePartialLoader.addPart("merchant");
                      return $translate.refresh();
                    }
                  ],
                  entity: function () {
                    return {
                      code: null,
                      vatCode: null,
                      ecommerceUrl: null,
                      email: null,
                      official: 0,
                      id: null
                    };
                  }
                }
              })
              .result.then(
                function () {
                  $state.go("shop", null, { reload: true });
                },
                function () {
                  $state.go("shop");
                }
              );
          }
        ]
      })
      /* Modifica dall'elenco attualmente disabilitata
        .state('shop.edit', {
            parent: 'shop',
            url: '/{id}/edit',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/shop/shop-dialog.html',
                    controller: 'ShopDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Shop', function(Shop) {
                            return Shop.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('shop', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        */
      .state("shop.new-page", {
        parent: "entity",
        url: "/newShop",
        data: {
          authorities: ["ROLE_USER"]
        },
        views: {
          "sidebar@": {
            templateUrl: "app/entities/receipt/sidebar-receipts.html"
          },
          "content@": {
            templateUrl: "app/entities/shop/shop-new-page.html",
            controller: "ShopNewPageController",
            controllerAs: "vm"
          }
        },
        resolve: {
          entity: function () {
            return {
              code: null,
              vatCode: null,
              ecommerceUrl: null,
              email: null,
              official: 0,
              id: null
            };
          },
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader
                .addPart("receipt")
                .addPart("address")
                .addPart("merchant")
                .addPart("global")
                .addPart("mandatoryFields")
                .addPart("shop");
              return $translate.refresh();
            }
          ]
        }
      })
      .state("shop.delete", {
        parent: "shop",
        url: "/{id}/delete",
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/shop/shop-delete-dialog.html",
                controller: "ShopDeleteController",
                controllerAs: "vm",
                size: "md",
                resolve: {
                  entity: [
                    "Shop",
                    function (Shop) {
                      return Shop.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("shop", null, { reload: true });
                },
                function () {
                  $state.go("^");
                }
              );
          }
        ]
      });
  }
})();
