(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('accept-privacy', {
            parent: 'account',
            url: '/accept-privacy',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'sidebar@': {
                    templateUrl: 'app/layouts/sidebar/sidebar-none.html',
                    controller: 'SidebarController',
                    controllerAs: 'vm'
                },
                'content@': {
                    templateUrl: 'app/accept_privacy_user/accept-privacy.html',
                    controller: 'AcceptPrivacyController',
                    controllerAs: 'vm'
                }
            },
            params:{
                merchantCode: {
                    value: 'GARANTEASY',
                    squash: false
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('merchant');
                    $translatePartialLoader.addPart('accept-privacy');
                    return $translate.refresh();
                }]
            }
        }).state('acceptPrivacyLogout', {
            parent: 'accept-privacy',
            url: '/acceptPrivacyLogout',
            ncyBreadcrumb: {
                skip: true // Never display this state in breadcrumb.
            },
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/accept_privacy_user/accept-privacy-logout-dialog.html',
                    controller: 'AcceptPrivacyLogoutController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('register');
                            $translatePartialLoader.addPart('merchant');
                            $translatePartialLoader.addPart('accept-privacy');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('accept-privacy', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
