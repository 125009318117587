(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ReceiptFileDialogController', ReceiptFileDialogController);

    ReceiptFileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DataUtils', 'ReceiptFile'];

    function ReceiptFileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DataUtils, ReceiptFile) {
        var vm = this;

        vm.receiptFile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.fileTooLarge = false;

        vm.setFile = function ($file, receiptFile) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
            	if($file.size>5120000) {
                    vm.fileTooLarge = true;
                    // Reset data in case there was a previous image selected
                	receiptFile.fileName = null;
                	receiptFile.fileData = null;
                	receiptFile.fileContentType = null;
            	} else {
                    vm.fileTooLarge = false;
	                DataUtils.toBase64($file, function(base64Data) {
	                    $scope.$apply(function() {
	                    	receiptFile.fileName = $file.name;
	                    	receiptFile.fileData = base64Data;
	                    	receiptFile.fileContentType = $file.type;
	                    });
	                });
            	}
            }
        };

        vm.canShow = function (contentType) {
        	return contentType != null && contentType.toLowerCase().indexOf('image') == 0 && contentType.toLowerCase().indexOf('tiff') == -1;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.receiptFile.id !== null) {
                ReceiptFile.update(vm.receiptFile, onSaveSuccess, onSaveError);
            } else {
                ReceiptFile.save(vm.receiptFile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyApp:receiptFileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
