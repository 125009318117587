(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('search', {
                parent: 'app',
                url: '/search?page&sort&search&documents&shops&products&warrenties&s&r',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.purchasedProduct.home.title'
                },
                views: {
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'content@': {
                        templateUrl: 'app/entities/search/search.controller.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('purchasedProduct');
                        $translatePartialLoader.addPart('receipt');
                        $translatePartialLoader.addPart('warranty');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('shop');
                        $translatePartialLoader.addPart('purchasedProductWarranty');
                        $translatePartialLoader.addPart('timeUnit');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
