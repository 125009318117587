(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('purchased-product-warranty', {
        parent: 'entity',
        url: '/purchased-product-warranty?page&sort&search&documents&shops&products&warrenties&s',
        ncyBreadcrumb: {
          label: "{{'garanteasyApp.purchasedProductWarranty.home.title' | translate}}"
        },
        data: {
          authorities: ['ROLE_USER'],
          pageTitle: 'garanteasyApp.purchasedProductWarranty.home.title'
        },
        views: {
          'sidebar@': {
            templateUrl: 'app/entities/receipt/sidebar-receipts.html'
          },
          'content@': {
            templateUrl: 'app/entities/purchased-product-warranty/purchased-product-warranties.html',
            controller: 'PurchasedProductWarrantyController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('purchasedProductWarranty');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('receipt');
            $translatePartialLoader.addPart('dataSource');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('timeUnit');
            $translatePartialLoader.addPart('purchasedProduct');
            return $translate.refresh();
          }]
        }
      })
      .state('purchased-product-warranty-detail', {
        parent: 'entity',
        url: '/purchased-product-warranty/{id}',
        data: {
          authorities: ['ROLE_USER'],
          pageTitle: 'garanteasyApp.purchasedProductWarranty.detail.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/purchased-product-warranty/purchased-product-warranty-detail.html',
            controller: 'PurchasedProductWarrantyDetailController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('receipt');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('timeUnit');
            $translatePartialLoader.addPart('purchasedProductWarranty');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('purchasedProduct');
            $translatePartialLoader.addPart('receiptType');
            $translatePartialLoader.addPart('paymentType');
            $translatePartialLoader.addPart('shop');
            $translatePartialLoader.addPart('dashboard');
            return $translate.refresh();
          }],
          entity: ['$stateParams', 'PurchasedProductWarranty', function ($stateParams, PurchasedProductWarranty) {
            return PurchasedProductWarranty.get({
              id: $stateParams.id
            }).$promise;
          }],
          previousState: ["$state", function ($state) {
            var currentStateData = {
              name: $state.current.name || 'purchased-product-warranty',
              params: $state.params,
              url: $state.href($state.current.name, $state.params)
            };
            return currentStateData;
          }]
        }
      })
      .state('purchased-product-warranty-detail.edit', {
        parent: 'purchased-product-warranty-detail',
        url: '/detail/edit',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product-warranty/purchased-product-warranty-dialog.html',
            controller: 'PurchasedProductWarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: ['PurchasedProductWarranty', function (PurchasedProductWarranty) {
                return PurchasedProductWarranty.get({
                  id: $stateParams.id
                }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('^', {}, {
              reload: false
            });
          }, function () {
            $state.go('^');
          });
        }]
      })
      .state('purchased-product-warranty.new', {
        parent: 'purchased-product-warranty',
        url: '/new',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product-warranty/purchased-product-warranty-dialog.html',
            controller: 'PurchasedProductWarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: function () {
                return {
                  expirationDate: null,
                  notifiedApplication: 0,
                  notifiedExpiration: 0,
                  id: null
                };
              }
            }
          }).result.then(function () {
            $state.go('purchased-product-warranty', null, {
              reload: true
            });
          }, function () {
            $state.go('purchased-product-warranty');
          });
        }]
      })
      .state('purchased-product-warranty.edit', {
        parent: 'purchased-product-warranty',
        url: '/{id}/edit',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product-warranty/purchased-product-warranty-dialog.html',
            controller: 'PurchasedProductWarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: ['PurchasedProductWarranty', function (PurchasedProductWarranty) {
                return PurchasedProductWarranty.get({
                  id: $stateParams.id
                }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('purchased-product-warranty', null, {
              reload: true
            });
          }, function () {
            $state.go('^');
          });
        }]
      })
      .state('purchased-product-warranty.delete', {
        parent: 'purchased-product-warranty',
        url: '/{id}/delete',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/purchased-product-warranty/purchased-product-warranty-delete-dialog.html',
            controller: 'PurchasedProductWarrantyDeleteController',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
              entity: ['PurchasedProductWarranty', function (PurchasedProductWarranty) {
                return PurchasedProductWarranty.get({
                  id: $stateParams.id
                }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('purchased-product-warranty', null, {
              reload: true
            });
          }, function () {
            $state.go('^');
          });
        }]
      });
  }

})();
