(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ShopController', ShopController);

  ShopController.$inject = ['$scope', '$state', 'Principal', 'Shop', 'ShopSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', '$rootScope', 'Searchbar', 'DataUtils', 'CmsShopService', '$log', '$localStorage', '$translate'];

  function ShopController($scope, $state, Principal, Shop, ShopSearch, ParseLinks, AlertService, pagingParams, paginationConstants, $rootScope, Searchbar, DataUtils, CmsShopService, $log, $localStorage, $translate) {
    var vm = this;
    vm.shops = [];
    $rootScope.showSearchBar = true;
    vm.gridView = $rootScope.gridView;
    vm.loadPage = loadPage;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.page = 0;
    vm.transition = transition;
    vm.itemsPerPage = paginationConstants.itemsPerPage;
    vm.clear = clear;
    vm.search = search;
    vm.loadAll = loadAll;
    vm.searchQuery = pagingParams.search;
    vm.currentSearch = pagingParams.search;
    vm.isAdmin = null;
    vm.accountId = null;
    $scope.stateData.vm = vm;
    checkAccess();
    vm.abbreviate = DataUtils.abbreviate;
    vm.setViewType = setViewType;
    vm.links = {
      last: 0
    };
    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.accountId = account.id;
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    vm.hasCards = function (shop) {
      var response = 'no';
      const prefix = 'garanteasyApp.shop.list.';
      if (shop.merchant && shop.merchant.cardTypes) {
        response = shop.merchant.cardTypes.length ? 'yes' : 'no';
      }
      return $translate.instant(prefix + response);
    }

    vm.oldSearch = undefined;
    checkGrid();
    function checkGrid() {
      if ($localStorage.shopView) {
        vm.setViewType($localStorage.shopView);
      }
    }

    function setViewType(type) {
      if (type === "grid") {
        $localStorage.shopView = "grid";
        vm.gridView = true;
      }
      if (type === "list") {
        $localStorage.shopView = "list";
        vm.gridView = false;
      }
    }
    loadAll();

    function loadAll(stringSearch) {
      if (vm.isLoading) return;
      $scope.globalSearchChangeHandled = true;
      vm.isLoading = true;
      vm.currentSearch = stringSearch || vm.oldSearch;
      if (vm.currentSearch) {
        ShopSearch.query({
          query: vm.currentSearch,
          page: vm.page,
          size: vm.itemsPerPage,
          sort: sort()
        }, onSuccess, onError);
      } else {
        Shop.query({
          page: vm.page,
          size: vm.itemsPerPage,
          sort: sort()
        }, onSuccess, onError);
      }
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        if (vm.predicate !== 'id') {
          result.push('id');
        }
        return result;
      }
      function onSuccess(data, headers) {
        vm.links = ParseLinks.parse(headers('link'));
        vm.totalItems = headers('X-Total-Count');
        vm.queryCount = vm.totalItems;
        loadCmsShopData(data);
        vm.shops = vm.shops.concat(data);
        vm.isLoading = false;
      }
      function onError(error) {
        vm.isLoading = false;
        $log.error("Errore shop", error);
        AlertService.error(error.data.message);
      }
    }

    function loadCmsShopData(loadedData) {

      var shopMap = new Map();
      var queryString = "";
      for (var i = 0; i < loadedData.length; i++) {
        if (loadedData[i] && loadedData[i].shop && loadedData[i].shop.cmsReference) {
          var shopId = loadedData[i].shop.id;
          var cmsReference = loadedData[i].shop.cmsReference;
          shopMap.set(shopId, cmsReference);
          queryString += cmsReference + ",";
        }
      }

      if (queryString.length != 0) {
        queryString = queryString.substring(0, queryString.length - 1);
        CmsShopService.getShopData(queryString).then(function (response) {
          var resultMap = new Map();
          if (response) {
            for (var i = 0; i < response.length; i++) {
              if (response[i].title) {
                resultMap.set(response[i].uuid, response[i]);
              }
            }
            for (var i = 0; i < loadedData.length; i++) {
              if (loadedData[i] && loadedData[i].shop && loadedData[i].shop.id) {
                var shopCmsUuid = loadedData[i].shop.cmsReference;
                if (resultMap.has(shopCmsUuid)) {
                  loadedData[i].cmsShopData = resultMap.get(shopCmsUuid);
                }
              }
            }
          }
        });
      }
    }

    function loadPage(page) {
      vm.page = page;
      vm.loadAll();
    }

    function transition() {
      reset();
      vm.loadAll();
    }

    function reset() {
      vm.page = 0;
      vm.shops = [];
    }

    function search(searchQuery) {
      if (!searchQuery) {
        return vm.clear();
      }
      vm.links = null;
      vm.page = 1;
      vm.predicate = '_score';
      vm.reverse = false;
      vm.currentSearch = searchQuery;
      vm.transition();
    }

    function clear() {
      vm.links = null;
      vm.page = 1;
      vm.predicate = 'merchant.owner.name';
      vm.reverse = true;
      vm.currentSearch = null;
      vm.transition();
    }

    $scope.$on('$destroy', function (event, next, current) {
      $rootScope.querySearch = '';
      $rootScope.showSearchBar = false;
      $rootScope.showSearchBar = false;
      // $rootScope.inputSearch = false;
    });

  }
})();
