(function () {
  'use strict';

  var purchasedProductComponent = {
    templateUrl: 'app/entities/purchased-product/components/purchased-product.component.html',
    controller: PurchasedProductController,
    controllerAs: 'vm',
    bindings: {
      receipt: '<',
      onSave: '<',
      onCancel: '<',
      onError: '<',
      onChange: '<',
      hideProductList: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('purchasedProductComponent', purchasedProductComponent);

  PurchasedProductController.$inject = ['$timeout', '$scope', 'Receipt', 'ProductLookup', 'AlertService', '$log', 'DataUtils', 'BrandLookup'];

  function PurchasedProductController($timeout, $scope, Receipt, ProductLookup, AlertService, $log, DataUtils, BrandLookup) {
    var vm = this;
    vm.ALL_PRODUCTS = -1;
    vm.PREFERRED_PRODUCTS = 0;
    vm.NEW_PRODUCT = 1;
    vm.isNewProductCollapsed = vm.NEW_PRODUCT;
    vm.tmp = {};
    vm.datePickerOpenStatus = {};
    vm.datePickerOpenStatus.birthDate = false;

    vm.clear = clear;
    vm.save = save;
    vm.openCalendar = openCalendar;
    vm.deleteFile = deleteFile;
    vm.uploadFile = uploadFile;

    $scope.$watch('vm.receipt.purchasedProduct', function (value) {
      if (typeof vm.onChange === 'function') {
        vm.onChange($scope.addProductForm);
      }
    }, true);
    vm.$onInit = function () {
      if (!vm.receipt) {
        vm.receipt = {
          receiptNumber: null,
          counterCode: null,
          description: null,
          creationDate: null,
          buyingDate: null,
          paymentType: null,
          source: 'DATAENTRY',
          id: null,
          purchasedProducts: []
        };
      }
      if (!vm.purchasedProduct) {
        vm.receipt.purchasedProduct = {};
        vm.receipt.purchasedProduct.deliveryDate = vm.receipt.buyingDate;
      }
      //if (!vm.purchasedProducts) {
      // vm.receipt.purchasedProducts = [];
      //}
      angular.copy(vm.receipt, vm.tmp);
    };

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

    function clear() {
      if (typeof vm.onCancel === 'function') {
        angular.copy(vm.tmp, vm.receipt);
        vm.onCancel();
      }
    }

    function save() {
      vm.isSaving = true;
      if (vm.receipt.id !== null) {
        Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
      } else {
        Receipt.save(vm.receipt, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      $scope.$emit('garanteasyApp:receiptUpdate', result);
      if (typeof vm.onSave === 'function') {
        vm.onSave(result);
      }
      vm.isSaving = false;
    }

    function onSaveError(error) {
      if (typeof vm.onError === 'function') {
        vm.onError(error);
      }
      vm.isSaving = false;
    }

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    function deleteFile(receiptFile) {
      var index;
      index = vm.receipt.purchasedProduct.receiptFiles.indexOf(receiptFile);
      return vm.receipt.purchasedProduct.receiptFiles.splice(index, 1);
    }

    function uploadFile($file, isProduct) {
      vm.receiptFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.receiptFile.receiptId = null;
          vm.receiptFile.fileData = null;
          vm.receiptFile.contentType = null;
          vm.receiptFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.toBase64($file, function (base64Data) {
            $scope.$apply(function () {
              // vm.receiptFile.receiptId = vm.receipt.id;
              vm.receiptFile.fileData = base64Data;
              vm.receiptFile.fileContentType = $file.type;
              vm.receiptFile.fileName = $file.name;
              if (!vm.receipt.purchasedProduct.receiptFiles) {
                vm.receipt.purchasedProduct.receiptFiles = [];
              }
              vm.receipt.purchasedProduct.receiptFiles.push(vm.receiptFile);
            });
          });
        }
      }
    }

    $scope.loadProducts = function (searchQuery) {
      if (vm.isNewProductCollapsed === vm.PREFERRED_PRODUCTS) {
        ProductLookup.query({}, function (results) {
          vm.products = results;
        });
      } else {
        ProductLookup.query({
          query: searchQuery
        },
          function (result) {
            vm.products = result;
            angular.forEach(vm.products, function (product, key) {
              product.description = String(product.description).replace(/<[^>]+>/gm, '');
            });
          },
          function (response) {
            $log.error("Error in productlokup", response);
            AlertService.error("Error: " + response.status);
          }
        );
      }
    };

    $scope.loadBrands = function (searchQuery, producer) {
      if (producer && producer.code) {
        searchQuery = '\'(owner.code:' + producer.code + ')AND(official:1)\'' + searchQuery;
      }
      BrandLookup.query({ query: searchQuery },
        function (result) {
          $scope.brands = result;
        }
      );
    };

    $scope.onKeyPressBrand = function (search) {
      vm.brandSearch = search;
    };

  }
})();
