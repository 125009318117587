angular
  .module('garanteasyApp')
  .component('warrantyBoxComponent', {
    templateUrl: 'app/entities/purchased-product-warranty/components/warranty-box-component.html',
    controller: WarrantyBoxController,
    controllerAs: 'vm',
    bindings: {
      purchasedProductWarranty: '<',
      hideExpandButton: '<',
      isNewWarranty: '<',
      hideEditButton: '<',
      forcedExtraTitle: '<',
      relatedProductBrandLogo: '<'
    }
  });

WarrantyBoxController.$inject = ['$scope', 'Receipt', 'Warranty', 'PurchasedProductWarranty', 'CmsWarrantyService', 'CMS_URI', 'CmsService', 'CmsShopService', 'PurchasedProduct'];

function WarrantyBoxController($scope, Receipt, Warranty, PurchasedProductWarranty, CmsWarrantyService, CMS_URI, CmsService, CmsShopService, PurchasedProduct) {
  var vm = this;
  vm.name = 'purchased.product.warranty.detail.controller';
  vm.receipt;
  vm.purchasedProduct;
  vm.isOwner = null;
  vm.isAdmin = null;
  vm.edit = false;
  vm.currentUserId;
  vm.onUpdateWarranty = onUpdateWarranty;
  vm.editWarranty = editWarranty;
  vm.cancelEdit = cancelEdit;
  vm.saveUpdatePurchasedProductWarranty = saveUpdatePurchasedProductWarranty;
  vm.onSave = onSave;
  vm.oldWarranty = null;
  vm.isOfficial;
  vm.resolveGuarantor = resolveGuarantor;
  vm.resolveWarrantyDescription = resolveWarrantyDescription;
  vm.cmsWarrantyData;
  vm.resolveCmsData = resolveCmsData;
  vm.productBrandName = "";
  vm.productBrandLogo = "";
  vm.cmsUri = CMS_URI;
  vm.saveSecond = function () {
    Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
  };
  vm.openCalendar = openCalendar;
  vm.datePickerOpenStatus = {};
  vm.datePickerOpenStatus.paymentDate = false;
  vm.convertIcon = convertIcon;
  vm.addExtraWarrantyToProduct = addExtraWarrantyToProduct;
  vm.onUpdateExtraWarranty = onUpdateExtraWarranty;
  vm.sourcePurchasedProduct;


  $scope.$watch("vm.forcedExtraTitle", function () {
    if (vm.forcedExtraTitle) {
      setModel(vm.purchasedProductWarranty.warranty);
    }
  });

  vm.$onInit = function () {
    if (vm.purchasedProductWarranty) {
      console.dir(vm.purchasedProductWarranty)
      setModel(vm.purchasedProductWarranty.warranty);
      vm.receipt = vm.purchasedProductWarranty.receipt;
      vm.purchasedProduct = vm.purchasedProductWarranty.purchasedProduct;
      vm.isOfficial = vm.purchasedProductWarranty.warranty.official;
      //console.debug("On init, pre loadExtraWarrantySource")
      loadExtraWarrantySource();
      fixDays();
    }
  };

  function loadExtraWarrantySource() {
    //console.debug("LOADEXTRa", vm.purchasedProductWarranty);
    if (vm.purchasedProductWarranty && vm.purchasedProductWarranty.warranty && vm.purchasedProductWarranty.sourcePurchasedProductId && (vm.purchasedProductWarranty.warranty.warrantyType == 'EXTRA' || vm.purchasedProductWarranty.warranty.warrantyType == 'EXTENSION')) {
      var sourceId = vm.purchasedProductWarranty.sourcePurchasedProductId;
        PurchasedProduct.get({ id: sourceId }).$promise.then(function (res) {
          vm.sourcePurchasedProduct = res;
          resolveCmsData();
        })
    } else {
      resolveCmsData();
    }
  }


  vm.getWarrantyValidity = function () {
    if (vm.purchasedProductWarranty.warranty.validityUnit == 'YEARS') {
      return vm.purchasedProductWarranty.warranty.validity * 12;
    } else {
      return vm.purchasedProductWarranty.warranty.validity;
    }
  }


  function setModel(result) {
    switch (result.contractType) {
      case "CONVENTIONAL":
        vm.warrentyModel = {
          'image': 'azienda.png',
          'icon': 'garanteasy-fabbricabucato',
          'title': 'garanteasyApp.receipt.detail.infoConventionalWarrantyMobile',
          'description': 'garanteasyApp.warranty.box.conventionalWarrantyInfo'
        };
        break;
      case "LEGAL":
        vm.warrentyModel = {
          'image': 'bilancia_scudo_bianco.png',
          'icon': 'garanteasy-bilanciabucata',
          'title': 'garanteasyApp.receipt.detail.infoLegalWarrantyMobile',
          'description': 'garanteasyApp.warranty.box.legalWarrantyInfo',
          'isLegal': true
        };
        break;
      case "INSURANCE_POLICY":
        vm.warrentyModel = {
          'image': 'ombrello_scudo_bianco.png',
          'icon': 'garanteasy-ombrellobucato',
          'title': 'garanteasyApp.receipt.detail.infoInsuranceWarrantyMobile',
          'description': 'garanteasyApp.warranty.box.extensionWarrantyInfo'
        };
        break;
      case "SERVICE_CONTRACT":
        vm.warrentyModel = {
          'image': 'assistenza_1scudo_bianco.png',
          'icon': 'garanteasy-assistenzabucata',
          'title': 'garanteasyApp.receipt.detail.infoServiceContractWarranryMobile',
          'description': 'garanteasyApp.warranty.box.extensionWarrantyInfo'
        };
        break;
      default:
        vm.warrentyModel = {
          'image': 'azienda.png',
          'icon': 'garanteasy-fabbricabucato',
          'title': 'garanteasyApp.receipt.detail.infoLegalWarrantyMobile',
          'description': ''
        };

    }
    if (vm.forcedExtraTitle || result.warrantyType == 'EXTENSION') {
      vm.warrentyModel.title = "Estensione di garanzia";
    }
  }


  function openCalendar(date) {
    vm.datePickerOpenStatus[date] = true;
  }

  function convertIcon(warrantyExtension) {
    if (warrantyExtension) {
      if (warrantyExtension.warranty.guarantorType === 'PRODUCER') {
        return "../../../content/images/icone_new/icone_sfondo_bianco/azienda.png";
      }
      if (warrantyExtension.warranty.guarantorType === 'INSURANCE') {
        return "../../../content/images/icone_new/icone_sfondo_bianco/ombrello_scudo_bianco.png";
      }
      if (warrantyExtension.warranty.guarantorType === 'ASSISTANCE_CENTER') {
        return "../../../content/images/icone_new/icone_sfondo_bianco/assistenza_1scudo_bianco.png";
      }
    }
    return "../../../content/images/icone_new/icone_sfondo_bianco/punto_domanda.png";
  }

  function addExtraWarrantyToProduct() {
    if (!vm.purchasedProduct.purchasedProductWarranties)
      vm.purchasedProduct.purchasedProductWarranties = [];
    vm.purchasedProduct.purchasedProductWarranties.push(vm.purchasedProduct.warrantyExtension);
    vm.purchasedProduct.warrantyExtension = {};
  }

  function onUpdateExtraWarranty(purchasedProductWarranty) {
    vm.purchasedProduct.warrantyExtension = Object.assign({}, purchasedProductWarranty);
  }


  function editWarranty() {
    if (!vm.isOfficial && !vm.isNewWarranty) {
      vm.oldWarranty = angular.copy(vm.purchasedProductWarranty);
      vm.edit = true;
    }
  }

  function fixDays() {
    if (vm.purchasedProductWarranty.status === 'valid' || vm.purchasedProductWarranty.status === 'expiring' && vm.purchasedProductWarranty.expirationDate) {
      const one_day = 1000 * 60 * 60 * 24;
      var dateTo = new Date(vm.purchasedProductWarranty.expirationDate);
      vm.purchasedProductWarranty.dayToExpiration = Math.round((dateTo.getTime() - new Date()) / one_day);
    }
  }

  function resolveWarrantyDescription() {
    if (!vm.purchasedProductWarranty) {
      return;
    }
    if (vm.purchasedProductWarranty.warranty.contractType === 'CONVENTIONAL' || vm.purchasedProductWarranty.warranty.contractType === 'SERVICE_CONTRACT' || vm.purchasedProductWarranty.warranty.contractType === 'INSURANCE_POLICY') {
      return vm.purchasedProductWarranty.warranty.description;
    }
  }

  function resolveGuarantor(purchasedProductWarranty) {

    if (!purchasedProductWarranty || !purchasedProductWarranty.warranty) {
      return;
    }
    if (purchasedProductWarranty.warranty.contractType === 'LEGAL') {
      if (purchasedProductWarranty.receipt && purchasedProductWarranty.receipt.shop && purchasedProductWarranty.receipt.shop.description) {
        return purchasedProductWarranty.receipt.shop.description;
      }
    }
    if (purchasedProductWarranty.warranty.contractType === 'CONVENTIONAL') {
      if (vm.cmsWarrantyData && vm.cmsWarrantyData.holder_company) {
        return vm.cmsWarrantyData.holder_company;
      }
      if (purchasedProductWarranty.purchasedProduct && purchasedProductWarranty.purchasedProduct.product && purchasedProductWarranty.purchasedProduct.product.producer && purchasedProductWarranty.purchasedProduct.product.producer.name) {
        return purchasedProductWarranty.purchasedProduct.product.producer.name;
      }
    }
    if (purchasedProductWarranty.warranty.warrantyType === 'EXTRA') {
      return purchasedProductWarranty.warranty.description;
    }
    if (purchasedProductWarranty.warranty.warrantyType === 'EXTENSION') {
      if (vm.cmsWarrantyData && vm.cmsWarrantyData.holder_company) {
        return vm.cmsWarrantyData.holder_company;
      }
      return purchasedProductWarranty.warranty.description;
    }
  }

  function cancelEdit() {
    vm.purchasedProductWarranty = vm.oldWarranty;
    vm.oldWarranty = null;
    vm.edit = false;
  }

  function onUpdateWarranty(purchasedProductWarranty, valid) {
    if (!purchasedProductWarranty) {
      return;
    }
  }

  vm.getReference = function () {
    if (vm.purchasedProductWarranty && vm.purchasedProductWarranty.warranty && vm.purchasedProductWarranty.warranty.cmsReference) {
      return vm.purchasedProductWarranty.warranty.cmsReference;
    }
  }


  function resolveCmsData() {
    //vm.purchasedProductWarranty.warranty.cmsReference = '0b7207fc-2215-40e1-be65-7ca3b8efcbfe';
    //console.debug("RESOLVECMSDATA", vm.purchasedProductWarranty.warranty);
    if (vm.purchasedProductWarranty && vm.purchasedProductWarranty.warranty && vm.purchasedProductWarranty.warranty.cmsReference) {
      //load data from cms
      if (vm.purchasedProductWarranty.warranty.warrantyType === 'EXTENSION') {
        //console.debug("EXTENSION", vm.purchasedProductWarranty);
        CmsWarrantyService.getExtensionData(vm.purchasedProductWarranty.warranty.cmsReference).then(function (response) {
          if (response) {
            vm.cmsWarrantyData = response[0];
          }
        });
      } else {
        //console.debug("OTHER", vm.purchasedProductWarranty);
        CmsWarrantyService.getWarrantyData(vm.purchasedProductWarranty.warranty.cmsReference).then(function (response) {
          if (response) {
            vm.cmsWarrantyData = response[0];
            if (vm.cmsWarrantyData) {
              if (vm.purchasedProductWarranty.warranty.contractType === 'LEGAL') {
                var companyUuid = vm.cmsWarrantyData.holder_company_uuid;
                if (companyUuid) {
                  CmsShopService.getCompanyData(companyUuid).then(function (responseCompany) {
                    vm.productBrandLogo = CMS_URI + responseCompany[0].brand_image_full;
                  });

                }
              }
            }
          }
        });
      }
    }
    if (vm.purchasedProductWarranty.warranty.warrantyType === 'EXTRA' || vm.purchasedProductWarranty.warranty.warrantyType === 'EXTENSION') {
      if (vm.sourcePurchasedProduct && vm.sourcePurchasedProduct.product && vm.sourcePurchasedProduct.product.brand)
        var brandCode = vm.sourcePurchasedProduct.product.brand.code;
      if (brandCode) {
        CmsService.getBrandByCode(brandCode).then(function (responseBrand) {
          if (responseBrand[0].brand_image_full && responseBrand[0].brand_image_full.length) {
            vm.productBrandLogo = CMS_URI + responseBrand[0].brand_image_full;
          }
        });
      }
    }
    if (vm.purchasedProductWarranty.warranty.contractType === 'CONVENTIONAL') {
      if (vm.purchasedProductWarranty.purchasedProduct && vm.purchasedProductWarranty.purchasedProduct.product && vm.purchasedProductWarranty.purchasedProduct.product.brand.code) {
        CmsService.getBrandByCode(vm.purchasedProductWarranty.purchasedProduct.product.brand.code).then(function (data) {
          if (data[0] && data[0].brand_image_full)
            vm.productBrandLogo = CMS_URI + data[0].brand_image_full;
        });
      }
    }
  }

  function onSave() {
    vm.saveUpdatePurchasedProductWarranty(vm.purchasedProductWarranty, vm.oldWarranty).then(function (res) {
      onSaveSuccess(res);
    }).catch(function (error) {
      onSaveError();
    });
  }

  function onSaveSuccess(purchasedProductWarranty) {
    vm.edit = false;
  }

  function onSaveError() {

  }


  function saveUpdatePurchasedProductWarranty(purchasedProductWarranty, old) {
    delete purchasedProductWarranty.receipt;
    var promise = new Promise(function (resolve) {
      resolve(purchasedProductWarranty);
    });
    if (!purchasedProductWarranty.warranty.id) {
      promise = Warranty.save(purchasedProductWarranty.warranty).$promise;
    } else if (!purchasedProductWarranty.warranty.official && purchasedProductWarranty.warranty.id && purchasedProductWarranty.warranty.code === 'CUSTOM') {
      promise = Warranty.update(purchasedProductWarranty.warranty).$promise.then(function (result) {
        purchasedProductWarranty.warranty = result;
        return purchasedProductWarranty;
      });
    }
    if (!purchasedProductWarranty.id) {
      promise = promise.then(function (warranty) {
        purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || purchasedProductWarranty.warranty.id || warranty.id;
        return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
      });
    } else if (purchasedProductWarranty.id && old && purchasedProductWarranty.warranty.id !== old.warranty.id) {
      promise = promise.then(function (warranty) {
        return PurchasedProductWarranty.delete({
          id: old.id
        }).$promise;
      }).then(function (warranty) {
        purchasedProductWarranty.id = null;
        purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || purchasedProductWarranty.warranty.id || warranty.id;
        return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
      });
    }
    return promise.then(function (result) {
      purchasedProductWarranty = result;
      return purchasedProductWarranty;
    });
  }

}

