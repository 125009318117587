'use strict';
(function () {

    angular
        .module('garanteasyApp')
        .controller('PurchasedProductController', PurchasedProductController);

    PurchasedProductController.$inject = ['$scope', '$state', '$stateParams', 'Principal', 'PurchasedProduct', 'PurchasedProductSearch', 'ParseLinks', 'AlertService', 'paginationConstants', '$rootScope', 'Searchbar', 'DataUtils', 'AuthServerProvider', 'pagingParams', '$localStorage', 'PurchasedProductWarranty', 'CmsShopService', 'ExtensionService', '$translate'];

    function PurchasedProductController($scope, $state, $stateParams, Principal, PurchasedProduct, PurchasedProductSearch, ParseLinks, AlertService, paginationConstants, $rootScope, Searchbar, DataUtils, AuthServerProvider, pagingParams, $localStorage, PurchasedProductWarranty, CmsShopService, ExtensionService, $translate) {
        var vm = this;
        vm.id = Date.now();

        vm.receiptId = $rootScope.receiptId;
        $scope.stateData.vm = vm;
        $rootScope.showSearchBar = true;
        vm.gridView = $rootScope.gridView;
        // $scope.gridView = false;
        vm.validWarranties = 0;
        vm.expiringWarranties = 0;
        vm.expiredWarranties = 0;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        // vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.isLoading = false;
        vm.page = 0;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.isAdmin = null;
        vm.abbreviate = DataUtils.abbreviate;
        vm.canShow = canShow;
        vm.getImageUrl = getImageUrl;
        vm.setImageProduct = setImageProduct;
        vm.imagesPath = {};
        vm.reset = reset;
        vm.setViewType = setViewType;
        vm.onSave = onSave;
        vm.extension = null;

        vm.purchasedProducts = [];
        vm.links = {
            last: 0
        };
        vm.getWarrantiesStatus = getWarrantiesStatus;

        init();

        function init() {
            if ($stateParams.extensionId)
                PurchasedProductWarranty.get({ id: $stateParams.extensionId }, onSuccessLoadExtension, onErrorLoadExtension);
        }

        function onSuccessLoadExtension(result) {
            vm.extension = result;
        }
        function onErrorLoadExtension(result) {

        }

        function getWarrantiesStatus(arr) {

            var valid = arr
                .filter(function (x) { return x.status === 'valid'; });
            if (valid.length) {
                return 'valid';
            }
            valid = arr
                .filter(function (x) { return x.status === 'aboutToExpire'; });
            if (valid.length) {
                return 'aboutToExpire';
            }
            valid = arr
                .filter(function (x) { return x.status === 'expired'; });
            if (valid.length) {
                return 'expired';
            }
            return null;
        }

        function reset() {
            vm.page = 0;
            vm.purchasedProducts = [];
            loadAll();
        }

        vm.getShopType = function (product) {
            if (product && product.receipt && product.receipt.shop && product.receipt.shop.cmsShopData) {
                return product.receipt.shop.cmsShopData;
            }
        }

        checkAccess();

        checkGrid();
        function checkGrid() {
            if ($localStorage.purchasedProductView) {
                vm.setViewType($localStorage.purchasedProductView);
            }
        }

        function setViewType(type) {
            if (type === "grid") {
                $localStorage.purchasedProductView = "grid";
                vm.gridView = true;
            }
            if (type === "list") {
                $localStorage.purchasedProductView = "list";
                vm.gridView = false;
            }
        }

        function loadCmsShopData(loadedData) {

            var shopMap = new Map();
            var queryString = "";
            for (var i = 0; i < loadedData.length; i++) {
                if (loadedData[i] && loadedData[i].receipt && loadedData[i].receipt.shop && loadedData[i].receipt.shop.cmsReference) {
                    var shopId = loadedData[i].receipt.shop.id;
                    var cmsReference = loadedData[i].receipt.shop.cmsReference;
                    shopMap.set(shopId, cmsReference);
                    queryString += cmsReference + ",";
                }
            }

            if (queryString.length != 0) {
                queryString = queryString.substring(0, queryString.length - 1);
                CmsShopService.getShopData(queryString).then(function (response) {
                    var resultMap = new Map();
                    if (response) {
                        for (var i = 0; i < response.length; i++) {
                            if (response[i].title) {
                                resultMap.set(response[i].uuid, response[i]);
                            }
                        }
                        for (var i = 0; i < loadedData.length; i++) {
                            if (loadedData[i] && loadedData[i].receipt && loadedData[i].receipt.shop && loadedData[i].receipt.shop.id) {
                                var shopCmsUuid = loadedData[i].receipt.shop.cmsReference;
                                if (resultMap.has(shopCmsUuid)) {

                                    loadedData[i].receipt.shop.cmsShopData = resultMap.get(shopCmsUuid);
                                }
                            }
                        }
                    }
                });
            }
        }

        function onSave(val) {
            //PurchasedProductWarranty.save(vm.extension, onPurchasedProductWarrantySaveSuccess, onPurchasedProductWarrantySaveError);
            ExtensionService.linkWarrantyToPurchasedProduct(vm.extension.sourcePurchasedProductId, val).then(onPurchasedProductWarrantySaveSuccess, onPurchasedProductWarrantySaveError);

        }

        function onPurchasedProductWarrantySaveSuccess(result) {
            $translate("garanteasyApp.warranty.extension.associationCompleted").then(function (t) {
                AlertService.success(t);
            });
            $state.go('purchased-product-warranty', null, { reload: true });
        }
        function onPurchasedProductWarrantySaveError(result) {

        }

        function checkAccess() {
            Principal.identity().then(function (account) {
                vm.currentUserId = account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }

        vm.totalNumExpired = 0;
        vm.totalNumValid = 0;
        vm.totalNumExpiring = 0;

        vm.oldSearch = undefined;

        setTimeout(function () {
            if (!$scope.globalSearchChangeHandled) {
                loadAll();
            }
        }, 500);

        function loadAll(stringSearch) {
            if (vm.isLoading) return;
            $scope.globalSearchChangeHandled = true;
            vm.isLoading = true;
            vm.currentSearch = stringSearch || vm.oldSearch;

            if (vm.currentSearch) {
                PurchasedProductSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                PurchasedProduct.query({
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                // vm.queryCount = vm.totalItems;

                for (var i = 0; i < data.length; i++) {
                    if (data[i].product.underWarranty) {
                        if (data[i].totalWarranties) {
                            vm.totalNumExpired += data[i].totalWarranties.numExpired;
                            vm.totalNumValid += data[i].totalWarranties.numValid;
                            vm.totalNumExpiring += data[i].totalWarranties.numExpiring;
                            vm.validWarranties += data[i].totalWarranties.numValid;
                            vm.expiringWarranties += data[i].totalWarranties.numExpiring;
                            vm.expiredWarranties += data[i].totalWarranties.numExpired;
                        }
                        vm.setImageProduct(data[i]);
                        vm.purchasedProducts.push(data[i]);
                    }
                }
                loadCmsShopData(data);
                vm.isLoading = false;
            }

            function onError(error) {
                vm.isLoading = false;
                AlertService.error(error.message ? error.message : error.data.message);
            }
        }

        function loadPage(page) {
            if (vm.page != page) {
                vm.page = page;
                loadAll();
            }
        }




        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.purchasedProducts = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        vm.ssize = function () {
            return '/xs';
        }

        function clear() {
            vm.purchasedProducts = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function canShow(contentType) {
            return contentType != null && contentType.toLowerCase().indexOf('image') == 0 && contentType.toLowerCase().indexOf('tiff') == -1;
        }

        function getImageUrl(receiptFile) {
            if (!receiptFile || !receiptFile.id) {
                vm.imagesPath[receiptFile.id] = null;
            } else
                if (_.isUndefined(vm.imagesPath[receiptFile.id])) {
                    vm.imagesPath[receiptFile.id] = "api/receipt-files/content/" + receiptFile.id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
                }
            return vm.imagesPath[receiptFile.id];
        }

        function setImageProduct(purchasedProduct) {
            if (purchasedProduct.receiptFiles && purchasedProduct.receiptFiles.length) {
                var file = purchasedProduct.receiptFiles.find(function (file) { return file.purchasedProductId === purchasedProduct.id; });
                if (file && vm.canShow(file.fileContentType)) {
                    purchasedProduct.image = file;
                    purchasedProduct.image.src = vm.getImageUrl(purchasedProduct.image);
                    return;
                }
            }
            if (purchasedProduct.product.images && purchasedProduct.product.images.length) {
                purchasedProduct.image = purchasedProduct.product.images[0];
                if (purchasedProduct.image.localCopy === null || purchasedProduct.image.localCopy) {
                    purchasedProduct.image.src = 'api/product-resources/image/' + purchasedProduct.image.id;
                } else {
                    purchasedProduct.image.src = purchasedProduct.image.value;
                }
            }
        }

        $scope.$on('$destroy', function (event, next, current) {
            $rootScope.querySearch = '';
            $rootScope.showSearchBar = false;
            $rootScope.viewPanelSuccess = null;
            $rootScope.receiptId = null;
            // $rootScope.inputSearch = false;
        });

        /*        var unsubscribe = $rootScope.$on('garanteasyApp:productUpdate', function(event, result) {
                    vm.product = result;
                });
                $scope.$on('$destroy', unsubscribe);
                $scope.$on('$destroy', function(event, next, current) {
                    $('.zoomContainer').remove();
                });*/
    }
})();
