angular
    .module('garanteasyApp')
    .component('listItemShop', {
        templateUrl: 'app/components/list-item/shop/shop.component.html',
        controller: ListItemShopComponentController,
        controllerAs: 'vm',
        bindings: {
            shop: '<'
        }
    });

ListItemShopComponentController.$inject = ['DataUtils'];

function ListItemShopComponentController(DataUtils) {
    var vm = this;
    vm.abbreviate = DataUtils.abbreviate;
    vm.getImage = function () {
        if (vm.shop.merchant.logoPath) {
            return vm.shop.merchant.logoPath;
        } else if (vm.shop.ecommerceUrl) {
            return '/content/images/icone_new/icone_sfondo_bianco/shop-generico.png';
        } else {
            return '/content/images/icone_new/icone_sfondo_bianco/shop-fisico.png';
        }
    };
}
