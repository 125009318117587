(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('UnidentifiedUserDetailController', UnidentifiedUserDetailController);

    UnidentifiedUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'UnidentifiedUser', 'Gift', 'Receipt'];

    function UnidentifiedUserDetailController($scope, $rootScope, $stateParams, previousState, entity, UnidentifiedUser, Gift, Receipt) {
        var vm = this;

        vm.unidentifiedUser = entity;

        if (typeof previousState.params.id != 'undefined') {
            vm.previousState = previousState.name+'({id: \''+previousState.params.id+'\'})';
        } else {
        	vm.previousState = previousState.name;
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:unidentifiedUserUpdate', function(event, result) {
            vm.unidentifiedUser = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
