(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('CompanyLookup', CompanyLookup);

    CompanyLookup.$inject = ['$resource'];

    function CompanyLookup($resource) {
        var resourceUrl =  'api/_lookup/companies';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
