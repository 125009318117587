

$( document ).ready(function() {

    /*$('[data-fancybox="gallery"]').fancybox();*/

    $('[data-action="download-image"]').bind('click',function(e){
        e.stopPropagation();
        e.preventDefault();
        //$('[data-action="main-image-url"]').click();
        window.open($('#main-image').attr("href"),'_blank','','');
    });

    $('[data-action="show-more"]').bind('click',function(e){
        var el = $(this).siblings('.detail-text'),
            curHeight = el.height(),
            autoHeight = el.css('height', 'auto').height();

        if(!$(this).hasClass('open')){
        	 $(this).addClass('open');        	
            el.height(curHeight).animate({height: 73}, 500);
        }
        else{
        	 $(this).removeClass('open');
            el.height(curHeight).animate({height: autoHeight}, 500);
        }
       
        var old_title = $(this).text();
        $(this).text($(this).data('title'));
        $(this).data('title',old_title);
    });

    $('[data-action="print"]').bind('click',function(e){
        print();
        //window.print();
        //$('.main-wrapper').printThis();
    });
});
