(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ReceiptFileController', ReceiptFileController);

    ReceiptFileController.$inject = ['$scope', '$state', 'ReceiptFile', 'ReceiptFileSearch'];

    function ReceiptFileController ($scope, $state, ReceiptFile, ReceiptFileSearch) {
        var vm = this;
        
        vm.receiptFiles = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ReceiptFile.query(function(result) {
                vm.receiptFiles = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ReceiptFileSearch.query({query: vm.searchQuery}, function(result) {
                vm.receiptFiles = result;
            });
        }    }
})();
