angular
    .module('garanteasyApp')
    .component('listItemReceipt', {
        templateUrl: 'app/components/list-item/receipt/receipt.component.html',
        controller: ListItemReceiptComponentController,
        controllerAs: 'vm',
        bindings: {
            receipt: '<'
        }
    });

ListItemReceiptComponentController.$inject = ['AuthServerProvider'];

function ListItemReceiptComponentController(AuthServerProvider) {
    var vm = this;
    vm.getImageUrl = function (receipt) {
        if (((receipt || {}).receiptFiles || {}).length > 0) {
            var image = _.find(receipt.receiptFiles, function (o) { return o.fileContentType.startsWith('image/'); });
            if (image) {
                return "api/receipt-files/content/" + image.id + "?width=-&height=150&token=" + AuthServerProvider.getToken();
            }
        } else {
            // Se manca l'immagine della ricevuta mostro quella del prodotto.
            if ((receipt || {}).purchasedProducts) {
                receipt.purchasedProducts.forEach(function (purchasedProduct) {
                    if (purchasedProduct.product && purchasedProduct.product.images[0]) {
                        return purchasedProduct.product.images[0].value;
                    }
                });
            }
        }

    };
}
