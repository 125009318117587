angular
    .module('garanteasyApp')
    .component('cardShop', {
        templateUrl: 'app/components/cards/shop/shop.component.html',
        controller: CardShopComponentController,
        controllerAs: 'vm',
        bindings: {
            shopElement: '<',
            cmsShop: '<'
        }
    });

CardShopComponentController.$inject = ['DataUtils', '$scope', 'CMS_URI', '$translate'];

function CardShopComponentController(DataUtils, $scope, CMS_URI, $translate) {
    var vm = this;
    vm.abbreviate = DataUtils.abbreviate;
    vm.isPhysicalShop = isPhysicalShop;

    vm.hasCards = function () {
        var response = 'no';
        const prefix = 'garanteasyApp.shop.list.';
        if (vm.shopElement.merchant && vm.shopElement.merchant.cardTypes) {
            response = vm.shopElement.merchant.cardTypes.length ? 'yes' : 'no';
        }
        return $translate.instant(prefix + response);
    }

    function isPhysicalShop() {
        if (vm.cmsShop && vm.cmsShop.shop_type)
            if (vm.cmsShop.shop_type === "PHYSICAL") {
                return true;
            }
    }

    vm.getImage = function () {
        if (vm.shop) {

            if (vm.cmsShop && (vm.cmsShop.signboard_logo || vm.cmsShop.signboard_image)) {
                if (vm.cmsShop.signboard_logo) {
                    return (CMS_URI + vm.cmsShop.signboard_logo);
                } else {
                    return (CMS_URI + vm.cmsShop.signboard_image);
                }

            } else {

                if (vm.shop.merchant.logoPath) {
                    return vm.shop.merchant.logoPath;
                } else if (vm.shop.ecommerceUrl) {
                    return '/content/images/icone_new/icone_sfondo_bianco/shop-generico.png';
                } else {
                    return '/content/images/icone_new/icone_sfondo_bianco/shop-fisico.png';
                }
            }
        }
    };

    $scope.$watch("vm.shopElement", function () {
        if (vm.shopElement && vm.shopElement.shop) {
            vm.shop = vm.shopElement.shop;

        }
    });

}
