angular
    .module('garanteasyApp')
    .component('cardWarrenty', {
        templateUrl: 'app/components/cards/warrenty/warrenty.component.html',
        controller: CardWarrentyComponentController,
        controllerAs: 'vm',
        bindings: {
            warrenty: '<'
        }
    });

CardWarrentyComponentController.$inject = ["DataUtils", "$log"];

function CardWarrentyComponentController(DataUtils, $log) {
  var vm = this;
  vm.abbreviate = DataUtils.abbreviate;
  vm.getImage = getImage;
  vm.getIcon = getIcon;
  vm.isOfficial = isOfficial;
  vm.resolveGuarantor = resolveGuarantor;

  function isOfficial() {
    return vm.warrenty.warranty.official;
  }

  function resolveGuarantor(purchasedProductWarranty) {
    if (purchasedProductWarranty.warranty.contractType === "LEGAL") {
      if (
        purchasedProductWarranty.receipt &&
        purchasedProductWarranty.receipt.shop &&
        purchasedProductWarranty.receipt.shop.description
      ) {
        return purchasedProductWarranty.receipt.shop.description;
      }
    }
    if (purchasedProductWarranty.warranty.contractType === "CONVENTIONAL") {
      if (
        purchasedProductWarranty.purchasedProduct &&
        purchasedProductWarranty.purchasedProduct.product &&
        purchasedProductWarranty.purchasedProduct.product.producer &&
        purchasedProductWarranty.purchasedProduct.product.producer.name
      ) {
        return purchasedProductWarranty.purchasedProduct.product.producer.name;
      }
      if (
        purchasedProductWarranty.warranty &&
        purchasedProductWarranty.warranty.holder_company
      ) {
        return purchasedProductWarranty.warranty.holder_company;
      }
    }
    if (
      purchasedProductWarranty.warranty.contractType === "SERVICE_CONTRACT" ||
      purchasedProductWarranty.warranty.contractType === "INSURANCE_POLICY"
    ) {
      return purchasedProductWarranty.warranty.description;
    }
  }

  function getImage(purchasedProductWarranty) {
    var item = ((purchasedProductWarranty || {}).warranty || {} || {})
      .contractType;
    if (item === "LEGAL") {
      return "garanteasy-bilanciabucata";
    } else if (item === "CONVENTIONAL") {
      return "garanteasy-edificiobucato";
    } else if (item === "SERVICE_CONTRACT") {
      return "garanteasy-assistenzabucato";
    } else if (item === "INSURANCE") {
      return "garanteasy-ombrellobucato";
    } else {
      return "garanteasy-bilanciabucata";
    }
  }

  function getIcon(purchasedProductWarranty) {
    var icon = "fa-balance-scale";
    if (purchasedProductWarranty && purchasedProductWarranty.warranty) {
      icon =
        vm.warrantyIcons[purchasedProductWarranty.warranty.warrantyType] ||
        icon;
    }
    return icon;
  }
}
