(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CardDetailController', CardDetailController);

    CardDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Card', 'User', 'Gift', 'Receipt', 'Address', 'CardCustomer', '$state'];

    function CardDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Card, User, Gift, Receipt, Address, CardCustomer, $state) {
        var vm = this;

        vm.card = entity;
        vm.cardCustomers = CardCustomer.query();

        $scope.cardCustomerVisible = function (cardCustomer) {
            return vm.isAdmin || (cardCustomer.card.id == vm.card.id);
        }

        if ($stateParams.from) {
            $scope.from = $stateParams.from
        }

        vm.setCardCustomerId = function (ccId) {
            $state.params.cardId = vm.card.id;
            $state.params.ccId = ccId;
            $state.params.id = vm.card.id;
        }

        vm.isOwner = function () {
            var ret = false;
            var account = Principal.identity();
            if (vm.card.contact && vm.card.contact.user && account.$$state && account.$$state.value.id)
                ret = vm.card.contact.user.id == account.$$state.value.id;
            return ret;
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:cardUpdate', function (event, result) {
            vm.card = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
