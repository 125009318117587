(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('UserDeletedController', UserDeletedController);

    UserDeletedController.$inject = ['LoginService','Auth', 'AuthServerProvider', 'Principal'];

    function UserDeletedController (LoginService, Auth, AuthServerProvider, Principal) {
        var vm = this;
        vm.logout = logout;
        vm.account = null;
        vm.isAuthenticated = null;
        // vm.login = LoginService.open;

        logout();

        function logout() {
            AuthServerProvider.logout();
            Principal.authenticate(null);
        }
    }
})();
