'use strict';
(function () {

    angular
        .module('garanteasyApp')
        .factory('CardTypeCmsService', CardTypeCmsService);

    CardTypeCmsService.$inject = ['$http', 'CMS_URI', '$log'];

    function CardTypeCmsService($http, CMS_URI, $log) {
        var service = {
            getCardByReference: getCardByReference,
            call: call
        };

        return service;

        function getCardByReference(cards) {
            var elements = [].concat(cards || []);
            var allCardReferences = "";
            if (elements && elements.length) {
                elements.forEach(function (card) {
                    if (card.cmsReference) {
                        if (allCardReferences.length) {
                            allCardReferences += ',';
                        }
                        allCardReferences += card.cmsReference;
                    }
                });
            }

            var uri = CMS_URI + '/api/view/fidelitycard/' + allCardReferences;
            return call(uri);
        }


        function call(uri) {
            return $http.get(uri)
                .then(getData)
                .catch(error);

            function getData(response) {
                return response.data;
            }

            function error(error) {
                $log.error('XHR Failed for cms.' + error.data);
            }
        }

    }
})();
