(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ContactDetailController', ContactDetailController);

    ContactDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Contact', 'User'];

    function ContactDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Contact, User) {
        var vm = this;

        vm.contact = entity;
        
        if($stateParams.from) {
            $scope.from = $stateParams.from
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:contactUpdate', function(event, result) {
            vm.contact = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
