(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyClaimDetailController', WarrantyClaimDetailController);

    WarrantyClaimDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'WarrantyClaim'];

    function WarrantyClaimDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, WarrantyClaim) {
        var vm = this;

        vm.warrantyClaim = entity;

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.onEdit = onEdit;
        vm.onCancelEdit = onCancelEdit;

        vm.canShow = function (contentType) {
        	return contentType != null && contentType.toLowerCase().indexOf('image') == 0 && contentType.toLowerCase().indexOf('tiff') == -1;
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:warrantyClaimUpdate', function(event, result) {
            vm.warrantyClaim = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function onEdit(){
          vm.editClaim = true;
        }

        function onCancelEdit(){
          vm.editClaim = false;
        }
    }
})();
