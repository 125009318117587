(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CardRequestUseController',CardRequestUseController);

    CardRequestUseController.$inject = ['$uibModalInstance', '$scope', 'entity', 'Card', 'CardCustomer', '$state', '$stateParams', 'Principal'];

    function CardRequestUseController($uibModalInstance, $scope, entity, Card, CardCustomer, $state, $stateParams, Principal) {
        var vm = this;
        var params = $stateParams.dataCard;
        vm.clear = clear;
        vm.confirmRequestUse = confirmRequestUse;
        vm.card = params;
        getAccount();


        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmRequestUse (id) {
            vm.isSaving = true;
            var cc = {};
            cc.card = vm.card;
            cc.contact = params.contact;
            cc.customer = vm.account;

            if (vm.postalCode){
                cc.postalCode = vm.postalCode;
                CardCustomer.connectTOUser(cc, onSaveSuccess, onSaveError);
            }
            else {
                CardCustomer.save(cc, onSaveSuccess, onSaveSuccess);
            }
        }

        function onSaveSuccess (result) {
           $scope.$emit('garanteasyApp:cardCustomerSave', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
