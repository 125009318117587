(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CardTypeDeleteController',CardTypeDeleteController);

    CardTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CardType'];

    function CardTypeDeleteController($uibModalInstance, entity, CardType) {
        var vm = this;

        vm.cardType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CardType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
