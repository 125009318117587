(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('UnidentifiedUserDeleteController',UnidentifiedUserDeleteController);

    UnidentifiedUserDeleteController.$inject = ['$uibModalInstance', 'entity', 'UnidentifiedUser'];

    function UnidentifiedUserDeleteController($uibModalInstance, entity, UnidentifiedUser) {
        var vm = this;

        vm.unidentifiedUser = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            UnidentifiedUser.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
