(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account ($resource) {
        var service = $resource('api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            },
            'deleteUser': {
                method: 'POST',
                url: 'api/account/delete_user/:login_confirm',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                    }
                    return data;
                }
            }
            // vm.cards = Card.queryActive(vm.receipt);
        });

        return service;
    }
})();
