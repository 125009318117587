(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .directive('checkGallery', checkGallery);

  checkGallery.$inject = ['GalleryCheckService'];

  function checkGallery(GalleryCheckService) {

    return {
      link: function (scope, element, attrs) {
        element.bind('error', function () {
          _.remove(GalleryCheckService.getData(), function (e) {
            return e.src == attrs.ngSrc;
          })
        });

      }
    };

  }
})();
