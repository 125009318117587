(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('WarrantyClaimNewController', WarrantyClaimNewController);

  WarrantyClaimNewController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', '$state', 'Receipt'];

  function WarrantyClaimNewController($scope, $rootScope, $stateParams, previousState, DataUtils, $state, Receipt) {
    var vm = this;
    vm.ppId;
    vm.isGeneric = false;
    vm.receiptId;
    vm.loadedReceipt;

    vm.byteSize = DataUtils.byteSize;
    vm.openFile = DataUtils.openFile;
    vm.onCancelEdit = onCancelEdit;
    vm.onSaveWarrantyClaim = onSaveWarrantyClaim;

    init();

    function init() {
      if ($stateParams) {
        if ($stateParams.idPurchasedProduct) {
          vm.ppId = $stateParams.idPurchasedProduct;
        }
        if ($stateParams.idReceipt) {
          vm.isGeneric = true;
          vm.receiptId = $stateParams.idReceipt;
          Receipt.get({ id: vm.receiptId }, onSuccessLoadReceipt, onErrorLoadReceipt);
        } 
      }
    }

    function onErrorLoadReceipt() {
      //TODO
    }

    function onSuccessLoadReceipt(receipt) {
      vm.loadedReceipt = receipt;
    }

    vm.canShow = function (contentType) {
      return contentType != null && contentType.toLowerCase().indexOf('image') == 0 && contentType.toLowerCase().indexOf('tiff') == -1;
    }

    var unsubscribe = $rootScope.$on('garanteasyApp:warrantyClaimUpdate', function (event, result) {
      vm.warrantyClaim = result;
    });
    $scope.$on('$destroy', unsubscribe);

    function onSaveWarrantyClaim() {
      $state.go('purchased-product.detail', {
        id: vm.ppId
      });
    }

    function onCancelEdit() {
      $state.go('purchased-product.detail', {
        id: vm.ppId
      });
    }
  }
})();
