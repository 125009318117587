(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('ReceiptFileSearch', ReceiptFileSearch);

    ReceiptFileSearch.$inject = ['$resource'];

    function ReceiptFileSearch($resource) {
        var resourceUrl =  'api/_search/receipt-files/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
