(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('EmailActivationController', EmailActivationController);

    EmailActivationController.$inject = ['$stateParams', 'Auth'];

    function EmailActivationController ($stateParams, Auth) {
        var vm = this;
        vm.contact = null;

        Auth.activateEmail({key: $stateParams.key}).then(function (result) {
            vm.error = null;
            vm.contact = result;
            vm.success = 'OK';
        }).catch(function () {
            vm.success = null;
            vm.error = 'ERROR';
        });
    }
})();
