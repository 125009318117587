(function () {
  'use strict';

  var cropper = {
    templateUrl: 'app/components/crop/crop.directive.html',
    controller: CropperController,
    controllerAs: 'vm',
    bindings: {
      files: '<',
      image: '<',
      portrait: '<',
      customClass: '<',
      onUpdate: '<',
      onCancel: '&'
    }
  };

  angular
    .module('garanteasyApp')
    .component('imageCropper', cropper);

  CropperController.$inject = ['$scope', '$element', '$log'];

  function CropperController($scope, $element, $log) {
    var vm = this;

    vm.createCroppie = createCroppie;
    vm.destroy = destroy;
    vm.zoomIn = zoomIn;
    vm.zoomOut = zoomOut;
    vm.rotate = rotate;
    vm.print = print;
    vm.cancel = cancel;
    vm.select = select;
    vm.isImage = isImage;
    vm.printReceipt = printReceipt;

    vm.croppie = null;
    vm.maxWidith = null;
    vm.maxHeight = 377;
    vm.selectedFile = null;

    init();

    $scope.$on('$destroy', function () {
      vm.alerts = [];
    });

    $scope.$watch("vm.image", function () {
      if (vm.image) {
        vm.destroy();
        if (vm.portrait && !(vm.selectedFile && vm.selectedFile.changed)) {
          return _forcePortrait(vm.image);
        }
        return vm.createCroppie(vm.image);
      } else if (vm.croppie) {
        return vm.destroy();
      }
    });

    $scope.$watch("vm.files", function () {
      if (vm.files && vm.files.length && !vm.selectedFile) {
        _buildSrcFiles();
        if (vm.files[0].src) {
          vm.image = vm.files[0].src;
        } else {
          vm.image = 'data:' + vm.files[0].fileContentType + ';base64,' + vm.files[0].fileData;
        }
        vm.selectedFile = vm.files[0];
      }
    }, true);

    function init() {
      var el = $element.find('.croppie-img-wrapper')[0];
      if (el) {
        vm.maxWidith = el.offsetWidth;
      }
      if (!vm.files) {
        vm.files = [];
      } else {
        _buildSrcFiles();
      }
    }

    function createCroppie(img, rotated) {
      var image = new Image;
      image.src = img;
      image.onload = function () {
        var width = image.width;
        var height = image.height;
        if (height > vm.maxHeight) {
          width = (width * vm.maxHeight / height);
          height = vm.maxHeight;
        }
        if (width > vm.maxWidith) {
          height = (height * vm.maxWidith / width);
          width = vm.maxWidith;
        }

        var myImg = $element.find('.croppie-my-image')[0];
        myImg.src = img;
        if (rotated) {
          _changedFile(img);
          if (typeof vm.onUpdate === 'function') {
            vm.onUpdate(img);
          }
        }
        vm.croppie = new Croppie(myImg, {
          viewport: {
            width: width,
            height: height
          },
          boundary: {
            width: width,
            height: height
          },
          showZoomer: false,
          enableResize: false,
          enableOrientation: true,
          enforceBoundary: true,
          enableExif: true,
          mouseWheelZoom: false
        });
      };
    }

    function zoomIn() {
      if (!vm.croppie) {
        return;
      }
      var zoom = vm.croppie.get('currentZoom');
      vm.croppie.setZoom(zoom.zoom + 0.05);
      vm.croppie.result({
        type: 'base64',
        size: 'original',
        format: 'jpeg',
        quality: 0.9
      }).then(function (result) {
        if (typeof vm.onUpdate === 'function') {
          vm.onUpdate(result);
        }
        _changedFile(result);
      });
    }

    function zoomOut() {
      if (!vm.croppie) {
        return;
      }
      var zoom = vm.croppie.get('currentZoom');
      vm.croppie.setZoom(zoom.zoom - 0.05);
      vm.croppie.result({
        type: 'base64',
        size: 'original',
        format: 'jpeg',
        quality: 0.9
      }).then(function (result) {
        if (typeof vm.onUpdate === 'function') {
          vm.onUpdate(result);
        }
        _changedFile(result);
      });
    }

    function rotate() {
      if (!vm.croppie) {
        return;
      }
      try {
        vm.croppie.destroy();
        $element.find('.croppie-img-wrapper')[0].innerHTML = $element.find('.croppie-my-image')[0].outerHTML;
        _rotateImage($element.find('.croppie-my-image')[0].src, vm.createCroppie);
      } catch (error) {
        $log.error(error);
      }
    }

    function print() {
      if (!vm.croppie) {
        return;
      }
      if (vm.selectedFile && vm.selectedFile.fileContentType === 'build') {
        return vm.printReceipt();
      }
      var source = $element.find('.croppie-my-image')[0].src;
      var popupWin = window.open('', '_blank');
      popupWin.document.open();
      var style = '@media print {@page {size: A4 portrait; }img {  max-width: 80%; margin: auto; padding: 0; }	}';
      popupWin.document.write('<html><head><style type="text/css">' + style + '</style></head><body onload="window.print()"><img style="max-width: 80%;" src="' + source + '" /></body></html>');
      popupWin.document.close();
    }

    function cancel() {
      vm.onCancel({
        file: vm.selectedFile
      });
    }

    function destroy() {
      $element.find('.croppie-my-image')[0].src = '';
      if (vm.croppie) {
        try {
          vm.croppie.destroy();
          $element.find('.croppie-img-wrapper')[0].innerHTML = $element.find('.croppie-my-image')[0].outerHTML;
        } catch (error) {
          vm.croppie = null;
        }
      }
    }

    function select(image) {
      if (vm.selectedFile.fileDataChanged) {
        vm.selectedFile.fileData = vm.selectedFile.fileDataChanged;
        vm.selectedFile.fileDataChanged = null;
        vm.selectedFile.changed = true;
      }
      vm.selectedFile = image;
      if (image.src) {
        vm.image = image.src;
      } else {
        vm.image = 'data:' + image.fileContentType + ';base64,' + image.fileData;
      }
    }

    function isImage(image) {
      return image && image.fileContentType && image.fileContentType.toLowerCase().indexOf('image') !== -1 && image.fileContentType.toLowerCase().indexOf('tiff') === -1;
    }

    function _rotateImage(srcBase64, callback) {
      var img = new Image();
      img.onload = function () {
        var width = img.width,
          height = img.height,
          canvas = document.createElement('canvas'),
          ctx = canvas.getContext("2d");

        canvas.width = height;
        canvas.height = width;

        ctx.transform(0, 1, -1, 0, height, 0);
        ctx.drawImage(img, 0, 0);
        callback(canvas.toDataURL('image/jpeg', 0.85), true);
      };
      img.src = srcBase64;
    }

    function _forcePortrait(srcBase64) {
      var img = new Image();
      img.onload = function () {
        if (img.width > img.height) {
          return _rotateImage(srcBase64, vm.createCroppie);
        } else {
          return vm.createCroppie(srcBase64);
        }

      };
      img.src = srcBase64;
    }

    function _buildSrcFiles() {
      angular.forEach(vm.files, function (element, key) {
        // Tutti ciò che non è immagine (pdf, doc, ...) deve passare per una rotta ad-hoc per evitare il filtro di ridimensionamento che altrimenti solleva una eccezione
        if (!isImage(element)) {
          element.srcDoc = '/doc/' + element.src;
          $log.debug("Selected file is NOT an image", element);
        }
      });
    }

    function _srcToFileData(data) {
      if (!data && typeof data !== 'string') {
        return '';
      }
      return data.slice(data.indexOf('base64,') + 'base64,'.length);
    }

    function _changedFile(result) {
      if (vm.selectedFile && vm.selectedFile.fileContentType && vm.selectedFile.fileData) {
        vm.selectedFile.fileDataChanged = _srcToFileData(result);
      }
    }

    function printReceipt() {
      var content = document.getElementById('receiptPrintContent').innerHTML;
      var headContent = document.getElementsByTagName('head')[0].innerHTML;
      var popupWin = window.open('', '_blank');
      popupWin.document.open();
      var style = '.receipt-print{ max-width: 600px; } @media print {@page {size: A4 portrait; } .receipt-print{ max-width: 300px; margin: auto; padding: 0; }	}';
      popupWin.document.write('<html><head>' + headContent + '<style type="text/css">' + style + '</style></head><body onload="window.print()"><div class="receipt-print">' + content + '</div></body></html>');
      popupWin.document.close();
    }
  }
})();
