(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyClaimSendController',WarrantyClaimSendController);

    WarrantyClaimSendController.$inject = ['$scope', '$uibModalInstance', 'entity', 'WarrantyClaim'];

    function WarrantyClaimSendController($scope, $uibModalInstance, entity, WarrantyClaim) {
        var vm = this;

        vm.warrantyClaim = entity;
        vm.clear = clear;
        vm.confirmSend = confirmSend;
        vm.hasShop = vm.warrantyClaim.purchasedProduct.receipt.shop && vm.warrantyClaim.purchasedProduct.receipt.shop.id;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmSend () {
        	WarrantyClaim.send(vm.warrantyClaim, onSendSuccess, onSendError);
        }

        function onSendSuccess (result) {
            $scope.$emit('garanteasyApp:warrantyClaimUpdate', result);
            $uibModalInstance.close(result);
        }

        function onSendError () {
            $uibModalInstance.close(result);
        }
    }
})();
