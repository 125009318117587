angular
    .module('garanteasyApp')
    .component('listItemWarrenty', {
        templateUrl: 'app/components/list-item/warrenty/warrenty.component.html',
        controller: ListItemWarrentyComponentController,
        controllerAs: 'vm',
        bindings: {
            warrenty: '<'
        }
    });

ListItemWarrentyComponentController.$inject = ['DataUtils'];

function ListItemWarrentyComponentController(DataUtils) {
    var vm = this;
    vm.abbreviate = DataUtils.abbreviate;
    vm.getImage = getImage;
    vm.getIcon = getIcon;
    vm.isOfficial = isOfficial;

    function isOfficial() {
        if (vm.warrenty.receipt.source === 'MERCHANT') {
            return true;
        } else {
            return false;
        }
    }

    function getImage(purchasedProductWarranty) {
        var item = (((purchasedProductWarranty || {}).warranty || {}) || {}).warrantyType;
        if (item === 'LEGAL') {
            return '/content/images/ICONA_GARANZIE_LEGALE.png';
        } else if (item === 'CONVENTIONAL') {
            return '/content/images/ICONA_GARANZIE_CONVENZIONALE.png';
        } else if (item === 'SERVICE_CONTRACT') {
            return '/content/images/ICONA_GARANZIE_ASSISTENZA.png';
        } else if (item === 'INSURANCE') {
            return '/content/images/ICONA_GARANZIE_POLIZZA.png';
        } else {
            return '/content/images/ICONA_GARANZIE_LEGALE.png';
        }
    }

    function getIcon(purchasedProductWarranty) {
        var icon = 'fa-balance-scale';
        if (purchasedProductWarranty && purchasedProductWarranty.warranty) {
            icon = vm.warrantyIcons[purchasedProductWarranty.warranty.type] || icon;
        }
        return icon;
    }
}
