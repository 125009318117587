(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyClaimFileDialogController', WarrantyClaimFileDialogController);

    WarrantyClaimFileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DataUtils', 'WarrantyClaimFile', 'WarrantyClaim'];

    function WarrantyClaimFileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DataUtils, WarrantyClaimFile, WarrantyClaim) {
        var vm = this;

        vm.warrantyClaimFile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.fileTooLarge = false;

        vm.setFile = function ($file, warrantyClaimFile) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
            	if($file.size>2048000) {
                    vm.fileTooLarge = true;
                    // Reset data in case there was a previous image selected
                	warrantyClaimFile.fileName = null;
                	warrantyClaimFile.fileData = null;
                	warrantyClaimFile.fileContentType = null;
            	} else {
                    vm.fileTooLarge = false;
	                DataUtils.toBase64($file, function(base64Data) {
	                    $scope.$apply(function() {
	                    	warrantyClaimFile.fileName = $file.name;
	                    	warrantyClaimFile.fileData = base64Data;
	                    	warrantyClaimFile.fileContentType = $file.type;
	                    });
	                });
            	}
            }
        };

        vm.canShow = function (contentType) {
        	return contentType != null && contentType.toLowerCase().indexOf('image') == 0 && contentType.toLowerCase().indexOf('tiff') == -1;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.warrantyClaimFile.id !== null) {
                WarrantyClaimFile.update(vm.warrantyClaimFile, onSaveSuccess, onSaveError);
            } else {
                WarrantyClaimFile.save(vm.warrantyClaimFile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyApp:warrantyClaimFileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
