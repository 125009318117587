'use strict';

angular
  .module('garanteasyApp')
  .component('receiptImageBuild', {
    templateUrl: 'app/components/receipt-image-build/receipt-image-build.component.html',
    controller: ReceiptImageBuild,
    controllerAs: 'vm',
    bindings: {
      receipt: '<',
      imageOnly: '<'
    }
  });

ReceiptImageBuild.$inject = ['$scope', '$element', '$log', 'AlertService', 'CmsService', '$translate', 'CMS_URI'];

function ReceiptImageBuild($scope, $element, $log, AlertService, CmsService, $translate, CMS_URI) {
  var vm = this;
  vm.buildProductsList = buildProductsList;
  vm.init = init;

  vm.logoUri;
  vm.cmsReference;
  vm.lang = 'eng';
  vm.products = [];
  vm.productsHasPrice = false;
  vm.getVatCode = getVatCode;
  vm.products;
  vm.stopBuilding = false;

  vm.init();

  function init() {
    if ($translate.use()) {
      vm.lang = $translate.use();
    }
    $scope.$on('$destroy', function () {
      $log.debug("Destroing directive");
    });

  }

  $scope.$watch("vm.receipt.purchasedProducts", function () {
    buildProductsList();
  }, true);

  $scope.$watch("vm.receipt", function () {
    if (vm.receipt) {
      getLogoImage();
    }
  });

  function getVatCode() {
    if (vm.receipt) {
      if (vm.receipt.shop && vm.receipt.shop.cmsShopData) {
        return vm.receipt.shop.cmsShopData.company_vat_code;
      }
      if (vm.receipt.shop) {
        if (vm.receipt.shop.merchant && vm.receipt.shop.merchant.vatCode) {
          return vm.receipt.shop.merchant.vatCode;
        } else {
          return vm.receipt.shop.vatCode;
        }
      }

    }
  }

  function buildProductsList() {
    if (!vm.stopBuilding) {
      vm.products = [];

      if (vm.receipt && vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length) {
        vm.stopBuilding = true;
        vm.productsHasPrice = false;
        vm.receipt.purchasedProducts.forEach(function (purchasedProduct) {
          if (purchasedProduct.transactionAmount) {
            vm.productsHasPrice = true;
          }
          var indexFound = vm.products.findIndex(function (el) {
            return el.product.id === purchasedProduct.product.id;
          });
          if (indexFound !== -1) {
            vm.products[indexFound].quantity++;
            vm.products[indexFound].transactionAmount += purchasedProduct.transactionAmount;
          } else {
            purchasedProduct.quantity = 1;
            vm.products.push(purchasedProduct);
          }
        });
      }
    }
  }



  function getLogoImage() {

    if (vm.receipt.shop && vm.receipt.shop.signboard && vm.receipt.shop.signboard.code) {

      CmsService.getBrandByCode(vm.receipt.shop.signboard.code).then(function (data) {
        if (data[0] && data[0].brand_image_full)
          vm.logoUri = CMS_URI + data[0].brand_image_full;
      });

      /*
      if (vm.receipt.shop.signboard.cmsReference) {
        vm.cmsReference = vm.receipt.shop.signboard.cmsReference;
        return getLogoImageByCmsReference();
      } else if (vm.receipt.shop.signboard.code) {

        
        return CmsService.searchBrandFromCode(vm.lang, vm.receipt.shop.signboard.code).then(function (data) {
          if (data && data[0] && data[0].id) {
            vm.cmsReference = data[0].id;
            getLogoImageByCmsReference();
          } else {
            getLogoImageByMerchant();
          }
        });*/
    }

    //getLogoImageByMerchant();
  }

  function getLogoImageByCmsReference() {
    CmsService.getBrandImageData(vm.lang, vm.cmsReference).then(function (data) {
      if (data.data && data.data.relationships && data.data.relationships.field_image &&
        data.data.relationships.field_image.links && data.data.relationships.field_image.links.related &&
        data.data.relationships.field_image.links.related.href) {
        CmsService.call(data.data.relationships.field_image.links.related.href).then(function (dataImage) {
          if (dataImage.data && dataImage.data.attributes && dataImage.data.attributes.uri && dataImage.data.attributes.uri.url) {
            setLogoUriGrayScale(CMS_URI + dataImage.attributes.uri.url);
          } else {
            getLogoImageByMerchant();
          }
        });
      } else {
        getLogoImageByMerchant();
      }
    });
  }

  function getLogoImageByMerchant() {
    if (vm.receipt.shop && vm.receipt.shop.merchant && vm.receipt.shop.merchant.logoPath != null && vm.receipt.shop.merchant.logoPath !== '') {
      var resultLogo = vm.receipt.shop.merchant.logoPath.substring(26);

      //setLogoUriGrayScale('/cms' + resultLogo);
      vm.logoUri = '/cms' + resultLogo;
    }
  }

  function setLogoUriGrayScale(logoUri) {
    var img = new Image;
    img.src = logoUri;
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var imgDataUrl = grayscale(img);
      vm.logoUri = imgDataUrl;
    };
  }

  function grayscale(image) {
    var myCanvas = document.createElement("canvas");
    var myCanvasContext = myCanvas.getContext("2d");
    var imgWidth = image.width;
    var imgHeight = image.height;
    // You'll get some string error if you fail to specify the dimensions
    myCanvas.width = imgWidth;
    myCanvas.height = imgHeight;
    //  alert(imgWidth);
    myCanvasContext.drawImage(image, 0, 0);

    // This function cannot be called if the image is not rom the same domain.
    // You'll get security error if you do.
    var imageData = myCanvasContext.getImageData(0, 0, imgWidth, imgHeight);

    var pix = imageData.data;
    for (var i = 0, n = pix.length; i < n; i += 4) {
      var grayscale = pix[i] * .3 + pix[i + 1] * .59 + pix[i + 2] * .11;
      pix[i] = grayscale; // red
      pix[i + 1] = grayscale; // green
      pix[i + 2] = grayscale; // blue
      // alpha
    }
    contrastImage(imageData, 200);
    myCanvasContext.putImageData(imageData, 0, 0);
    return myCanvas.toDataURL('image/jpeg', 0.9);
  }

  function contrastImage(imgData, contrast) {  //input range [-100..100]
    var d = imgData.data;
    contrast = (contrast / 100) + 1;  //convert to decimal & shift range: [0..2]
    var intercept = 128 * (1 - contrast);
    for (var i = 0; i < d.length; i += 4) {   //r,g,b,a
      d[i] = d[i] * contrast + intercept;
      d[i + 1] = d[i + 1] * contrast + intercept;
      d[i + 2] = d[i + 2] * contrast + intercept;
    }
    return imgData;
  }

}
