(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('cms', {
      parent: 'account',
      url: '/cms/:node',
      data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'cms.title'
      },
      views: {
        'sidebar@': {
          templateUrl: 'app/entities/dashboard/sidebar-dashboard.html',
          controller: 'SidebarController',
          controllerAs: 'vm'
        },
        'content@': {
          templateUrl: 'app/cms/cms.html',
          controller: 'CmsController',
          controllerAs: 'vm'
        }
      },
      params: {
        // merchantCode: {
        //     value: 'GARANTEASY',
        //     squash: false
        // }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('register');
          $translatePartialLoader.addPart('merchant');
          $translatePartialLoader.addPart('accept-privacy');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('receipt');
          return $translate.refresh();
        }]
      }
    }).state('cms.detail', {
      parent: 'cms',
      url: '/:id',
      data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'cms.title'
      },
      views: {
        'sidebar@': {
          templateUrl: 'app/entities/dashboard/sidebar-dashboard.html',
          controller: 'SidebarController',
          controllerAs: 'vm'
        },
        'content@': {
          templateUrl: 'app/cms/cms-detail.html',
          controller: 'CmsDetailController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('register');
          $translatePartialLoader.addPart('merchant');
          $translatePartialLoader.addPart('accept-privacy');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('receipt');
          return $translate.refresh();
        }]
      }
    });
  }
})();
