(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CardCustomerDetailController', CardCustomerDetailController);

    CardCustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Card', 'User', 'Gift', 'Receipt', 'Address','CardCustomer'];

    function CardCustomerDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Card, User, Gift, Receipt, Address, CardCustomer) {
        var vm = this;

        vm.cardCustomer = entity;

        if($stateParams.from) {
            $scope.from = $stateParams.from
        }

        vm.isOwner = function() {
            var ret = false;
            var account = Principal.identity();
            if(vm.cardCustomer.card && vm.cardCustomer.card.contact && vm.cardCustomer.card.contact.user && account.$$state && account.$$state.value.id)
                ret = vm.cardCustomer.card.contact.user.id == account.$$state.value.id;
            return ret;
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:cardUpdate', function(event, result) {
            vm.card = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
