(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('GiftLandingController', GiftLandingController);

  GiftLandingController.$inject = ['$state', '$stateParams', '$scope', '$rootScope', '$q', 'Receipt', 'Principal', 'PurchasedProduct'];

  function GiftLandingController($state, $stateParams, $scope, $rootScope, $q, Receipt, Principal, PurchasedProduct) {
    var vm = this;
    vm.checkAccess = checkAccess;
    vm.receipt = {};
    vm.init = init;


    vm.init();

    checkAccess();

    function init() {
      if ($stateParams.receiptId)
        Receipt.get({ id: $stateParams.receiptId }, onSuccessLoadReceipt, onErrorLoadReceipt);
    }



    function onSuccessLoadReceipt(result) {
      vm.receipt = result;
    }



    function onErrorLoadReceipt() {

    }

    function onSaveSuccess(result) {
      vm.isSaving = false;
    }

    function onSaveError() {
      vm.isSaving = false;
    }

    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.currentUserId = account.id;
        if (vm.receipt.id && vm.receipt.customer) {
          vm.isOwner = vm.receipt.customer.user.id === account.id;
        } else {
          // New receipt
          vm.isOwner = true;
        }
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

  }
})();
