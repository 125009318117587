angular
    .module('garanteasyApp')
    .component('listItemProduct', {
        templateUrl: 'app/components/list-item/product/product.component.html',
        controller: ListItemProductComponentController,
        controllerAs: 'vm',
        bindings: {
            product: '<'
        }
    });

ListItemProductComponentController.$inject = ['DataUtils', 'Product'];

function ListItemProductComponentController(DataUtils, Product) {
    var vm = this;
    vm.abbreviate = DataUtils.abbreviate;
    vm.image;
    vm.getWarrantiesStatus = function (arr) {

        var valid = arr
            .filter(function (x) { return x.status === 'valid'; });
        if (valid.length) {
            return 'valid';
        }
        valid = arr
            .filter(function (x) { return x.status === 'aboutToExpire'; });
        if (valid.length) {
            return 'aboutToExpire';
        }
        valid = arr
            .filter(function (x) { return x.status === 'expired'; });
        if (valid.length) {
            return 'expired';
        }
        return null;
    };
    vm.$onInit = function () {
        //$log.debug("CardProductComponentController: purchased-product is", vm.product);
        Product.get({ id: vm.product.product.id }, function (p) {
            if (p.images.length > 0) {
                vm.image = p.images[0].id ? '/api/product-resources/image/' + p.images[0].id + '/xs' : null;
            }
        });
    };
}
