(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('PurchasedProductWarrantyDialogController', PurchasedProductWarrantyDialogController);

    PurchasedProductWarrantyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PurchasedProductWarranty', 'PurchasedProduct', 'Warranty'];

    function PurchasedProductWarrantyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PurchasedProductWarranty, PurchasedProduct, Warranty) {
        var vm = this;

        vm.purchasedProductWarranty = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        // Carica il purchased product con le garanzie certificate del brand, se esistono
        vm.loadPurchasedProduct = loadPurchasedProduct;
        vm.purchasedProduct = {};
       	loadPurchasedProduct($stateParams.id);
        
        function loadPurchasedProduct (id) {
        	PurchasedProduct.get({id: id}, function(result) {
                vm.purchasedProduct = result;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            PurchasedProductWarranty.save(vm.purchasedProductWarranty, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('garanteasyEntitiesApp:purchasedProductWarrantyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.expirationDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
