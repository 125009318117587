angular
  .module('garanteasyApp')
  .component('shop', {
    templateUrl: ['$element', '$attrs', function (elem, attr) {
      if (attr.mode) {
        return 'app/components/shop/shop.' + (attr.mode === 'create' || attr.mode === 'select' ? 'edit' : attr.mode) + '.component.html';
      } else {
        return 'app/components/shop/shop.display.component.html';
      }
    }],
    controller: ShopComponentController,
    controllerAs: 'vm',
    bindings: {
      currentShop: '<',
      cmsReference: '<',
      onUpdate: '<'
    }
  });

ShopComponentController.$inject = ['$scope', '$element', '$log', 'ShopLookup', 'Shop', 'CmsShopService', '$attrs', '$translate'];

function ShopComponentController($scope, $element, $log, ShopLookup, Shop, CmsShopService, $attrs, $translate) {

  var vm = this;

  vm.ALL_SHOPS = -1;
  vm.PREFERRED_SHOPS = 0;
  vm.EDIT_SHOP = 1;
  vm.cmsShop = {};
  vm.loadCmsShopData = loadCmsShopData;
  vm.cmsCompanyData = {};
  vm.loadedShop;

  $log.debug("Booting directive", vm);

  function loadShopData() {
    if (vm.currentShop && vm.currentShop.id) {
      Shop.get({ id: vm.currentShop.id }).$promise.then(onSuccessLoadShop, onErrorLoadShop);
      vm.loadedShop = true;
    }
  }

  function onSuccessLoadShop(result) {
    vm.currentShop = result;

  }

  function onErrorLoadShop() {

  }

  vm.hasCards = function (shop) {
    var response = 'no';
    const prefix = 'garanteasyApp.shop.list.';
    if (shop && shop.merchant && shop.merchant.cardTypes) {
      response = shop.merchant.cardTypes.length ? 'yes' : 'no';
    }
    return $translate.instant(prefix + response);
  }

  function initDefaultShop() {
    if (!$scope.currentShop) {
      $scope.currentShop = {};
    }

    if (!$scope.currentShop.address) {
      $scope.currentShop.address = { country: { code: 'IT' } };
    }

    if (!$scope.currentShop.address.country) {
      $scope.currentShop.address.country.code = 'IT';
    }
    if (vm.cmsReference) {
      loadCmsShopData(vm.cmsReference);
    }
  }

  function loadCmsShopData(cmsReference) {

    CmsShopService.getShopData(cmsReference).then(function (response) {
      if (response && response[0]) {
        vm.cmsShop = response[0];
        if (vm.cmsShop.owner_uuid) {
          CmsShopService.getCompanyData(vm.cmsShop.owner_uuid).then(function (response2) {
            if (response2 && response2[0]) {
              vm.cmsCompanyData = response2[0];
            }
          });
        }
      }
    });
  }



  vm.$onInit = function () {
    $log.debug("Initializing directive", vm);
    if (vm.currentShop) {
      if (!vm.loadedShop) {
        loadShopData();
      }
      vm.shop_ecommerce = vm.currentShop.ecommerceUrl ? 'ecommerce' : 'shop';
      if (vm.cmsReference) {
        loadCmsShopData(vm.cmsReference);
      }
    }


    initDefaultShop();

    switch ($attrs.mode) {
      case 'create':
      case 'edit':
        vm.isNewShopCollapsed = vm.ALL_SHOPS;
        vm.hideSelect = true;
        break;
      case 'select':
        vm.isNewShopCollapsed = vm.ALL_SHOPS;
        vm.hideSelect = false;
        $scope.loadShops();
        break;
      default:
        vm.hideSelect = false;
    }
  };

  vm.isNewShopCollapsed = vm.EDIT_SHOP;

  $scope.$watch('vm.currentShop', function (newshop) {
    if (!vm.loadedShop) {
      loadShopData();
    }
    $log.debug("shop has changed", vm.currentShop, newshop);
    if (angular.isFunction(vm.onUpdate) && newshop) {
      vm.onUpdate(vm.currentShop);
    }
  }, true);

  $scope.$watch('vm.cmsReference', function (cmsReference) {
    $log.debug("Loading cms Reference", cmsReference);
    if (cmsReference) {
      vm.loadCmsShopData(cmsReference);
    }
  }, true);

  vm.selectShop = function ($item, $model) {
    $log.debug("New shop has been selected", $scope.currentShop, $item, $model);
    if (!$item) {
      initDefaultShop();
      if (angular.isFunction(vm.onUpdate)) {
        vm.onUpdate($scope.currentShop);
      }
    } else {
      if (angular.isFunction(vm.onUpdate)) {
        vm.onUpdate($item);
      }
    }
  };

  vm.shops = [];
  $scope.loadShops = function (searchQuery) {
    if (searchQuery && searchQuery.trim().length > 1) {
      ShopLookup.query({ query: searchQuery }, function (result) {
        vm.shops = result;
      }, function (response) {
        if (response.status === 404) {
          vm.shops = Shop.query({ isActive: true });
        }
      });
    } else {
      Shop.query({ isActive: true }, function (result) {
        if (Array.isArray(result)) {
          vm.shops = result.map(function (data) {
            return data.shop || data;
          });
        }
      });
    }
  };

  $scope.$on('$destroy', function () {
    $log.debug("Destroing directive");
  });
}
