(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'Customer', 'Contact', 'PhoneNumber', 'JhiLanguageService', '$translate', 'Card', 'UserData', 'MerchantPrivacyPolicy', 'AlertService', '$state'];

    function SettingsController(Principal, Auth, Customer, Contact, PhoneNumber, JhiLanguageService, $translate, Card, UserData, MerchantPrivacyPolicy, AlertService, $state) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.customers = [];
        vm.contacts = [];
        vm.phoneNumbers = [];
        vm.cards = [];

        vm.merchantPrivacyPolicies = loadMerchantPrivacyPolicies;
        vm.onSaveSuccess = onSaveSuccess;
        vm.editAddContact = editAddContact;
        vm.onCancelContact = onCancelContact;
        vm.editAddPhoneNumber = editAddPhoneNumber;
        vm.onCancelPhoneNumber = onCancelPhoneNumber;
        vm.editAddCustomer = editAddCustomer;
        vm.onCancelCustomer = onCancelCustomer;
        vm.editAddCard = editAddCard;
        vm.onCancelCard = onCancelCard;
        vm.changePassword = changePassword;

        loadMerchantPrivacyPolicies();

        function loadMerchantPrivacyPolicies() {
            MerchantPrivacyPolicy.getByUser({
            }, onSuccess, onError);
        }

        function onSuccess(data) {
            vm.merchantPrivacyPolicies = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.authorities = [];

        vm.isOwner = null;
        vm.isAdmin = null;

        checkAccess();

        function checkAccess() {
            Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                } else {
                    vm.isAdmin = false;
                }
            });
        }


        loadCustomers();
        loadContacts();
        loadPhoneNumbers();
        loadCards();

        Principal.getAuthorities().then(function (a) {
            if (a) {
                vm.authorities = a;
            }
        });


        function loadCustomers() {
            Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                if (!result) {
                    Customer.query(function (result) {
                        vm.customers = result;
                    });
                }
            });
        }

        function loadContacts() {
            Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                if (!result) {
                    Contact.query(function (result) {
                        vm.contacts = result;
                    });
                }
            });
        }

        function loadPhoneNumbers() {
            Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                if (!result) {
                    PhoneNumber.query(function (result) {
                        vm.phoneNumbers = result;
                    });
                }
            });
        }

        function loadCards() {
            Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                if (!result) {
                    Card.query(function (result) {
                        vm.cards = result;
                    });
                }
            });
        }

        vm.userDataRequest = function () {
            UserData.send({}, onSuccessSend, onError);
        }

        function onSuccessSend(data) {
            $translate("settings.userData.download.resultOk").then(function (t) {
                AlertService.success(t);
                // $state.param = $state.current.parent;
            });
        }

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                serviceConsent: account.serviceConsent,
                commInfoConsent: account.commInfoConsent,
                commInfo3rdConsent: account.commInfo3rdConsent,
                /*
                firstName: account.firstName,
                lastName: account.lastName,
                */
                langKey: account.langKey,
                login: account.login
            };
        };

        Principal.identity().then(function (account) {
            vm.settingsAccount = copyAccount(account);
        });

        function save() {
            Auth.updateAccount(vm.settingsAccount).then(function () {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function (account) {
                    vm.settingsAccount = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function (current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        vm.isOwner = function (card) {
            var ret = false;
            var account = Principal.identity();
            if (card.contact && card.contact.user && account && account.$$state && account.$$state.value && account.$$state.value.id)
                ret = card.contact.user.id == account.$$state.value.id;
            return ret;
        }

        vm.approve = function (mpp, input) {
            for (var i = 0; i < vm.merchantPrivacyPolicies.length; i++) {
                if (vm.merchantPrivacyPolicies[i].id === mpp.id) {
                    var merchantPrivacyPolicy = mpp;
                    merchantPrivacyPolicy.approved = input;
                    MerchantPrivacyPolicy.update(merchantPrivacyPolicy, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess(result) {
            $state.go('settings', null, { reload: true });
        }

        function onSaveError() {
            $state.go('settings', null, { reload: true });
        }

        function editAddContact(contact) {
            if (contact) {
                vm.contact = contact;
            } else {
                vm.contact = {};
            }
        }

        function onCancelContact() {
            vm.contact = null;
        }

        function editAddPhoneNumber(phoneNumber) {
            if (phoneNumber) {
                vm.phoneNumber = phoneNumber;
            } else {
                vm.phoneNumber = { activated: true };
            }
        }

        function onCancelPhoneNumber() {
            vm.phoneNumber = null;
        }

        function editAddCustomer(customer, address) {
          if (customer) {
            vm.customer = customer;
          } else {
            vm.customer = {};
          }
          if (address) {
            vm.customer.address = address;
          } else {
            vm.customer.address = {};
          }
        }

        function onCancelCustomer() {
            vm.customer = null;
        }

        function editAddCard(card) {
            if (card) {
                vm.card = card;
            } else {
                vm.card = {};
            }
        }

        function onCancelCard() {
            vm.card = null;
        }

        function changePassword() {
          Auth.changePassword(vm.password).then(function () {
            vm.error = null;
            vm.success = 'OK';
            vm.password = null;
            vm.confirmPassword = null;
            vm.passwordForm.$setPristine();
            window.scrollTo(0,0);
          }).catch(function () {
            vm.success = null;
            vm.error = 'ERROR';
          });
        }
    }
})();
