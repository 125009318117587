(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ShopDialogController', ShopDialogController);

    ShopDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Shop', 'Merchant', 'MerchantLookup', 'User', 'Address', 'AlertService'];

    function ShopDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Shop, Merchant, MerchantLookup, User, Address, AlertService) {
        var vm = this;

        vm.shop = entity;
        vm.clear = clear;
        vm.save = save;
        vm.merchants = Merchant.query();
        vm.isValidInput = isValidInput;
        vm.validMerchant = null;
        vm.validAddress = null;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.resetMerchant = function () {
            vm.shop.merchant = null;
        }

        $scope.loadMerchants = function (searchQuery) {
            if (searchQuery) {
                MerchantLookup.query({ query: searchQuery }, function (result) {
                    $scope.merchants = result;
                }, function (response) {
                    if (response.status === 404) {
                        $scope.merchants = Merchant.query({ isActive: true });
                    }
                });
            } else {
                $scope.merchants = Merchant.query({ isActive: true });
            }
        };

        // Constraints beyond the ones in the form
        function isValidInput() {
            // Merchant must be valid
            vm.validMerchant = vm.shop.merchant != null &&
                (vm.shop.merchant.id != null || (vm.shop.merchant.owner.name != null && vm.shop.merchant.owner.name.trim().length > 0));
            // And either ecommerce url or address must be valid
            vm.validAddress =
                (vm.shop.ecommerceUrl != null && vm.shop.ecommerceUrl.trim().length > 0)
                ||
                (vm.shop.address != null &&
                    vm.shop.address.street != null && vm.shop.address.street.trim().length > 0
                    && vm.shop.address.city != null && vm.shop.address.city.trim().length > 0);

            return vm.validMerchant && vm.validAddress;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.shop.id !== null) {
                Shop.update(vm.shop, onSaveSuccess, onSaveError);
            } else {
                Shop.save(vm.shop, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('garanteasyApp:shopUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError(error) {
            vm.isSaving = false;
            AlertService.error(error.data.message);
        }
    }
})();
