(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ShopNewPageController', ShopNewPageController);

  ShopNewPageController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'Shop', 'Merchant', 'MerchantLookup', 'User', 'Address', '$state', '$rootScope', 'Base64'];

  function ShopNewPageController($timeout, $scope, $stateParams, $q, entity, Shop, Merchant, MerchantLookup, User, Address, $state, $rootScope, Base64) {
    var vm = this;

    $rootScope.shop = entity;
    vm.receipt = {};
    vm.clear = clear;
    vm.save = save;
    vm.merchants = Merchant.query();
    // vm.isValidInput = isValidInput;
    vm.validMerchant = null;
    vm.validAddress = null;

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

  
    function clear() {

    }

    function save() {
      vm.isSaving = true;
      if ($rootScope.shop.id !== null) {
        Shop.update($rootScope.shop, onSaveSuccess, onSaveError);
      } else {

        if( !$rootScope.shop.code && $rootScope.shop.shopSignboard ){
          $rootScope.shop.code = Base64.encode($rootScope.shop.shopSignboard);
        }

        Shop.save($rootScope.shop, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      $scope.$emit('garanteasyApp:shopUpdate', result);
      vm.isSaving = false;
      $state.go('shop', null, { reload: true });
    }

    function onSaveError(error) {
      vm.error = error;
      vm.isSaving = false;
    }

    $rootScope.shopUpdate = function( newShop ){
      $rootScope.shop = newShop;
    };
  }
})();
