(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = ['$scope', 'Auth', '$state'];

    function ProfileController($scope, Auth, $state) {

        $scope.logout = function () {
            Auth.logout();
            $state.go('home');
        };
    }

})();
